import { axiosFactory } from '@/main/factories/http/axios-factory'
import { SignupManagementFactory } from '@/main/factories/pages/admin/list-signups-factory'
import Vue from 'vue'
import Vuex from 'vuex'
import { signupStoreFactory } from './admin/signup-management/signup-store'
import { meStoreFactory } from './users/me'

Vue.use(Vuex)

const http = axiosFactory()
const signupHttp = SignupManagementFactory(http)

// TODO gargalo -- CRIAR INSTANCIAS SOB DEMANDA
const signupStore = signupStoreFactory(signupHttp)
const me = meStoreFactory()

export default new Vuex.Store({
  modules: {
    signupStore,
    me
  }
})
