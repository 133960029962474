<template>
  <section class="flex flex-col justify-center items-center mb-4">
    <div class="w-full md:w-1/2 bg-white shadow p-5 rounded flex flex-col">
    <div class="flex flex-row border-b-2 w-full relative pb-2 border-separate items-center  justify-center  mb-4">
      <router-link to="/portal/estilo/bases" class="cursor-pointer absolute left-0 mr-2" title="Voltar">
          <i class="fas fa-arrow-left bg-gray-300 text-xl p-1 rounded-full border hover:border-gray-400 "></i>
      </router-link>
      <span class="font-mont  uppercase font-black text-xl"> {{ mode === 'create' ? "Cadastro de bases": "Informações da base" }}  </span>
    </div>
    <FormulateForm name="base" @submit="onSubmit">
      <SampleInput :readonly="!canEdit"  :value="activeBase.code" label="Código*" validation="required" placeholder="informe o código da base" name="code" id="code"/>
      <SampleInput :readonly="!canEdit"  :value="activeBase.provider" label="Fornecedor*"  validation="required"  placeholder="informe o fornecedor" name="provider" id="provider"/>
      <SampleInput :readonly="!canEdit"  :value="activeBase.providerRef" label="Referência do fornecedor" placeholder="informe a referência do fornecedor" name="providerRef" id="providerRef"/>
      <SampleInput :readonly="!canEdit"  :value="activeBase.type" label="Tipo" placeholder="informe o tipo da base" name="type" id="type"/>
      <SampleSelectInput
        name="tissue"
        :disabled="!canEdit" 
        :options="{ PLANO: 'PLANO', MALHA: 'MALHA' }"
        :value="activeBase.tissue"
        placeholder="informe o tipo do tecido"
        label="Tecido"  />
      <div class="flex flex-col md:flex-row ">
          <SampleInput :readonly="!canEdit"  :value="activeBase.width"  class="w-full mr-1" validation="number" step="0.01" min="0" type="number" label="Largura" placeholder="informe a largura" name="width" id="width"/>
          <SampleInput :readonly="!canEdit" :value="activeBase.linearGrammage" class="w-full mr-1" type="number" label="Gramatura" placeholder="informe a gramatura" name="linearGrammage" id="linearGrammage"/>
          <SampleInput :readonly="!canEdit"  :value="activeBase.squareGrammage" class="w-full mr-1" type="number" label="Gramatura (m²)" placeholder="informe a g/m²" name="squareGrammage" id="squareGrammage"/>
      </div>
      <SampleInput :readonly="!canEdit" :value="activeBase.description" label="Descriçao*" validation="required"  placeholder="informe a descrição" name="description" id="description"/>
      <SampleInput :readonly="!canEdit"  :value="activeBase.name"  label="Nome comercial*" validation="required"  placeholder="informe o nome comercial" name="name" id="name"/>
      <SampleInput :readonly="!canEdit"  :value="activeBase.composition"  label="Composição*"  validation="required"  placeholder="informe a composição" name="composition" id="composition"/>
      <SampleInput :readonly="!canEdit" :value="activeBase.observation" label="Observação" placeholder="adicione alguma observação" name="observation" id="observation"/>
      <SampleInput :readonly="!canEdit"  :value="activeBase.tags" label="Tags" placeholder="adicione tags separadas por espaço por ex: VERAO ESTAMPADO" name="tags" id="tags"/>

      <div>
        <SampleSendFormButton v-if="canEdit && !activeBase.psID " class="mt-4" :text="mode === 'create' ? 'Cadastrar' :'Atualizar'" />
        <LinkFormButton :class="{ 
          'mt-4 bg-red-500 uppercase text-white py-2 rounded': !canEdit || activeBase.psID ,
          'text-red-600': canEdit && !activeBase.psID 
        }" 
        class="text-center block w-full" text="Voltar" to="/portal/estilo/bases" />
      </div>
    </FormulateForm>
    </div>
  </section>
</template>

<script lang="ts">
import Vue from 'vue'
import { Token } from '@/presentation/helpers/token-local-storage'
import SampleInput from '@/presentation/components/forms/sample-input.vue'
import SampleSelectInput from '@/presentation/components/forms/sample-select-input.vue'
import SampleSendFormButton from '@/presentation/components/forms/send-form-button.vue'
import LinkFormButton from '@/presentation/components/buttons/link-router-button.vue'
import { Toastr } from '@/presentation/helpers/toastr-configs'
import { mapActions } from 'vuex'
import { BaseModel } from '../../../../../domain/models/bases/base-model'
import NProgress from 'nprogress'

export default Vue.extend({
  name: 'BaseDetailPage',
  props: {
    mode: {
      type: String,
      default: 'create'
    },
    baseId: {
      type: String,
      default: null
    },
    activeBase: {
      type: Object,
      default: (): Object => {
        return {
          code: '',
          provider: '',
          providerRef: '',
          type: '',
          tissue: '',
          widht: '',
          linearGrammage: '',
          squareGrammage: '',
          description: '',
          name: '',
          composition: '',
          observation: '',
          tags: ''
        }
      }
    }
  },
  components: {
    SampleInput,
    SampleSelectInput,
    SampleSendFormButton,
    LinkFormButton
  },
  methods: {
    async submitWrapper (data: BaseModel, callback: (data: BaseModel) => void, toastText: string) {
      NProgress.start()
      await callback(data)
      Toastr.successBottomRight(this, `Base ${toastText} com sucesso`)
      NProgress.done()
    },
    async submitUpdate (data: BaseModel) {
       await this.updateBase({ id: this.baseId, base: data })
    },
    async submitCreate (data: BaseModel) {
      await this.createBase(data)
      this.$formulate.reset('base')
    },
    async onSubmit (data: any) {
      try {
        if(!data){
          return
        }
        data.tags = data.tags.trim().toUpperCase().split(' ')
        if (this.mode === 'edit' && this.baseId) {
          return await this.submitWrapper(data, this.submitUpdate, 'atualizada')
        }
        return await this.submitWrapper(data, this.submitCreate, 'criada')
      } catch (error) {
        NProgress.done()
        console.log("ALgum error", error)
        Toastr.errorBottomRight(this, error.errorMessage)
      }
    },
    ...mapActions({ createBase: 'createBase', updateBase: 'updateBase' })
  },
  computed: {
    canEdit(){
      return Token.validRole('administrator', 'manager')
    }
  }

})
</script>
