<template>
  <section class="flex flex-col items-center justify-center " >
    <SearchInput  v-if="searchText || bases.length" :text="searchText" @search="listAllBases" class="w-full md:w-1/3 self-center" />
    <div  v-if="bases.length" class="flex flex-col w-full md:w-10/12"  >
      <ListMenuOptions  :showAddButton="Token.validRole('administrator', 'manager')" addButtonText="Nova base" detailCreate='bases/cadastro' @refresh="listAllBases(null)"/>
      <BasesList :bases="bases"/>
      <Pagination class="mb-4" :pageNumber="pageNumber" @previous="listAllBases(searchText, $event)" @next="listAllBases(searchText, $event)" :pageLimit="basesPageSize" />
    </div>
    <div v-else>
      <NoData :createMode="true" createText="Criar nova base" createRoute="bases/cadastro" text="Nenhuma base encontrada" />
    </div>
  </section>
</template>

<script lang="ts">
import Vue from 'vue'
import SearchInput from '@/presentation/components/inputs/input-search.vue'
import BasesList from '@/presentation/components/lists/bases-list.vue'
import Pagination from '@/presentation/components/utils/pagination.vue'
import ListMenuOptions from '@/presentation/components/lists/list-menu-options.vue'
import NoData from '@/presentation/components/utils/no-data.vue'
import NProgress from 'nprogress'
import { mapState, mapGetters } from 'vuex'
import { Token } from '@/presentation/helpers/token-local-storage'


export default Vue.extend({
  name: 'BaseListPage',
  data () {
    return {
      pageNumber: 1,
      Token
    }
  },
  components: {
    SearchInput,
    BasesList,
    ListMenuOptions,
    Pagination,
    NoData
  },
  computed: {
    ...mapState(['basesStore']),
    ...mapGetters(['bases', 'searchText', 'basesPageSize'])
  },
  methods: {
    async listAllBases (searchText: string, pageNumber: number = 1) {
      NProgress.start()
      this.pageNumber = pageNumber
      this.$store.commit('SET_SEARCH_TEXT', searchText)
      await this.$store.dispatch('loadBases', { pageNumber, pageSize: 5 })
      NProgress.done()
    }
  }
})
</script>
