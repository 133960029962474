import {getRequest, updateRequest} from '@/data/helper/http-request-decorators';
import { IHttpClient } from '@/data/protocols/httpClient';
import { IDevelop } from '@/domain/develop/develop';
import { IHttpResponse } from '@/domain/models/api-responses';
import {DeveloperStatus, DeveloperStatusType, DrawingDeveloperModel} from '@/domain/models/develop/develop-model';

export class Develop implements IDevelop {
  constructor(
    private readonly url: string,
    private readonly token: string,
    private readonly http: IHttpClient<DrawingDeveloperModel>) { }
  
  @getRequest
  async list(queryParams: { [key: string]: any }): Promise<IHttpResponse<DrawingDeveloperModel>>{
    return this.http.request({
      method: 'get',
      url: this.url,
      params: queryParams,
      headers: { Authorization: `Bearer ${this.token}`}
    })
  }

  @updateRequest
  async setStatus(id: string, status: DeveloperStatus): Promise<IHttpResponse<any>>{
       return this.http.request({
          method: "put",
          url: `${this.url}/${id}/setStatus`,
          body: status,
          headers: { Authorization: `Bearer ${this.token}`}
        })
  }

  @updateRequest
  async removeStatus(id: string, status: DeveloperStatusType): Promise<IHttpResponse<any>>{
    return this.http.request({
      method: "put",
      url: `${this.url}/${id}/removeStatus`,
      body: {status},
      headers: { Authorization: `Bearer ${this.token}` }
    })
  }

  @updateRequest
  async update(id: string, data: Partial<DrawingDeveloperModel>): Promise<IHttpResponse<DrawingDeveloperModel>>{
      return this.http.request({
          method: "put",
          url: `${this.url}/${id}`,
          body: data,
          headers: { Authorization: `Bearer ${this.token}`}
      })
  }
}