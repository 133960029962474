<template>
  <section class="flex flex-col">
    <div
      class="bg-white flex flex-col items-center  justify-center lg:mr-2 mb-2 h-8 border border-gray-200"
      v-if="
        !isFreelancer &&
          (!activeDrawin.developerApproved || activeDrawin.canceled)
      "
    >
      <span
        v-if="!activeDrawin.developerApproved && !activeDrawin.canceled"
        class="font-semibold truncate text-sm lg:text-base"
        :class="{
          'text-red-600  ': daysRemaining <= 7
        }"
      >
        {{
          daysRemaining <= 0
            ? 'DEVELOPER ATRASADO'
            : 'FALTAM ' + daysRemaining + ' DIA(S)'
        }}
      </span>
      <span
        v-if="activeDrawin.canceled"
        class="font-semibold truncate text-sm text-red-600 lg:text-base"
      >
        DESENHO CANCELADO
      </span>
    </div>
    <div
      class="flex flex-col items-center bg-white  lg:mr-2  border border-gray-200 p-4 mb-2"
    >
      <div class="self-end" v-if="isManager">
        <div class="relative inline-block text-left">
          <button
            id="dropdown-button"
            class="inline-flex  justify-center w-full rounded-md  hover:bg-gray-100 focus:outline-none"
            @click.stop="optionOpen = !optionOpen"
          >
            <svg
              class="w-6 h-6 "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke="currentColor"
                stroke-linecap="round"
                stroke-width="3"
                d="M12 6h.01M12 12h.01M12 18h.01"
              />
            </svg>
          </button>
          <div
            id="dropdown-menu"
            v-if="optionOpen"
            class="origin-top-right absolute right-0  w-48 shadow-lg bg-white "
          >
            <div
              class="py-2 p-2   hover:bg-gray-100"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="dropdown-button"
            >
              <button
                @click.stop="downloadRecordFile()"
                class="self-center w-full py-2 text-base font-medium text-gray-900"
              >
                Ficha
              </button>
            </div>

            <div
              v-if="!activeDrawin.developerApproved"
              class=" py-2 p-2 hover:bg-gray-100"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="dropdown-button"
            >
              <button
                @click.stop="developerOk"
                class="self-center w-full py-2 text-base font-medium text-gray-900"
              >
                Liberar developer
              </button>
            </div>
            <div
              class="py-2 p-2   hover:bg-gray-100"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="dropdown-button"
            >
              <button
                @click="onCancel"
                class="self-center w-full py-2 text-base font-medium text-red-700"
              >
                {{ activeDrawin.canceled ? 'Reativar desenho' : 'Cancelar' }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <img
        class="w-32 rounded h-32  object-cover"
        :src="
          activeDrawin.imageUrl || require('../../assets/images/no_image.png')
        "
      />
      <div class="mt-4 w-full flex flex-col ">
        <div class="capitalize">
          <span class="text-gray-500  uppercase font-thin text-xs ">
            Lucin ref:
          </span>
          {{ activeDrawin.lucinRef || '-' }}
        </div>
        <div v-if="activeDrawin.isChild" class="capitalize">
          <span class="text-gray-500  uppercase font-thin text-xs ">
            Acervo ref:
          </span>
          {{ activeDrawin.childLucinRef || '-' }}
        </div>
        <div class="capitalize">
          <span class="text-gray-500  uppercase font-thin text-xs "
            >Nome desenho:
          </span>
          {{ activeDrawin.name || '-' }}
        </div>
        <div class="capitalize" v-if="!isFreelancer">
          <span class="text-gray-500   uppercase font-thin text-xs "
            >Status compra:
          </span>
          {{ purchasedStatusMap.get(activeDrawin.purchaseStatus) || '-' }}
        </div>

        <div v-if="!isFreelancer" class="capitalize ">
          <span class="text-gray-500 uppercase font-thin text-xs">
            Cliente:
          </span>
          {{ activeDrawin.client || '-' }}
        </div>
        <div class="capitalize ">
          <span class="text-gray-500 uppercase font-thin text-xs">
            Estilista:
          </span>
          {{ activeDrawin.stylist || '-' }}
        </div>
        <div class="capitalize mb-6">
          <span class="text-gray-500 uppercase  font-thin text-xs ">
            Tipo:
          </span>
          {{ activeDrawin.type || '-' }}
        </div>

        <div v-if="!isFreelancer" class="capitalize">
          <span class="text-gray-500 uppercase  font-thin text-xs ">
            Cadastro:
          </span>
          {{
            new Date(activeDrawin.registerDate).toLocaleDateString('pt-BR', {
              timeZone: 'UTC'
            })
          }}
        </div>
        <div v-if="!isFreelancer" class="capitalize">
          <span class="text-gray-500 uppercase font-thin text-xs ">
            Enviar até: </span
          >{{
            new Date(activeDrawin.factoryMaxDate).toLocaleDateString('pt-BR', {
              timeZone: 'UTC'
            })
          }}
        </div>
        <div v-if="!isFreelancer" class="capitalize">
          <span class="text-gray-500  uppercase font-thin text-xs ">
            Entrega da produção:
          </span>
          {{
            activeDrawin.productionDate
              ? new Date(
                  activeDrawin.productionDate
                ).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
              : '-'
          }}
        </div>
        <button
          v-if="isManager && !activeDrawin.developerApproved"
          @click="developerOk"
          class=" text-blue-500  self-center w-1/2 rounded-lg p-1 float-right mt-2  hover:bg-blue-100 focus:outline-none uppercase text-xs "
        >
          Liberar developer
        </button>
      </div>
    </div>
    <div
      v-if="activeDrawin.basesId.length"
      class="flex flex-col items-center bg-white  lg:mr-2  border border-gray-200 p-4 mb-2"
    >
      <span class="self-center uppercase mt-2"
        >Bases ({{ activeDrawin.basesId.length }})</span
      >
      <div
        class="flex flex-col w-full"
        v-for="base of activeDrawin.basesId"
        :key="base.id"
      >
        <div class="flex flex-row justify-between items-start">
          <div
            :class="{
              'font-semibold': activeBase ? activeBase.id === base.id : false
            }"
          >
            {{ base.name }}
            {{ base.providerRef ? ' - ' + base.providerRef : '' }}
          </div>
          <span class="self-end">
            <button
              :class="{
                'bg-blue-600': activeBase ? activeBase.id === base.id : false
              }"
              class="hover:bg-blue-600  bg-gray-600 px-2 rounded-full text-xs text-white focus:outline-none"
              @click="showModal(base)"
            >
              Ver
            </button>
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="!isFreelancer"
      class="flex flex-col items-center bg-white  lg:mr-2  border border-gray-200 p-4 mb-2"
    >
      <span class="self-center uppercase mt-2">Origem</span>
      <div class="mt-4 w-full flex flex-col ">
        <div class="capitalize">
          <span class="text-gray-500 uppercase font-thin text-xs"> Nome: </span>
          {{ activeDrawin.studio || '-' }}
        </div>
        <div class="capitalize">
          <span class="text-gray-500  uppercase font-thin text-xs ">
            Referencia:
          </span>
          {{ activeDrawin.studioRef || '-' }}
        </div>
        <div class="capitalize">
          <span class="text-gray-500 uppercase  font-thin text-xs ">
            Pagamento:
          </span>
          {{ activeDrawin.studioPaid ? 'Lucin' : '-' }}
        </div>
      </div>
    </div>

    <div
      class="flex flex-col items-center bg-white  lg:mr-2  border border-gray-200 p-4 mb-2"
      v-if="!isFreelancer"
    >
      <span class="self-center uppercase mt-2">COMPRA</span>

      <div
        v-if="loadingPurchase"
        class="flex flex-col w-full text-blue-300  rounded-lg animate-pulse p-4 justify-center items-center "
      >
        <span class="text-blue-600">
          Buscando pedido de compra...
        </span>
      </div>

      <div
        v-if="!loadingPurchase && !purchaseList.length"
        class="mt-4 w-full flex flex-col items-center"
      >
        <img
          class="w-full h-40"
          loading="lazy"
          src="../../assets/svgs/illustrations/purchase.svg"
          alt=""
          srcset=""
        />
      </div>

      <div
        v-if="!loadingPurchase && purchaseList.length"
        class="mt-4 mb-2 w-full flex flex-col overflow-x-auto  py-4"
      >
        <table class="text-xs border">
          <thead class="">
            <th class="border-r p-2">Num</th>
            <th class="border-r p-2">Cód</th>
            <th class="border-r p-2">Item</th>
            <th class="border-r p-2">Data</th>
            <th class="border-r p-2">Quantidade</th>
          </thead>
          <tbody>
            <tr
              v-for="p of purchaseList"
              :key="p.personalId"
              class="truncate border "
            >
              <td class=" px-2 py-1 border-r ">
                {{ p.numSystem || '-' }}
              </td>
              <td class=" px-2 py-1 border-r ">
                {{ p.code }}
              </td>
              <td class=" px-2 py-1 border-r ">
                {{ p.name }} - ({{ p.color }})
              </td>
              <td class=" px-2 py-1 border-r ">
                {{ new Date(p.purchaseDate).toLocaleDateString('pt-BR') }}
              </td>
              <td class="text-center">{{ p.quantity }} {{ p.unity }}</td>
            </tr>
            <tr>
              <td colspan="4" class="text-center font-semibold border-r">
                Total
              </td>
              <td colspan="1" class="text-center font-semibold border-r">
                {{
                  purchaseList
                    .reduce((p, c) => c.quantity + p, 0)
                    ?.toFixed(2) || 0
                }}
                {{ purchaseList.every(p => p.unity === 'm') ? 'm' : '' }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div
      class="flex flex-col items-center bg-white  lg:mr-2  border border-gray-200 p-4 mb-2"
      v-if="!isFreelancer"
    >
      <span class="self-center uppercase mt-2">Acervo</span>

      <div
        v-if="loadingCollection"
        class="flex flex-col w-full  rounded-lg animate-pulse p-4 justify-center items-center "
      >
        <span class="text-blue-600">
          Buscando acervo...
        </span>
      </div>

      <div
        v-if="
          !loadingCollection &&
            !collection?.isChild &&
            !collection?.children.length
        "
        class="mt-4 w-full flex flex-col items-center"
      >
        <img
          class="w-full h-40"
          loading="lazy"
          src="../../assets/svgs/illustrations/collection.svg"
          alt=""
          srcset=""
        />
      </div>

      <div
        v-if="!loadingCollection && collection?.children.length"
        class="mt-4 mb-2 w-full flex flex-col "
      >
        <ul class="text-md list-none ">
          <span class=" text-gray-700 hover:text-blue-400 ">
            <i class="fas fa-folder-open cursor-pointer"></i>
            <label
              class=" cursor-pointer"
              @click.stop="goToSteps(collection?.parent.id)"
              :class="{
                'font-semibold': collection?.parent.id == activeDrawin.id
              }"
            >
              {{ collection?.parent.lucinRef }}</label
            >
          </span>

          <ul class="flex flex-col border-l ml-2 pt-1 space-y-4  ">
            <div
              v-for="child of collection?.children"
              class=" h-4 flex cursor-pointer hover:text-blue-400"
            >
              <span class="  w-4  border-b inline-block  "> </span>
              <li
                @click.stop="goToSteps(child.id)"
                class="pl-2 "
                :class="{
                  'font-semibold': child.id == activeDrawin.id,
                  'font-thin': child.id != activeDrawin.id
                }"
              >
                {{ child.childLucinRef || child.lucinRef || child.name || '-' }}
              </li>
            </div>
          </ul>
        </ul>
      </div>
      <button
        type="button"
        v-if="!collection?.isChild && !loadingCollection && isManager"
        @click.stop="createCollection(activeDrawin.id)"
        class="text-blue-500  bg-blue-100 cursor-pointer hover:bg-blue-200   self-center w-1/2 rounded-lg p-1 float-right mt-4 mb-2 focus:outline-none uppercase text-xs"
      >
        Novo
      </button>
    </div>

    <div
      v-if="activeBase"
      class="fixed w-full  bg-black  bg-opacity-25 h-100 inset-0 z-50 overflow-hidden flex justify-center items-center cursor-pointer"
      @click="activeBase = null"
    >
      <BasesModalCard @close="activeBase = null" :activeBase="activeBase" />
    </div>
  </section>
</template>
<script lang="ts">
import { BaseModel } from '@/domain/models/bases/base-model'
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import BasesList from '@/presentation/components/lists/bases-list.vue'
import BasesModalCard from '@/presentation/components/cards/bases-modal-card.vue'
import { DatesHelper } from '@/presentation/helpers/dates-helper'
import { Token } from '@/presentation/helpers/token-local-storage'
import { Toastr } from '@/presentation/helpers/toastr-configs'
import nProgress from 'nprogress'
export default Vue.extend({
  name: 'DrawinInfoCard',
  props: {
    loadingCollection: {
      required: true,
      default: true
    },
    loadingPurchase: {
      required: true,
      default: true
    },
    collection: null,
    purchaseList: null
  },
  data() {
    return {
      activeBase: null,
      isManager: Token.validRole('manager', 'administrator'),
      isFreelancer: Token.validRole('freelancer'),
      optionOpen: false,
      url: '',
      purchasedStatusMap: new Map([
        ['purchased', 'COMPRADO'],
        ['pending', 'AGUARDANDO'],
        ['canceled', 'NÃO COMPRADO']
      ])
    }
  },
  components: {
    BasesList,
    BasesModalCard
  },
  methods: {
    showModal(base: BaseModel) {
      this.activeBase = base
    },
    async developerOk(): Promise<void> {
      await this.approveDeveloper(this.activeDrawin.id)
      Toastr.successBottomRight(this, 'Developer aprovada!')
    },
    createCollection(id: string) {
      return this.$router.push(
        this.$route.path
          .replace('desenhos/', 'desenhos/acervo/')
          .replace(/\/arte|\/configurar/, '')
      )
    },
    goToSteps(id: string) {
      const route = this.$router.resolve({
        path: `/portal/estilo/desenhos/${id}/arte`
      })
      window.open(route.href, '_blank')
    },
    async downloadRecordFile() {
      nProgress.start()
      const result = await this.recordFile(this.activeDrawin.id)
      if (!result) {
        nProgress.done()
        return
      }

      const pdfBlob = new Blob([result], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(pdfBlob)

      const printWindow = window.open(pdfUrl)
      if (printWindow) {
        printWindow.onload = function() {
          printWindow.focus()
        }
      }

      nProgress.done()
    },
    async onCancel() {
      try {
        const cancel = !!this.activeDrawin.canceled
        const { id } = this.activeDrawin
        if (cancel) {
          await this.reactivate(id)
        } else {
          await this.cancel(id)
        }
        this.activeDrawin.canceled = !cancel

        Toastr.successBottomRight(
          this,
          `Desenhos ${cancel ? 'reativado' : 'cancelado'} com sucesso!`
        )
      } catch (error) {}
    },
    ...mapActions({
      approveDeveloper: 'approveDeveloper',
      cancel: 'cancel',
      reactivate: 'reactivate',
      recordFile: 'recordFile'
    })
  },
  created() {
    window.addEventListener('click', e => {
      if (this.$el.firstElementChild !== e.target) {
        this.optionOpen = false
      }
    })
  },
  computed: {
    daysRemaining(): number {
      return DatesHelper.daysBetweenTodayAnd(
        new Date(this.activeDrawin.factoryMaxDate)
      )
    },
    ...mapGetters({ activeDrawin: 'activeDrawin' })
  }
})
</script>
