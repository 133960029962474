import { updateRequest } from '@/data/helper/http-request-decorators';
import { IHttpClient } from '@/data/protocols/httpClient';
import { IHttpResponse } from '@/domain/models/api-responses';
import { BankAccountModel, UserModel } from '@/domain/models/user-model';
import { IMe } from '@/domain/users/me';

export class Me implements IMe {
  constructor(private readonly http: IHttpClient<UserModel>, private readonly url: string, private readonly token: string){}

  @updateRequest
  async updateAvatar(data: any): Promise<IHttpResponse<UserModel>> {
   return await this.http.request({
      method: 'patch',
      url: `${this.url}/avatar`,
      body: data,
      headers: {
        Authorization: `Bearer ${this.token}`,
        'Content-type': 'multipart/form-data'
      }
    })
  }

  @updateRequest
  async changePassword(currentPassword: string, currentPasswordConfirmation: string, newPassword: string): Promise<IHttpResponse<UserModel>>{
    return await this.http.request({
      method: 'patch',
      url: `${this.url}/password`,
      body: {
        oldPassword: currentPassword,
        newPassword,
        newPasswordConfirmation: currentPasswordConfirmation
      },
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }   

  @updateRequest
  async updateData(userName: string, userCpf: string, { bankName, accountNumber, agency, pix}: BankAccountModel): Promise<IHttpResponse<UserModel>> {
   return await this.http.request({
      method: 'patch',
      url: `${this.url}/userData`,
      body: {
        name: userName,
        cpf: userCpf,
        bankName,
        pix,
        agency,
        accountNumber
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
      }
    })
  }

}