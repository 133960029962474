import { IHttpResponse } from "@/domain/models/api-responses";
import { getRequest } from "../helper/http-request-decorators";
import { IHttpClient } from "../protocols/httpClient";
import { DrawinColor } from "@/domain/models/drawins/drawin-model";
import { IColorsService } from "@/domain/colors/colors";

export default class ColorsService implements IColorsService {
    constructor (
        private readonly url: string,
        private readonly token: string,
        private readonly http: IHttpClient<DrawinColor>
      ) {
    
      }

    @getRequest
    async listAll(params: { [key: string]: string; }): Promise<IHttpResponse<DrawinColor>> {
        return await this.http.request({
            method: 'get',
            url: `${this.url}`,
            headers: { Authorization: `Bearer ${this.token}` },
            params: {
              ...params
            }
          })
    }



}
