import { IForgotPassword } from '@/domain/auth/forgot-password'
import { IHttpClient } from '../protocols/httpClient'
import { IHttpResponse } from '@/domain/models/api-responses'

export class ForgotPassword implements IForgotPassword {
  constructor (
    private readonly http : IHttpClient<any>,
    private readonly url: string
  ) { }

  async send (email: string): Promise<IHttpResponse<any>> {
    const response = await this.http.request({
      body: { email },
      method: 'post',
      url: this.url
    })

    const { error, errorMessage, statusCode } = response

    if (error) {
      throw { errorMessage, statusCode }
    }

    return { statusCode: 200 }
  }
}
