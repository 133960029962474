<template>
  <aside
    class="transform w-64 bg-whiteh-full overflow-auto ease-in-out transition-all duration-300 z-30"
  >
    <div
      class="flex flex-col w-full align-center justify-center items-center p-4 border-b"
      @click="drawer"
    >
      <img
        class="rounded-full h-24 w-24"
        :src="
          myAvatarUrl ||
            userInfo.avatarUrl ||
            require('../../../assets/images/no_avatar.png')
        "
      />
      <p class="pt-4 font-semibold truncate">
        {{ userInfo.name }}
      </p>
    </div>

    <div class="pt-1">
      <label class="px-3 text-xs text-gray-500 uppercase dark:text-gray-400 "
        >Menu</label
      >

      <div class="px-4">
        <router-link class="px-4 py-2 hover:bg-gray-100 flex " to="/portal">
          <div class="text-gray-800">
            <i class="fa fa-home" />
          </div>
          <div class="pl-3">
            <p class="font-rubik">
              Home
            </p>
            <!-- <p class="text-xs text-gray-500">acompanhe desenhos e bases </p> -->
          </div>
        </router-link>
      </div>
    </div>

    <div v-if="!isFreela" class="">
      <label class="px-3 text-xs text-gray-500 uppercase dark:text-gray-400"
        >Desenvolvimento</label
      >
      <div class="px-4">
        <router-link
          class="px-4 py-2 hover:bg-gray-100 flex"
          to="/portal/estilo/desenhos"
        >
          <div class="text-gray-800">
            <i class="fas fa-palette" />
          </div>
          <div class="pl-3">
            <span class="font-rubik">Desenhos</span>
          </div>
        </router-link>
        <router-link
          class="px-4 py-2 hover:bg-gray-100 flex"
          to="/portal/estilo/bases"
        >
          <div class="text-gray-800">
            <i class="fas fa-object-group" />
          </div>
          <div class="pl-3">
            <span>Bases</span>
          </div>
        </router-link>
        <router-link
          class="px-4 py-2 hover:bg-gray-100 flex"
          to="/portal/estilo/developer"
        >
          <div class="text-gray-800">
            <i class="fas fa-share" />
          </div>
          <div class="pl-3">
            <span>Developer</span>
          </div>
        </router-link>
        <router-link
          v-if="Token.validRole('administrator', 'manager')"
          class="px-4 py-2 hover:bg-gray-100 flex"
          to="/portal/estilo/clientes"
        >
          <div class="text-gray-800">
            <i class="fas fa-briefcase" />
          </div>
          <div class="pl-3">
            <span>Clientes</span>
          </div>
        </router-link>
        <router-link
          v-if="Token.validRole('administrator', 'manager')"
          class="px-4 py-2 hover:bg-gray-100 flex"
          to="/portal/estilo/freelancer"
        >
          <div class="text-gray-800">
            <i class="fas fa-laptop" />
          </div>
          <div class="pl-3">
            <span>Freelancer</span>
          </div>
        </router-link>
      </div>
    </div>

    <div v-if="!isFreela" class="">
      <label class="px-3 text-xs text-gray-500 uppercase dark:text-gray-400"
        >Relatórios</label
      >
      <div class="px-4">
        <router-link
          v-if="Token.validRole('administrator', 'manager')"
          class="px-4 py-2 hover:bg-gray-100 flex"
          to="/portal/estilo/relatorios"
        >
          <div class="text-gray-800">
            <i class="fas fa-database" />
          </div>
          <div class="pl-3">
            <p>Relatórios</p>
          </div>
        </router-link>
      </div>
    </div>

    <div class="">
      <label class="px-3 text-xs text-gray-500 uppercase dark:text-gray-400"
        >Configurações</label
      >
      <div class="px-4">
        <router-link
          v-if="Token.validRole('administrator', 'manager')"
          class="px-4 py-2 hover:bg-gray-100 flex"
          to="/portal/admin/signup"
        >
          <div class="text-gray-800">
            <i class="fas fa-user-check" />
          </div>
          <div class="pl-3">
            <p>Cadastro</p>
          </div>
        </router-link>

        <router-link
          class="px-4 py-2 hover:bg-gray-100 flex"
          to="/portal/perfil/editar"
        >
          <div class="text-gray-800">
            <i class="fas fa-id-card" />
          </div>
          <div class="pl-3">
            <p>Perfil</p>
          </div>
        </router-link>
      </div>
    </div>

    <div class="fixed bottom-0 w-full">
      <button
        class="flex items-center  bg-red-100 justify-center p-4 text-white w-full"
        @click="logout"
      >
        <span class="text-center text-red-600  uppercase ">Sair</span>
      </button>
    </div>
  </aside>
</template>

<script lang="ts">
import { Token } from '@/presentation/helpers/token-local-storage'
import { mapActions, mapGetters } from 'vuex'
import Vue from 'vue'
export default Vue.extend({
  name: 'SideBarNavigation',
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      userInfo: {
        email: Token.userInfo().email,
        name: Token.userInfo().name,
        avatarUrl: Token.userInfo().avatarUrl
      },
      isFreela: Token.validRole('freelancer'),
      Token
    }
  },
  watch: {
    isOpen: {
      immediate: true,
      handler(isOpen) {
        if (process.env.client) {
          if (isOpen) document.body.style.setProperty('overflow', 'hidden')
          else document.body.style.removeProperty('overflow')
        }
      }
    }
  },
  mounted() {
    document.addEventListener('keydown', e => {
      if (e.keyCode === 27 && this.isOpen) this.$emit('isOpen', !this.isOpen)
    })
  },
  methods: {
    drawer() {
      this.$emit('isOpen', !this.isOpen)
    },
    logout() {
      Token.removeToken()
      this.$router.push('/')
    }
  },
  computed: {
    ...mapGetters(['myAvatarUrl'])
  }
})
</script>
