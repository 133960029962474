import './presentation/assets/styles/styles.css'
import 'vue-select/dist/vue-select.css'

import Vue from 'vue'
import App from '@/main/App.vue'
import router from '@/main/router'
import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { PluginOptions } from 'vue-toastification/dist/types/src/types'
import VueFormulate from '@braid/vue-formulate'
import 'nprogress/nprogress.css'
import store from '@/store/index'
import VModal from 'vue-js-modal'
import vSelect from 'vue-select'
import VTooltip from 'v-tooltip'

declare module 'vue/types/vue' {
  interface Vue {
    $formulate: {
      handle: (err: typeof Error, formName: string, skip?: boolean) => void | typeof Error;
      reset: <V>(formName: string, initialValue?: V) => void;
      resetValidation: (formName: string) => void;
      setValues: <V>(formName: string, values: V) => void;
    };
  }
}

const options: PluginOptions = {
  draggable: false,
  position: POSITION.BOTTOM_RIGHT
}

Vue.use(VTooltip)

Vue.component('v-select', vSelect)
Vue.config.productionTip = false

Vue.use(Toast, options)
Vue.use(VueFormulate)
Vue.use(VModal, {
  dialog: true
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
