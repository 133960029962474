<template>
  <section>
    <div class="flex  w-full  mb-4  justify-between  gap-2">
      <div class=" flex gap-2  w-full  text-sm">
        <RouterLink
          to="desenhos/cadastro"
          v-if="Token.validRole('administrator', 'manager')"
        >
          <span
            class="flex gap-2 font-semibold bg-white border py-2 px-4 items-center justify-between  rounded-md cursor-pointer hover:bg-gray-100 "
          >
            <span>Novo </span>
            <i class="fas fa-plus text-sm"></i>
          </span>
        </RouterLink>

        <input
          @input="onQuickSearch($event.target.value)"
          v-model="quickSearchText"
          class="w-full border rounded pl-4 py-1 focus:border-2 focus:border-blue-500 focus:outline-none"
          type="text"
          placeholder="Pesquisa rápida (código, nome do desenho ou nome do cliente)"
          name=""
          id=""
        />
        <span
          @click="showFilters = !showFilters"
          class="flex gap-2 border bg-white  py-2 px-4 items-center justify-between  rounded-md cursor-pointer hover:bg-gray-100 "
          :class="{
            ' border-blue-500  text-blue-500': activeQueryFilters !== ''
          }"
        >
          <span>Filtros </span>
          <i v-if="activeQueryFilters === ''" class="fas fa-sliders-h"></i>
          <i
            v-else
            @click="onFilterDrawins('')"
            class="fas fa-times text-red-700 cursor-pointer"
          ></i>
        </span>
      </div>

      <div class=" flex gap-2   text-sm">
        <div
          class="bg-gray-200  border text-sm text-gray-700 leading-none   rounded-md inline-flex"
        >
          <button
            @click="drawinViewMode = 'list'"
            class="inline-flex items-center transition-colors  ease-in focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-r-md px-4 py-2"
            :class="{
              'bg-white text-blue-500  rounded-md': drawinViewMode === 'list'
            }"
            id="list"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="fill-current w-4 h-4 mr-2"
            >
              <line x1="8" y1="6" x2="21" y2="6"></line>
              <line x1="8" y1="12" x2="21" y2="12"></line>
              <line x1="8" y1="18" x2="21" y2="18"></line>
              <line x1="3" y1="6" x2="3.01" y2="6"></line>
              <line x1="3" y1="12" x2="3.01" y2="12"></line>
              <line x1="3" y1="18" x2="3.01" y2="18"></line>
            </svg>
            <span>Lista</span>
          </button>
          <button
            @click="drawinViewMode = 'grid'"
            class="inline-flex items-center transition-colors ease-in focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-l-md px-4 py-2 active"
            :class="{
              'bg-white text-blue-600 rounded-md': drawinViewMode === 'grid'
            }"
            id="grid"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="fill-current w-4 h-4 mr-2"
            >
              <rect x="3" y="3" width="7" height="7"></rect>
              <rect x="14" y="3" width="7" height="7"></rect>
              <rect x="14" y="14" width="7" height="7"></rect>
              <rect x="3" y="14" width="7" height="7"></rect>
            </svg>
            <span>Grid</span>
          </button>
        </div>
      </div>
    </div>

    <KeepAlive>
      <DrawinFilterSideBar
        v-if="showFilters"
        @opened="showFilters = $event"
        @onFilter="onFilterDrawins"
        @onGenerateDrawinReport="generateDrawinReport"
      />
    </KeepAlive>

    <DrawinGridView
      v-if="drawinViewMode === 'grid'"
      :drawinList="drawinList"
      @onGenerateRecordFile="generateRecordFile"
      @onCancelDrawin="cancelDrawin"
      @onReactivateDrawin="reactiveDrawin"
      @onDeveloperApproved="approveDeveloper"
      @onLoadMoreDrawins="nextDrawinPage"
    />

    <DrawinListView
      v-if="drawinViewMode === 'list'"
      :drawinList="drawinList"
      @onGenerateRecordFile="generateRecordFile"
      @onCancelDrawin="cancelDrawin"
      @onReactivateDrawin="reactiveDrawin"
      @onDeveloperApproved="approveDeveloper"
      @onLoadMoreDrawins="nextDrawinPage"
    />
  </section>
</template>

<script lang="ts">
import { IDrawin } from '@/domain/drawins/drawins'
import { Toastr } from '@/presentation/helpers/toastr-configs'
import nProgress from 'nprogress'

import Vue from 'vue'
import DrawinGridView from '@/presentation/components/drawins-view/DrawinGridView.vue'
import DrawinListView from '@/presentation/components/drawins-view/DrawinListView.vue'
import DrawinFilterSideBar from '@/presentation/components/drawins-view/DrawinFilterSideBar.vue'
import { Token } from '@/presentation/helpers/token-local-storage'
import { RouterLink } from 'vue-router'
import { DrawinModel } from '@/domain/models/drawins/drawin-model'
import { IDrawinsReports } from '@/domain/reports/drawins/drawins-report'

export default Vue.extend({
  name: 'DrawinListPage',
  props: {
    drawinsHttp: Object as () => IDrawin,
    reportsHttp: Object as () => IDrawinsReports
  },
  components: {
    DrawinGridView,
    DrawinListView,
    DrawinFilterSideBar
  },
  data() {
    return {
      drawinList: [],
      drawinViewMode: 'list',
      showFilters: false,
      activePage: 1,
      pageSize: 15,
      maxOfPages: 1,
      activeQueryFilters: '',
      quickSearchTimeout: null,
      quickSearchText: '',
      loadinNewDrawins: false,
      Token
    }
  },
  async created() {
    this.drawinsHttp
      .listDrawins(
        `pageNumber=${this.activePage}&pageSize=${this.pageSize}`,
        false
      )
      .then(result => {
        this.drawinList = result.data
        this.maxOfPages = result.pagesLimit
      })
      .catch(error => {
        console.log(error)
        Toastr.errorBottomRight(this, 'Erro ao buscar desenhos')
      })
  },
  mounted() {
    document
      .querySelector('.calcHeight')
      .addEventListener('scroll', this.onScroll)

    document.addEventListener('click', this.resetOptionMenu)
  },
  beforeDestroy() {
    document
      .querySelector('.calcHeight')
      .removeEventListener('scroll', this.onScroll)

    document.addEventListener('click', this.resetOptionMenu)
  },
  methods: {
    async onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight - 400) {
        this.nextDrawinPage()
      }
    },
    async nextDrawinPage() {
      if (this.loadingNewDrawins) return
      if (this.maxOfPages == this.activePage) return
      this.activePage = this.activePage + 1
      this.loadingNewDrawins = true
      const newDrawins = await this.findDrawins(
        `pageNumber=${this.activePage}&pageSize=${this.pageSize}&searchText=${this.quickSearchText}&${this.activeQueryFilters}`
      )
      this.maxOfPages = newDrawins.pagesLimit
      this.drawinList.push(...newDrawins.data)
      this.loadingNewDrawins = false
    },

    async onQuickSearch(text: string) {
      clearTimeout(this.quickSearchTimeout)
      this.quickSearchTimeout = setTimeout(async () => {
        this.activePage = 1
        const result = await await this.findDrawins(
          `pageNumber=${this.activePage}&pageSize=${this.pageSize}&searchText=${text}&${this.activeQueryFilters}`
        )
        this.quickSearchText = text
        this.drawinList = result.data
      }, 700)
    },

    async findDrawins(queryParams: string): Promise<DrawinModel[]> {
      return this.drawinsHttp.listDrawins(queryParams, false)
    },
    async onFilterDrawins(queryParams: string) {
      this.showFilters = !this.showFilters
      if (this.activeQueryFilters == queryParams) return
      this.quickSearchText = ''
      this.activeQueryFilters = queryParams
      this.activePage = 1

      const drawinsWithFilter = await this.findDrawins(
        `pageNumber=${this.activePage}&pageSize=${this.pageSize}&${queryParams}`
      )
      this.maxOfPages = drawinsWithFilter.pagesLimit
      this.drawinList = drawinsWithFilter.data
    },

    async generateRecordFile(drawinId: string) {
      nProgress.start()
      Toastr.successBottomRight(this, 'Ficha sendo gerada, por favor aguarde')

      const result = await this.drawinsHttp.recordFile(drawinId)
      if (!result) {
        nProgress.done()
        return
      }

      const pdfBlob = new Blob([result], { type: 'application/pdf' })
      const pdfUrl = URL.createObjectURL(pdfBlob)

      const printWindow = window.open(pdfUrl)
      if (printWindow) {
        printWindow.onload = function() {
          printWindow.focus()
        }
      }

      nProgress.done()
    },
    async approveDeveloper(drawinId: string, index: number) {
      nProgress.start()
      const result = await this.drawinsHttp.approveDeveloper(drawinId)
      if (!result) {
        nProgress.done()
        return
      }

      Toastr.successBottomRight(this, 'Desenho liberado para developer')

      this.drawinList[index].developerApproved = true
      this.drawinIdToOptionMenu = ''
      nProgress.done()
    },
    async cancelDrawin(drawinId: string, index: number) {
      nProgress.start()
      const result = await this.drawinsHttp.cancel(drawinId)
      if (!result) {
        nProgress.done()
        return
      }
      Toastr.successBottomRight(this, 'Desenho cancelado com sucesso')
      this.drawinList[index].canceled = true
      nProgress.done()
    },
    async reactiveDrawin(drawinId: string, index: number) {
      nProgress.start()
      const result = await this.drawinsHttp.reactivate(drawinId)
      if (!result) {
        nProgress.done()
        return
      }
      Toastr.successBottomRight(this, 'Desenho reativado com sucesso')
      this.drawinList[index].canceled = false
      this.drawinIdToOptionMenu = ''
      nProgress.done()
    },
    async generateDrawinReport(value: string) {
      this.showFilters = false
      nProgress.start()
      const result = await this.reportsHttp.drawins(value)
      if (!result) {
        nProgress.done()
        return
      }
      Toastr.successBottomRight(
        this,
        'O relatório está sendo gerado e será enviado para o seu e-mail em breve'
      )
      nProgress.done()
    }
  }
})
</script>
