import { IBasesManagement } from '@/domain/bases/bases-management'
import { BasesManagement } from '@/data/style/bases/bases-management'
import { IHttpClient } from '@/data/protocols/httpClient'
import { BaseModel } from '@/domain/models/bases/base-model'
import { Token } from '@/presentation/helpers/token-local-storage'
import { LucinRoute } from '@/main/configs/routes/routes-paths'

export const basesManagementFactory = (http: IHttpClient<BaseModel>): IBasesManagement => {
  return new BasesManagement(http, Token.getToken(), LucinRoute.bases)
}
