<template>
  <section>
    <!-- <span v-if="setting.field === 'onTime'" class="truncate"> <i class="fas fa-circle text-xs text-green-500 "></i>  </span> -->
    <span v-if="setting.field === 'designerId' && setting.isImage">
      <img
        class="w-20 h-20 lg:h-12 lg:w-12 rounded-full"
        :src="
          (step[setting.field] && step[setting.field].avatarUrl) ||
            require('../../assets/images/no_avatar.png')
        "
      />
    </span>
    <div
      v-if="setting.field === 'designerId' && setting.key === 'name'"
      class="flex flex-col mt-4 lg:mt-0"
    >
      <span class="text-xs text-gray-600 uppercase lg:hidden">
        {{ setting.name }}</span
      >
      <span
        class="mb-1 lg:mb-0 uppercase truncate"
        :title="
          step[setting.field] ? step[setting.field].name : 'SEM DESENHISTA'
        "
      >
        {{ step[setting.field] ? step[setting.field].name : 'SEM DESENHISTA' }}
      </span>
    </div>

    <div
      v-if="setting.field === 'designerId' && setting.key === 'roles'"
      class="lg:truncate flex flex-col"
    >
      <span class="text-xs text-gray-600 uppercase lg:hidden">
        {{ setting.name }}</span
      >
      <i
        v-if="
          step[setting.field] !== null &&
            (step[setting.field].roles.includes('freelancer') ||
              step.freelancer)
        "
        class="fas fa-check text-green-700  mb-1 lg:mb-0 text"
      ></i>
      <i v-else class="fas fa-minus text-gray-400 mb-1 lg:mb-0 text"></i>
    </div>
    <v-popover
      class="cursor-pointer hover:font-semibold"
      v-if="setting.field === 'name'"
      offset="8"
      placement="top-center"
    >
      <div class="flex flex-col ">
        <span class="text-xs text-gray-600 uppercase lg:hidden">
          {{ setting.name }}</span
        >
        <span class="mb-1 lg:mb-0">
          {{ step[setting.field].split('/')[0] }} ({{ step.count + 1 }}x)
        </span>
      </div>

      <template slot="popover" v-if="isManager">
        <EmojiDrawins
          :itemRef="step.code"
          :designerFinish="step.designerFinish"
          :showCustomerApprovation="
            step.needCustomerApprovation && !step.customerApproves
          "
          @manager-reprovation="
            managerReprobateStep({
              id: activeDrawin.id,
              params: { stepCode: $event }
            })
          "
          @customer-approvation="
            customerApprovesStep({
              id: activeDrawin.id,
              params: { stepCode: $event }
            })
          "
        />
      </template>
    </v-popover>

    <div class="flex flex-col" v-if="setting.date">
      <span class="text-xs text-gray-600 uppercase lg:hidden">
        {{ setting.name }}</span
      >
      <!-- {{ step[setting.field] ? formatToTimeZone(new Date(step[setting.field]), 'Europe/Berlin') : 'sem data'  }} -->
      <!-- new Date(step[setting.field].split("T")[0]).toUTCString() -->
      <span class="mb-1 lg:mb-0">
        {{
          step[setting.field]
            ? format(getUTCDate(step[setting.field]), 'dd/MM/yy', {
                timezone: 'America/Brazil'
              })
            : '-'
        }}
      </span>
    </div>

    <div
      v-if="setting.field === 'needCustomerApprovation'"
      class="lg:truncate flex flex-col"
    >
      <span class="text-xs text-gray-600 uppercase lg:hidden">
        {{ setting.name }}</span
      >
      <i
        class="fas text-gray-400 mb-1 lg:mb-0 text"
        :class="{
          'fa-times text-red-600':
            step.needCustomerApprovation && !step.customerApproves,
          'fa-minus': !step.needCustomerApprovation,
          'fa-check text-green-700':
            step.needCustomerApprovation && step.customerApproves
        }"
      ></i>
    </div>
    <div
      v-if="setting.field === 'status'"
      class="flex flex-col  lg:inline lg:truncate"
    >
      <span class="text-xs mt-2 text-gray-600 uppercase lg:hidden">
        {{ setting.name }}</span
      >
      <BadgeDrawinStepsStatus :step="step" />
    </div>

    <div v-if="setting.field === 'actions'" class="flex flex-col lg:truncate">
      <span class="text-xs mt-2 text-gray-600 uppercase lg:hidden">
        {{ setting.name }}</span
      >
      <i
        class="fas fa-stop text-red-600 cursor-pointer mb-1 lg:mb-0"
        v-if="
          isDesigner && step.status === 'Em andamento' && step.designerId
            ? activeUser === step.designerId.email
            : false
        "
        @click="showModal(step.code)"
      ></i>
      <i
        class="fas fa-play text-gray-600 cursor-pointer mb-1 lg:mb-0"
        v-if="
          isDesigner &&
            step.status === 'Aguardando' &&
            (step.designerId ? activeUser === step.designerId.email : true)
        "
        @click="drawinStep(step.code, 'set')"
      >
      </i>
      <i
        class="fas fa-lock text-gray-600 mb-1 lg:mb-0"
        v-if="
          !isDesigner ||
            step.status === 'Aguardando cliente' ||
            step.status === 'Finalizado' ||
            (step.designerId ? activeUser !== step.designerId.email : false) ||
            step.status === 'Aguardando cliente' ||
            (step.status !== 'Em andamento' && step.status !== 'Aguardando')
        "
      ></i>
    </div>
  </section>
</template>
<script lang="ts">
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import EmojiDrawins from '@/presentation/components/buttons/emojis-drawins-steps.vue'
import { Token } from '@/presentation/helpers/token-local-storage'
import BadgeDrawinStepsStatus from '@/presentation/components/badges/drawin-step-status-badge.vue'
import { format } from 'date-fns'

export default Vue.extend({
  name: 'DrawinsStepsCard',
  components: {
    EmojiDrawins,
    BadgeDrawinStepsStatus
  },
  props: {
    setting: Object,
    step: { type: Object }
  },
  data() {
    return {
      format,
      activeUser: Token.userInfo().email,
      isManager: Token.validRole('manager', 'administrator'),
      isDesigner: Token.validRole('designer', 'freelancer')
    }
  },
  methods: {
    async drawinStep(stepCode: string, type: 'set' | 'leave') {
      this.setDrawinStep({
        id: this.activeDrawin.id,
        params: { stepCode, type }
      })
    },
    showModal(stepCode: string) {
      this.$modal.show('modal-leave-step', { stepCode })
    },
    ...mapActions({
      setDrawinStep: 'setDrawinStep',
      managerReprobateStep: 'managerReprobateStep',
      customerApprovesStep: 'customerApprovesStep'
    }),
    getUTCDate: (dateString = Date.now()) => {
      const date = new Date(dateString)

      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      )
    }
  },
  computed: {
    ...mapGetters({ activeDrawin: 'activeDrawin' })
  }
})
</script>
