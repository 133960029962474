import { POSITION } from 'vue-toastification'

export abstract class Toastr {
  static errorTopRight(context: any, error: string) {
    const message = error || 'Erro ao comunicar-se com o servidor.'
    context.$toast.error(message, {
      position: POSITION.TOP_RIGHT,
      toastClassName: 'my-custom-toast-class',
      bodyClassName: ['custom-class-1']
    })
  }

  static errorBottomRight (context: any, message: string) {
    context.$toast.error(message, {
      position: POSITION.BOTTOM_RIGHT,
      toastClassName: 'my-custom-toast-class',
      bodyClassName: ['custom-class-1']
    })
  }

  static successBottomCenter (context: any, message: string) {
    context.$toast.success(message, {
      position: POSITION.BOTTOM_CENTER,
      toastClassName: 'my-custom-toast-class',
      bodyClassName: ['custom-class-1']
    })
  }

  static successBottomRight (context: any, message: string) {
    context.$toast.success(message, {
      position: POSITION.BOTTOM_RIGHT,
      toastClassName: 'my-custom-toast-class',
      bodyClassName: ['custom-class-1']
    })
  }

  static successTopRight (context: any, message: string) {
    context.$toast.success(message, {
      position: POSITION.TOP_RIGHT,
      toastClassName: 'my-custom-toast-class',
      bodyClassName: ['custom-class-1']
    })
  }
}
