<template>
  <section class="flex flex-col items-center justify-center " >
    <SearchInput v-if="developSearchText || developList.length" :text="developSearchText" @search="listAllDevelopers" class="w-full md:w-1/3 self-center" />      <div   class="flex flex-col w-full md:w-11/12 mb-4"  >
        <ListMenuOptions
          @refresh="listAllDevelopers(null, pageNumber)"

        />
        <DevelopList @sort="onSort" :develop="developList"/>
      </div>
      <Pagination
        class="mb-4"
        :pageNumber="activeDevelopPage"
        @previous="listAllDevelopers(developSearchText, $event)"
        @next="listAllDevelopers(developSearchText, $event)"
        :pageLimit="developPageLimit" />

  </section>
</template>

<script lang="ts">
import Vue from 'vue'

import SearchInput from '@/presentation/components/inputs/input-search.vue'
import ListMenuOptions from '@/presentation/components/lists/list-menu-options.vue'
import DevelopList from '@/presentation/components/lists/develop-list.vue'
import Pagination from '@/presentation/components/utils/pagination.vue'
import { mapGetters } from 'vuex'
import NProgress from "nprogress";

export default Vue.extend({
  name: "DeveloperPage",
  components: {
    SearchInput,
    ListMenuOptions,
    DevelopList,
    Pagination
  },
  data(){
    return {
      pageNumber: 1,
      sort: undefined,
      searchText: undefined
    }
  },
  methods: {
    async listAllDevelopers (searchText: string, pageNumber: number = 1) {
      NProgress.start()
      this.pageNumber = pageNumber
      this.searchText = searchText
      this.$store.commit('SET_DEVELOP_SEARCH_TEXT', searchText)
      await this.$store.dispatch('loadDevelop', { pageNumber, searchText, sort: this.sort })
      NProgress.done()
    },
     async onSort(sortQuery: any): Promise<void> {
      this.sort = sortQuery
      await this.listAllDevelopers(this.searchText, this.pageNumber)
    }
  },
  computed:{
    ...mapGetters(['developList', 'developPageLimit',  'activeDevelopPage', 'developSearchText'])
  }
})
</script>