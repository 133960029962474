<template>
  <div>
    <div
      class="bg-white  fixed z-50 h-screen top-0 right-0 w-full lg:w-1/4 border-l border-gray-400 leading-6 "
    >
      <div class="flex flex-col justify-between w-full gap-6  h-full ">
        <div
          class="flex justify-between border-b border-1 py-4 px-6  font-semibold text-gray-700  "
        >
          <span class="">{{ title }}</span>
          <span class="pl-4">
            <i
              @click="$emit('opened', false)"
              class="fas fa-times cursor-pointer transition duration-500 ease-in-out hover:border-red-600 hover:text-red-600"
            ></i>
          </span>
        </div>
        <div class="flex flex-col px-4 text-sm text-gray-800 overflow-y-auto ">
          <div class="bg-gray-200 p-6 rounded">
            <p
              class="w-full font-semibold text-gray-800 text-xl  py-8 text-center"
            >
              INFORMAÇÕES DO DESENHO
            </p>
            <p class="font-semibold">Cliente</p>
            <v-select
              multiple
              placeholder=""
              label="name"
              id="client"
              class="style-chooser"
              name="client"
              :options="customersList"
              @option:selecting="addToFilter('customers', $event.id)"
              @option:deselecting="removeFromFilter('customers', $event.id)"
              @search="listAllCustomers"
            >
              <template slot="no-options">
                Informe o NOME do cliente
              </template>
            </v-select>

            <p class="font-semibold mt-4">Tipo</p>
            <v-select
              multiple
              class="style-chooser"
              placeholder=""
              label="value"
              :selectable="option => !option.hasOwnProperty('header')"
              @option:selecting="addToFilter('types', $event.value)"
              @option:deselecting="removeFromFilter('types', $event.value)"
              :options="[
                { header: 'Estampa', value: '' },
                { value: 'ESTAMPA CONVENCIONAL' },
                { value: 'ESTAMPA DIGITAL' },
                { value: 'ESTAMPA TRANSFER' },

                { header: 'Bordado', value: '' },
                { value: 'BORDADO' },

                { header: 'Fio tinto', value: '' },
                { value: 'LISTRA' },
                { value: 'XADREZ' },

                { header: 'Liso', value: '' },

                { value: 'LISO (DESENVOLVIMENTO)' },
                { value: 'LISO (L/D)' },

                { header: 'Outros', value: '' },
                { value: 'APLICAÇÕES' },
                { value: 'DEVORÊ' },
                { value: 'GUIPURE' },
                { value: 'JACQUARD' },
                { value: 'LAISE' },
                { value: 'MAQUINETADO' },
                { value: 'PAETÊ' },
                { value: 'RENDA' },
                { value: 'URAGIRI' }
              ]"
            >
              <template #option="{ header, value }">
                <span v-if="header" class="font-bold uppercase">{{
                  header
                }}</span>
                <span v-else class="">{{ value }}</span>
              </template>
            </v-select>

            <p class="font-semibold mt-4">Origem</p>
            <v-select
              class="style-chooser"
              multiple
              label="value"
              placeholder=""
              @option:selecting="addToFilter('studio', $event.value)"
              @option:deselecting="removeFromFilter('studio', $event.value)"
              :options="[
                { value: 'DESENVOLVIMENTO' },
                { value: 'ACERVO' },
                { value: 'FABRICA' },
                { value: 'CLIENTE' },
                { value: 'LIVRO' }
              ]"
            >
              <template #option="{ value }">
                <span class="">{{ value }}</span>
              </template>
            </v-select>

            <p class="font-semibold mt-4">Status de compra</p>
            <v-select
              class="style-chooser"
              single
              placeholder=""
              label="label"
              @input="addBooleanFilter('hasPurchase', $event?.value)"
              :options="[
                { label: 'COMPRADO', value: true },
                { label: 'NÃO COMPRADO', value: false }
              ]"
            >
              <template #option="{ label }">
                <span class="">{{ label }}</span>
              </template>
            </v-select>

            <p class="font-semibold mt-4">Developer</p>
            <v-select
              single
              placeholder=""
              class="style-chooser"
              label="label"
              @input="addBooleanFilter('hasDeveloperApproved', $event?.value)"
              :options="[
                { label: 'DEVELOPER APROVADO', value: true },
                { label: 'DEVELOPER PENDENTE', value: false }
              ]"
            >
              <template #option="{ label }">
                <span class="">{{ label }}</span>
              </template>
            </v-select>

            <p class="font-semibold mt-4">Status do desenho</p>
            <v-select
              class="style-chooser"
              single
              placeholder=""
              label="label"
              @input="addBooleanFilter('canceled', $event?.value)"
              :options="[
                { label: 'Cancelado', value: true },
                { label: 'Ativo', value: false }
              ]"
            >
              <template #option="{ label }">
                <span class="">{{ label }}</span>
              </template>
            </v-select>

            <span v-if="!freelancerContext">
              <p class="font-semibold mt-4">Meus desenhos</p>
              <v-select
                class="style-chooser"
                single
                placeholder=""
                label="label"
                @input="addBooleanFilter('onlyMyDrawins', $event?.value)"
                :options="[{ label: 'Apenas meus desenhos', value: true }]"
              >
                <template #option="{ label }">
                  <span class="">{{ label }}</span>
                </template>
              </v-select>
            </span>

            <div v-if="freelancerContext">
              <p class="font-semibold mt-4">Desenhistas</p>
              <v-select
                multiple
                placeholder=""
                label="name"
                id="client"
                class="style-chooser"
                name="client"
                :options="designerList"
                @option:selecting="addToFilter('designers', $event.id)"
                @option:deselecting="removeFromFilter('designers', $event.id)"
                @search="listAllDesigners"
              >
                <template slot="no-options">
                  Informe o NOME do designer
                </template>
              </v-select>

              <p class="font-semibold mt-4">Status freelancer</p>
              <v-select
                class="style-chooser"
                single
                placeholder=""
                label="label"
                @input="addBooleanFilter('freelancerStatus', $event?.value)"
                :options="[
                  { label: 'Pago', value: 'paid' },
                  { label: 'Pendente', value: 'pending' }
                ]"
              >
                <template #option="{ label }">
                  <span class="">{{ label }}</span>
                </template>
              </v-select>
            </div>
          </div>

          <div
            class="flex flex-col bg-gray-200 p-6 rounded justify-between w-full gap-2 mt-8 "
          >
            <p class="w-full font-semibold text-gray-800 text-xl  text-center">
              DATA DE CADASTRO
            </p>
            <span class="flex flex-col  gap-1   w-full">
              <label class="font-semibold">De</label>
              <input
                type="date"
                v-model="filters.minRegisterDate.value"
                class="border border-gray-500 w-full  rounded pl-2 pr-4 py-1 focus:border-2 focus:border-blue-500 focus:outline-none "
                name=""
                id=""
              />
            </span>
            <span class="flex flex-col  gap-1   w-full mt-2">
              <label class="font-semibold">Até</label>
              <input
                type="date"
                v-model="filters.maxRegisterDate.value"
                class="border border-gray-500 w-full  rounded pl-2 pr-4 py-1 focus:border-2 focus:border-blue-500 focus:outline-none "
                name=""
                id=""
              />
            </span>
          </div>
        </div>

        <div
          id="footer"
          class=" w-full  bg-white gap-2  border-t border-1 py-4 px-6 flex justify-end text-sm uppercase "
        >
          <span
            v-if="showExcelButton && isManager"
            :title="
              filters.minRegisterDate.value && filters.maxRegisterDate.value
                ? ''
                : 'Selecione um período de datas para gerar o relatório'
            "
            @click="showDrawinFilterModal"
            class="border px-3 py-1 rounded-md cursor-pointer transition duration-500 ease-in-out "
            :class="{
              'cursor-not-allowed opacity-50 bg-gray-100 border-none ':
                !filters.minRegisterDate.value ||
                !filters.maxRegisterDate.value,
              'hover:border-green-500 hover:text-green-500':
                filters.minRegisterDate.value && filters.maxRegisterDate.value
            }"
            >Excel
            <i class="fas fa-file-excel text-green-500 ml-2"></i>
          </span>

          <span
            @click="onFilter"
            class="border px-2 py-1 rounded-md cursor-pointer transition duration-500 ease-in-out hover:border-gray-700 hover:text-gray-700"
            >Filtrar <i class="fas fa-filter text-gray-700 ml-2"></i>
          </span>
        </div>
      </div>
    </div>
    <div
      @click="$emit('opened', false)"
      class="cursor-pointer absolute opacity-50  w-screen h-screen z-40 inset-0 bg-gray-200"
    ></div>

    <modal
      :width="400"
      :adaptive="true"
      :scrollable="true"
      :draggable="true"
      height="auto"
      name="drawinFilterExcel"
    >
      <div class="flex flex-col p-4">
        <span class="uppercase tracking-tighter text-center divide-x mb-4">
          Relatório de desenhos
        </span>
        <div class="flex flex-row justify-between py-1">
          <label for="showImages">Exibir imagens</label>
          <div
            class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
          >
            <input
              type="checkbox"
              name="reportShowImages"
              id="reportShowImages"
              class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none focus:outline-none cursor-pointer"
              @change="
                addBooleanFilter('reportShowImages', $event?.target?.checked)
              "
              :checked="filters.reportShowImages.value"
            />
            <label
              for="reportShowImages"
              class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>
        </div>

        <div class="flex flex-row justify-between py-1">
          <label for="reportSearchBases">Exibir bases no relatório</label>
          <div
            class="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in"
          >
            <input
              type="checkbox"
              name="reportSearchBases"
              id="reportSearchBases"
              :checked="filters.reportSearchBases.value"
              class="toggle-checkbox absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none focus:outline-none cursor-pointer"
              @change="
                addBooleanFilter('reportSearchBases', $event?.target?.checked)
              "
            />
            <label
              for="reportSearchBases"
              class="toggle-label block overflow-hidden h-6 rounded-full bg-gray-300 cursor-pointer"
            ></label>
          </div>
        </div>
        <button
          @click="onGenerateDrawinReport"
          type="button"
          class="mt-3 bg-blue-500 py-1 text-white hover:bg-blue-600 rounded"
        >
          Gerar
        </button>
      </div>
    </modal>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { axiosFactory } from '@/main/factories/http/axios-factory'
import { customersHttpFactory } from '@/main/factories/pages/customers/customer-http-factory'
import { title } from 'process'
import { designersHttpFactory } from '@/main/factories/pages/users/designers'
import { Token } from '@/presentation/helpers/token-local-storage'
export default Vue.extend({
  name: 'DrawinFilterSideBar',
  props: {
    showExcelButton: {
      required: false,
      default: true
    },
    title: {
      required: false,
      default: 'FILTRO DESENHOS'
    },
    freelancerContext: {
      required: false,
      default: false
    }
  },
  data() {
    return {
      customersList: [],
      designerList: [],
      customersHttp: customersHttpFactory(axiosFactory()),
      designersHttp: designersHttpFactory(axiosFactory()),
      isManager: Token.validRole('manager'),
      debounceTimeout: null,
      filters: {
        customers: {
          type: 'list',
          queryParam: 'customerId=',
          values: []
        },
        types: {
          type: 'list',
          queryParam: 'type=',
          values: []
        },
        studio: {
          type: 'list',
          queryParam: 'studio=',
          values: []
        },
        hasPurchase: {
          type: 'boolean',
          queryParam: 'hasPurchase=',
          value: null
        },
        hasDeveloperApproved: {
          type: 'boolean',
          queryParam: 'developerApproved=',
          value: null
        },
        canceled: {
          type: 'boolean',
          queryParam: 'canceled=',
          value: null
        },
        minRegisterDate: {
          type: 'date',
          queryParam: 'minRegisterDate=',
          value: null
        },
        maxRegisterDate: {
          type: 'date',
          queryParam: 'maxRegisterDate=',
          value: null
        },
        onlyMyDrawins: {
          type: 'boolean',
          queryParam: 'onlyMyDrawins=',
          value: null
        },
        reportShowImages: {
          type: 'boolean',
          queryParam: 'reportShowImages=',
          value: null
        },
        listPurchases: {
          type: 'boolean',
          queryParam: 'listPurchases=',
          value: null
        },
        reportSearchBases: {
          type: 'boolean',
          queryParam: 'reportSearchBases=',
          value: null
        },
        freelancerStatus: {
          type: 'string',
          queryParam: 'freelancerStatus=',
          value: null
        },
        designers: {
          type: 'list',
          queryParam: 'designerId=',
          values: []
        }
      }
    }
  },
  methods: {
    async listAllCustomers(search: string) {
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(async () => {
        const result = await this.customersHttp.listAll({ searchText: search })
        this.customersList = result.data
      }, 500)
    },
    async listAllDesigners(search: string) {
      clearTimeout(this.debounceTimeout)
      this.debounceTimeout = setTimeout(async () => {
        const result = await this.designersHttp.listAllDesigners(search)
        this.designerList = result.data
      }, 500)
    },
    addToFilter(field: string, id: string | number) {
      if (this.filters[field].values.includes(id)) {
        return
      }
      this.filters[field].values.push(id)
    },
    removeFromFilter(field: string, id: string | number) {
      const index = this.filters[field].values.indexOf(id)
      if (index > -1) {
        this.filters[field].values.splice(index, 1)
      }
    },
    addBooleanFilter(field: string, value: boolean) {
      if (value === undefined) {
        value = null
      }
      this.filters[field].value = value
    },
    buildQueryParam(): string {
      let queryParamStr = ''
      for (const filter in this.filters) {
        if (this.filters[filter].type === 'list') {
          const values = this.filters[filter].values
          if (values.length === 0) {
            continue
          }
          queryParamStr += `${this.filters[filter].queryParam}${values.join(
            ','
          )}&`
          continue
        }
        const value = this.filters[filter].value
        if (value === null) {
          continue
        }
        queryParamStr += `${this.filters[filter].queryParam}${value}&`
      }
      return queryParamStr
    },
    onFilter() {
      const queryParamStr = this.buildQueryParam()
      this.$emit('onFilter', queryParamStr)
    },
    onGenerateDrawinReport() {
      this.$modal.hide('drawinFilterExcel')
      this.$emit('onGenerateDrawinReport', this.buildQueryParam())
    },
    showDrawinFilterModal() {
      if (
        !this.filters.minRegisterDate.value ||
        !this.filters.maxRegisterDate.value
      ) {
        return
      }
      this.$modal.show('drawinFilterExcel', {})
    }
  }
})
</script>

<style scoped>
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle,
.style-chooser .vs__dropdown-menu {
  border: 1px solid #a0aec0;
  text-transform: uppercase;
}

.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68d391;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68d391;
}
</style>
