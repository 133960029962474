<template>
  <div class="w-full md:max-w-xl bg-white p-4 m-2 rounded animate__animated animate__fadeIn">
    <FormTextHeader title="Cadastre-se" />
    <FormulateForm name="signup" values="values" @submit="onSubmit">
      <SampleSelectInput v-on:onChange="setRole($event)" name="roles" validation="required" :options="rolesTypes" placeholder="selecione seu perfil" label="selecione seu perfil" />
      <SampleInput label="Nome" name="name" validation="^required" :placeholder="roleSelected === 'customer' ? 'informe o nome da sua empresa' :'informe o seu nome'" />
      <SampleInput label="E-mail" name="email" validation="^required|email" placeholder="informe o seu e-mail" />
      <SampleInput label="Senha" name="password" validation="^required" placeholder="informe uma senha"  type="password"/>
      <SampleInput label="Confirme a senha" name="password_confirm" validation="^required|confirm" placeholder="confirme sua senha"  type="password" />
      <div>
        <SendFormButton  class="mt-4" text="cadastrar" />
        <LinkButton class="text-red-500 block w-full  text-center  mt-2" to="/" text="Voltar"/>
      </div>
    </FormulateForm>

  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import SampleSelectInput from '@/presentation/components/forms/sample-select-input.vue'
import SampleInput from '@/presentation/components/forms/sample-input.vue'
import FormTextHeader from '@/presentation/components/forms/form-header.vue'
import SendFormButton from '@/presentation/components/forms/send-form-button.vue'
import LinkButton from '@/presentation/components/buttons/link-router-button.vue'
import { ISignUp } from '../../../domain/auth/signup'
import { Toastr } from '../../helpers/toastr-configs'

export default Vue.extend({
  props: {
    signUp: Object as () => ISignUp
  },
  components: {
    SampleSelectInput,
    FormTextHeader,
    SendFormButton,
    SampleInput,
    LinkButton
  },
  name: 'SignUpPage',
  data () {
    return {
      name: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      role: '',
      formData: {},
      rolesTypes: {
        customer: 'Cliente',
        freelancer: 'Freelancer',
        designer: 'Desenhista'
      },
      roleSelected: ''
    }
  },
  methods: {
    async onSubmit (data: any) {
      try {
        const { password_confirm, roles, ...dataSpread } = data
        const dataParse = Object.assign({}, dataSpread, { passwordConfirmation: password_confirm, roles: [roles] })
        await this.signUp.add(dataParse)
        this.$formulate.reset('signup')
        Toastr.successBottomCenter(this,
          'Cadastro efetuado com sucesso. Nossa equipe irá avaliar seus dados e em breve entraremos em contato!'
        )
      } catch (error) {
        Toastr.errorTopRight(this, error)
      }
    },
    setRole (role: string) {
      this.roleSelected = role
    }
  }
})
</script>
