<template >
  <div class="flex flex-col "  v-if="settings.show" :class="{ [settings.widthClass]: true }">
      <span  class="text-gray-500 uppercase text-sm font-hairline lg:hidden"> {{ settings.name }}</span>
      <div v-if="settings.isImage" class="flex flex-col mb-4 lg:mb-0 lg:self-auto bg-gray-300  w-20 lg:w-12 ">
          <img class="h-20 w-20 lg:w-12 lg:h-12 border  " :src="develop[settings.field][0][settings.nestedField] || require('../../assets/images/no_image.jpg') " >
      </div>
      <span v-if="settings.textField" class="" >
        {{ settings.nested  ? develop[settings.field][0][settings.nestedField] || '-' : develop[settings.field] || '-' }} 
       </span>
      <span v-if="settings.date" class=" "> {{ develop[settings.field] ? format(new Date(develop[settings.field]), 'dd/MM/yy') : '-' }} </span>
   </div>
</template>

<script lang="ts" >
import Vue from 'vue'
import { format } from 'date-fns'

export default Vue.extend({
  name: 'Developcard',
  props: {
    develop: {
      type: Object,
      required: true
    },
    settings: {
      type: Object,
      required: true
    }
  },
  methods: {
    format,
    goToEdit (id: string) {
      this.$router.push(`desenhos/editar/${id}`)
    }
  }
})

</script>
