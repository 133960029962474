import { IBasesManagement } from '@/domain/bases/bases-management'
import { BaseModel } from '@/domain/models/bases/base-model'
import { IHttpClient } from '@/data/protocols/httpClient'
import { IHttpResponse } from '@/domain/models/api-responses'

export class BasesManagement implements IBasesManagement {
  constructor(
    private readonly http: IHttpClient<BaseModel>,
    private readonly token: string,
    private readonly url: string
  ) {}

  async newBase(base: BaseModel): Promise<IHttpResponse<BaseModel>> {
    const response = await this.http.request({
      method: 'post',
      url: this.url,
      body: base,
      headers: { Authorization: `Bearer ${this.token}` }
    })

    const { errorMessage, statusCode, error } = response

    if (error) {
      throw { errorMessage, statusCode }
    }

    const { data } = response

    return {
      data,
      statusCode
    }
  }

  async listAll(
    pageNumber: number,
    searchText?: string,
    pageSize?: number
  ): Promise<IHttpResponse<BaseModel>> {
    const response = await this.http.request({
      method: 'get',
      url: this.url,
      headers: { Authorization: `Bearer ${this.token}` },
      params: {
        pageNumber,
        searchText,
        pageSize
      }
    })

    const { errorMessage, statusCode, error } = response

    if (error) {
      throw { errorMessage, statusCode }
    }

    const { data, pagesLimit, dataCount } = response

    return {
      data,
      statusCode,
      pagesLimit,
      dataCount
    }
  }

  async findByCode(code: string): Promise<IHttpResponse<BaseModel>> {
    const response = await this.http.request({
      url: `${this.url}/${code}`,
      method: 'get',
      headers: { Authorization: `Bearer ${this.token}` }
    })
    const { errorMessage, statusCode, error } = response

    if (error) {
      throw { errorMessage, statusCode }
    }

    const { data, pagesLimit, dataCount } = response

    return {
      data,
      statusCode,
      pagesLimit,
      dataCount
    }
  }

  async updateBase(
    id: string,
    base: BaseModel
  ): Promise<IHttpResponse<BaseModel>> {
    const response = await this.http.request({
      method: 'put',
      url: `${this.url}/${id}`,
      body: base,
      headers: { Authorization: `Bearer ${this.token}` }
    })

    const { errorMessage, statusCode, error } = response

    if (error) {
      throw { errorMessage, statusCode }
    }

    const { data } = response

    return {
      data,
      statusCode
    }
  }

  async findByDrawinId(drawinId: string): Promise<IHttpResponse<BaseModel>> {
    const response = await this.http.request({
      url: `${this.url}/findByDrawin/${drawinId}`,
      method: 'get',
      headers: { Authorization: `Bearer ${this.token}` }
    })
    const { errorMessage, statusCode, error } = response

    if (error) {
      throw { errorMessage, statusCode }
    }

    const { data, pagesLimit, dataCount } = response

    return {
      data,
      statusCode,
      pagesLimit,
      dataCount
    }
  }
}
