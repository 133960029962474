<template>
  <FormulateInput
    type="submit"
    :label="text"
    input-class="bg-blue-500 text-center  uppercase w-full rounded text-white py-2 px-2 focus:outline-none focus:shadow-outline hover:bg-blue-700 "
  />
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'SendFormButton',
  props: {
    text: {
      type: String,
      required: true
    }
  }
})
</script>

<style lang="scss" scoped>

</style>
