<template>
  <section class="flex flex-col lg:flex-row w-full " >
    <div class="w-full mb-2 lg:mb-0 lg:w-1/4  flex flex-col">
      <DrawinInfoCard  :drawin="activeDrawin" :loadingCollection="loadingCollection" :collection="collection"  :loadingPurchase="loadingPurchase" :purchaseList="purchaseList" />
    </div>
    <div class=" w-full mb-4 relative">
       <div class="bg-white p-4 flex flex-col items-center shadow">
          <span class="font-bold uppercase mb-2 mt-2"> Configurar etapas </span>
          <table class="flex flex-col lg:flex-none lg:table  w-full">
            <thead class="invisible lg:visible">
              <th class="px-5 pt-3 border-b-2   text-xs font-semibold  uppercase tracking-wider">  </th> 
              <th class="px-5 pt-3 border-b-2   text-xs font-semibold  uppercase tracking-wider">Etapa</th> 
              <th class="px-5 pt-3 border-b-2   text-xs font-semibold  uppercase tracking-wider">Desenhista</th> 
              <th class="px-5 pt-3 border-b-2   text-xs font-semibold  uppercase tracking-wider">Inicio</th> 
              <th class="px-5 pt-3 border-b-2   text-xs font-semibold  uppercase tracking-wider">Termino</th> 
              <th class="px-5 pt-3 border-b-2   text-xs font-semibold  uppercase tracking-wider">Valor (R$)</th> 
              <th class="px-5 pt-3 border-b-2   text-xs font-semibold  uppercase tracking-wider">Freelancer</th> 
              <th class="px-5 pt-3 border-b-2   text-xs font-semibold  uppercase tracking-wider">Aprovação</th> 
              <th class="px-5 pt-3 border-b-2   text-xs font-semibold  uppercase tracking-wider">Finalizado</th> 
              <th class="px-5 pt-3 border-b-2   text-xs font-semibold  uppercase tracking-wider">Status</th> 
              <th class="px-5 pt-3 border-b-2   text-xs font-semibold  uppercase tracking-wider"> </th> 
            </thead>
            
            <draggable class="border-b "  v-model="drawinsStepsRef" tag="tbody"> 
              <tr  class="flex flex-col lg:flex-none lg:table-row  hover:bg-gray-200" v-for="(step, index) of drawinsStepsRef" :key="step.code">
                <td class="text-center hidden lg:table-cell cursor-move">
                  <i class="fas fa-ellipsis-v text-gray-300"></i>
                  <i class="fas fa-ellipsis-v text-gray-300"></i>
                </td>

                <td class="py-1 px-2 text-sm ">
                   <label for="" class="font-semibold text-gray-500 uppercase lg:hidden">Etapa</label>
                    <FormulateInput
                      v-model="step.name"
                      name="name"
                      label-class="mt-4 block uppercase text-gray-700 text-xs font-bold "
                      input-class="w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none"
                    />
                </td>
                <td class=" py-1 px-2 text-sm">
                  <label for="" class="font-semibold text-gray-500 uppercase lg:hidden">Desenhista</label>
                  <FormulateInput
                      placeholder="Selecione o desenhista"
                      name="designerId"
                      type="select"
                      :options="designers"
                      :value="step.designerId.id" 
                      v-model="step.designerId.id"
                      @input="defaultStepSetup(index, step.designerId.id, step)"
                      label-class="mt-4 block uppercase text-gray-700 text-xs font-bold "
                      input-class="w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none"
                    /> 
                </td>
                <td class=" py-1 px-2 text-sm" >
                  <label for="" class="font-semibold text-gray-500 uppercase lg:hidden">Inicio</label>
                    <FormulateInput
                    :disabled="!step.designerId.id"
                    name="endDate"
                    type="date" 
                    v-model="step.startDate"
                    :value="step.startDate"
                    label-class="mt-4 block uppercase text-gray-700 text-xs font-bold "
                    input-class="w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none"
                    />
                </td>
                <td class=" py-1 px-2 text-sm" >
                    <label for="" class="font-semibold text-gray-500 uppercase lg:hidden">Termino</label>
                    <FormulateInput
                    name="endDate"
                    type="date" 
                    v-model="step.endDate"
                    :value="step.endDate"
                    label-class="mt-4 block uppercase text-gray-700 text-xs font-bold "
                    input-class="w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none"
                    />
                </td>
                <td class=" py-1 px-2 text-sm" >
                    <label for="" class="font-semibold text-gray-500 uppercase lg:hidden">Valor (R$)</label>
                    <FormulateInput
                    name="freelancerPrice"
                    type="number" 
                    v-model="step.freelancerPrice"
                    :value="step.freelancerPrice"
                    :disabled="!isFreelancer(step)"
                    label-class="mt-4 block uppercase text-gray-700 text-xs font-bold "
                    input-class="w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none"
                    />
                </td>
                <td class="flex flex-col lg:flex-none lg:table-cell py-1 px-2 text-sm  text-center" > 
                  <label for="" class="font-semibold text-gray-500 uppercase lg:hidden">Freelancer</label>
                  <SampleCheckbox :value="step.freelancer"  @chkToggle="setFreelancer($event, step)" name="freelancer"/>
                </td>
                <td class="flex flex-col lg:flex-none lg:table-cell py-1 px-2 text-sm  text-center" > 
                  <label for="" class="font-semibold text-gray-500 uppercase lg:hidden">Aprovação</label>
                  <SampleCheckbox :value="step.needCustomerApprovation" @chkToggle="setCustomerApprovation($event, step)" name="customerNeedApproves"/>
                </td>
                 <td class="flex flex-col lg:flex-none lg:table-cell py-1 px-2 text-sm  text-center" > 
                  <label for="" class="font-semibold text-gray-500 uppercase lg:hidden">Finalizado</label>
                  <SampleCheckbox :value="step.designerFinish" @chkToggle="setDesignerFinish($event, step)" name="designerFinish"/>
                </td>
                <td class="py-1 px-2 text-sm  text-center whitespace-no-wrap">
                  <label for="" class="font-semibold text-gray-500 uppercase lg:hidden">Status</label>
                  <BadgeDrawinStepsStatus :step="step" />
                </td>
                <td class="py-1 px-2   text-center">
                  <i class="fas fa-trash text-gray-500 cursor-pointer hover:text-red-600" @click="removeStep(index)" ></i>
                </td>
              </tr>
        
              </draggable>
          </table>
          <span class="text-blue-600 cursor-pointer mt-4 hover:underline" @click="addNewStep" > Criar nova etapa </span>
          <span class="lg:self-end lg:w-auto lg:mt-0 mt-4  w-full"> 
            <button @click="saveChanges" class="w-full lg:w-auto bg-blue-500 text-white px-2 lg:py-1 rounded uppercase text-xs py-2 hover:bg-blue-700"> 
                Salvar alterações
            </button>
          </span>
      </div>
    </div>
   
  </section>
</template>

<script lang="ts">
 import Vue from 'vue'
 import SampleInput from '@/presentation/components/forms/sample-input.vue'
 import SampleSelect from '@/presentation/components/forms/sample-select-input.vue'
 import SampleCheckbox from '@/presentation/components/forms/sample-checkbox.vue'
 import { mapActions, mapGetters, mapMutations } from 'vuex'
 import { DrawinStep } from '@/domain/models/drawins/drawin-model'
 import { IDesigners } from '@/domain/users/designer'
 import { UserModel } from '@/domain/models/user-model'
 import NProgress from 'nprogress'
 import { Toastr } from '@/presentation/helpers/toastr-configs'
 import DrawinInfoCard  from '@/presentation/components/cards/drawin-info-card.vue'
 import BadgeDrawinStepsStatus from '@/presentation/components/badges/drawin-step-status-badge.vue'
 import draggable from 'vuedraggable'

 export default Vue.extend({
   name: 'DrawinsStepsSettingPage',
   data() {
     return {
       drawinsStepsRef: [],
       designers: undefined,
       changes: false,
       originalStpes: [],
       loadingCollection: true,
       collection: null,
       loadingPurchase: true,
       purchaseList: null
     }
   },
   components: {
     SampleInput,
     SampleSelect,
     SampleCheckbox,
     DrawinInfoCard,
     BadgeDrawinStepsStatus,
     draggable
   },
   props: {
     designerHttp: {
       required: true,
       type: Object as () => IDesigners
     },
     drawinHttp: {
      required: true
    }
   },
   async created() {
     this.originalStpes =this.activeDrawin.steps.map((s: DrawinStep) => s.code)
     this.drawinsStepsRef = [...this.mapperDesignerId(JSON.parse(JSON.stringify(this.activeDrawin.steps.map(s => {
      if(!s.freelancer) {
        s.freelancer = false
      }
      return s
     }))))]
     
     const drawinCollection = await this.drawinHttp.findCollectionById(this.activeDrawin.id) 
      if (!drawinCollection) {
        Toastr.errorBottomRight(this, 'Ocorreu um erro ao procurar o acervo')
      }
      this.loadingCollection = false
      this.collection = drawinCollection.data[0]

      this.drawinHttp.listPurchaseOrder(this.activeDrawin.id).then((r) => {
          this.loadingPurchase = false
          this.purchaseList = r.data
        }).catch((e) => {
        }).finally(() => {
          this.loadingCollection = false
        })

   },
   async activated() {
     if(!this.designers){
       this.designers  = (await this.designerHttp.listAllDesigners()).data.map((u: UserModel) => ({ value: u.id, label: u.name, roles: u.roles})) 
       this.designers.unshift({ value: null, label: 'REMOVER DESENHISTA'})
       console.log(this.designers)
     }
     const mapperSteps = this.mapperDesignerId(JSON.parse(JSON.stringify(this.activeDrawin.steps)))
      if(JSON.stringify(mapperSteps) !== JSON.stringify(this.drawinsStepsRef)){
        this.drawinsStepsRef = [...this.mapperDesignerId(JSON.parse(JSON.stringify(this.drawinsStepsRef.map((step: DrawinStep) => {
          const index = this.activeDrawin.steps.findIndex((s: DrawinStep) => s.code === step.code)
          step = this.activeDrawin.steps[index]
          return step
        })))
        )]  
      }
   },
   methods: {
     makeNewStep(): DrawinStep{
       return {
         name: '',
         code: Date.now().toString(),
         needCustomerApprovation: false,
         customerApproves: false,
         internalApprovation: true,
         count: 0,
         designerId: { id: null},
         designerFinish: false,
         deadline: null,
         endDate: null,
         observation: null,
         startDate: null,
         freelancer: false,
       }
     },
     defaultStepSetup(event: any, value: any, step: any){
      //  this.drawinsStepsRef[event].status =  value ?  'Em andamento' : ''
      //  this.drawinsStepsRef[event].startDate =  value ?  this.drawinsStepsRef[event].startDate : null
       this.drawinsStepsRef[event].endDate =  value ?  this.drawinsStepsRef[event].endDate : null
       this.drawinsStepsRef[event].customerApproves =  value ? this.drawinsStepsRef[event].customerApproves : false

       if(this.designers && this.designers.length > 0) {
          const newDesigner = this.designers.find(c => c.value === value)
          if(newDesigner?.roles?.includes('freelancer')) {
            step.freelancer =  true     
          } else  {
            step.freelancer = false
          }
       }
    
          
       
      
     },
     removeStep(index: number){
       this.drawinsStepsRef.splice(index, 1)
     },
     addNewStep(){
       this.drawinsStepsRef.push(this.makeNewStep())
     },
     mapperNewSteps(steps: DrawinStep[]){
       // TODO mover regra pro backend
       return steps.map(s => {
        if(!this.originalStpes.length || !s){
          return Toastr.errorBottomRight(this, 'Ocorreu um erro inesperado. Por favor tente novamente mais tarde.')
        }

        if(s.code && !this.originalStpes.includes(s.code)){
          s.status = (s.designerId as any).id ? 'Em andamento' : 'Aguardando'
          return s
        }

        if(typeof s.freelancerPrice === 'string') {
          s.freelancerPrice = parseFloat(s.freelancerPrice)
        }

  
        return s
       })
     },
     mapperDesignerId(steps: any){
       return steps.map((s: any) => {
        if (typeof s.designerId === 'string') {
          s.designerId = { id: s.designerId }
        }
        s.endDate = s.endDate ?  s.endDate.split('T')[0] : s.endDate 
        s.startDate = s.startDate ? s.startDate.split('T')[0] : s.startDate
        s.designerId = s.designerId ? s.designerId : { id: null }
        return s
      })
     },
     setDesignerFinish(event: any, step: DrawinStep){
      const index = this.drawinsStepsRef.findIndex(s => s.code === step.code)
      this.drawinsStepsRef[index].designerFinish = event.checked
   
      if(!event.checked) {
        this.drawinsStepsRef[index].status = 'Em andamento'
        this.drawinsStepsRef[index].customerApproves = false
      } else {
        this.drawinsStepsRef[index].status = this.drawinsStepsRef[index].needCustomerApprovation ? 'Aguardando cliente' : 'Finalizado'
      }
      step.designerFinish = event.checked
     },
     setFreelancer(event: any, step: DrawinStep){
       const index = this.drawinsStepsRef.findIndex(s => s.code === step.code)
       this.drawinsStepsRef[index].freelancer = event.checked
       step.freelancer = event.checked       
     },
     setCustomerApprovation(event: any, step: DrawinStep){
       const index = this.drawinsStepsRef.findIndex(s => s.code === step.code)
       this.drawinsStepsRef[index].needCustomerApprovation = event.checked
       step.needCustomerApprovation = event.checked
     },
     async saveChanges(event: any) {
      NProgress.start()
      const response =  await this.updateDrawin({id: this.activeDrawin.id, drawin: { steps: this.mapperNewSteps(this.drawinsStepsRef) }, typeJson: true })
      this.drawinsStepsRef =[...this.mapperDesignerId(JSON.parse(JSON.stringify(response.data[0].steps)))]
      Toastr.successBottomRight(this, 'Etapas atualizadas com sucesso')
      NProgress.done()
     },

     isFreelancer(step){
      return step.freelancer 
    },
     ...mapActions({
       removeDrawingStep: 'removeDrawingStep',
       addStepDrawing:'addStepDrawing',
       updateDrawin: 'updateDrawin'
     })
   },
  computed: {
    ...mapGetters({ 
      activeDrawin: 'activeDrawin'
    }),
  },
 })
</script>

