<template>
  <section>
    <Loading v-if="loading" />
    <div v-if="!loading" class="flex flex-col  justify-center w-full items-center">
      <div v-if="signupRegisters.length" class="mb-2 w-full md:w-1/4  ">
        <SearchInput   />
      </div>
        <div v-if="signupRegisters.length" class="w-full md:w-7/12 xl:w-1/2" >
        <span class="float-right text-sm text-gray-500" > {{ signupSelected.length }} selecionado(s) </span>
      </div>
      <SignupCard
        :data="signupRegisters"
        v-on:onSelect="onSelect"
        v-on:onReprobates="listChange($event, 'reprovado', removeSignup)"
        v-on:onApproves="listChange($event, 'aprovado', approveSignup)"
      />
      <NoDataFound v-if="!signupRegisters.length" text="Nenhum cadastro pendente! " />
    </div>

  </section>
</template>

<script lang="ts">
import Vue from 'vue'
import SignupCard from '@/presentation/components/cards/signup-card.vue'
import SearchInput from '@/presentation/components/inputs/input-search.vue'
import NoDataFound from '@/presentation/components/utils/no-data.vue'
import Loading from '@/presentation/components/utils/loading.vue'
import { ISignupManagement } from '@/domain/admin/signup-management/signup-management'
import { mapState, mapActions, mapGetters } from 'vuex'
import { Toastr } from '../../helpers/toastr-configs'
export default Vue.extend({
  name: 'SignupManagement',
  data () {
    return {
      signupSelected: []
    }
  },
  components: {
    SignupCard,
    SearchInput,
    NoDataFound,
    Loading
  },
  props: {
    signup: Object as () => ISignupManagement
  },
  methods: {
    onSelect (userEmail: string) {
      this.toggleUser(userEmail)
    },
    listChange (userEmail: string, text: string, callback: (usersEmails: string[]) => Promise<any>) {
      const users = this.signupSelected.length ? this.signupSelected : [userEmail]
      callback(users)
      Toastr.successBottomRight(this, `Cadastro(s) ${text} com sucesso`)
      this.signupSelected = []
    },
    toggleUser (userEmail: string) {
      const isSelected = this.signupSelected.includes(userEmail)
      if (isSelected) {
        const index = this.signupSelected.indexOf(userEmail)
        this.signupSelected.splice(index, 1)
        return
      }
      this.signupSelected.push(userEmail)
    },
    ...mapActions({
      loadSignups: 'loadSignups',
      removeSignup: 'removeSignup',
      approveSignup: 'approveSignup'
    })
  },
  computed: {
    ...mapState(['singupStore']),
    ...mapGetters(['signupRegisters', 'loading'])
  },
  created () {
    this.loadSignups({ pageNumber: 1, pageSize: 10 })
  }

})
</script>
