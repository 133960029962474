<template>
  <section>
    <div v-if="baseList.length">
      <ul class="flex flex-col p-1 animate__animated animate__fadeInUp " >
        <li class="border flex flex-row  mb-1 rounded-lg relative shadow-sm" v-for="base of baseList" :key="base.id" @click.stop="addBase(base)">
          <i class="fas fa-info absolute right-0 bottom-0 m-3 cursor-pointer text-gray-400  hover:text-blue-500" title="Ir para base" @click.stop="goToBase(base)"></i>
          <i @click.stop="removeBase(base)" title="Remover base" class="fas fa-times absolute top-0 right-0 m-3 cursor-pointer text-gray-400  hover:text-red-500" v-if="selectedBases"></i>
          <div class="select-none cursor-pointer rounded-md flex flex-1 items-center p-4 hover:bg-gray-100 ">
          <div class="flex flex-col w-10 h-10 rounded-full  bg-gray-300 justify-center items-center mr-4"><i class="fas fa-layer-group"></i></div>
          <div class="flex-1 pl-1 mr-16">
          <div class="font-medium">{{ base.name }} <span class="text-sm text-gray-500"> ({{base.code}}) </span> </div>
            <div class="text-gray-600 text-sm">{{ base.provider  }} - {{ base.description }} </div>
            <div class="text-gray-600 text-sm">{{ base.composition  }} </div>
          </div>
        </div>
        </li>
      </ul>
      <Pagination v-if="!selectedBases" class="mb-4" :pageNumber="1" @previous="searchBases($event)" @next="searchBases($event)" :pageLimit="basesPageSize" />
    </div>
    <div class="flex justify-center" v-else-if=" !baseList.length  && !selectedBases">
      <NoData :backButton="false" :createMode="true" createText="Criar nova base" createRoute="/portal/estilo/bases/cadastro" text="Nenhuma base encontrada" class="w-1/3" />
    </div>
  </section>
</template>

<script lang="ts">
import Vue from 'vue'
import { BaseModel } from '../../../domain/models/bases/base-model'
import Pagination from '@/presentation/components/utils/pagination.vue'
import { mapState, mapGetters } from 'vuex'
import NoData from '@/presentation/components/utils/no-data.vue'
import NProgress from 'nprogress'
import { Toastr } from '@/presentation/helpers/toastr-configs'

export default Vue.extend({
  name: 'BaseCardSelection',
  data () {
    return { baseList: [] }
  },
  props: {
    selectedBases: {
      type: Array,
      required: false
    },
    baseSearchText: {
      type: String,
      default: null
    }
  },
  components: {
    Pagination,
    NoData
  },
  methods: {
    addBase (base: BaseModel) {
      if (this.selectedBases) {
        return
      }
        this.$emit('addBase', base)
        Toastr.successBottomRight(this, 'Base adicionada')
    },
    removeBase (base: BaseModel) {
      this.$emit('removeBase', base)
    },
    goToBase (base: BaseModel) {
      let code = base.code
      if (base.psID) {
          code = `ps_${base.psID}`
      }
    
      const routeData = this.$router.resolve(`/portal/estilo/bases/view/${code}`)
      window.open(routeData.href, '_blank')
    },
    async searchBases (pageNumber :number = 1) {
      NProgress.start()
      this.$store.commit('SET_SEARCH_TEXT', this.baseSearchText)
      await this.$store.dispatch('loadBases', { pageNumber, pageSize: 5})
      this.baseList = this.bases
      NProgress.done()
    }
  },
  watch: {
    async baseSearchText () {
      this.searchBases()
    }
  },
  computed: {
    ...mapState(['basesStore']),
    ...mapGetters(['bases', 'searchText', 'basesPageSize'])
  },
  async beforeMount () {
    if (!this.selectedBases) {
      this.searchBases()
      return
    }
    this.baseList = this.selectedBases
  }
})
</script>
