import IFreelancer from '@/domain/freelancers/freelancer'
import { IHttpResponse } from '@/domain/models/api-responses'
import { FreelancerList } from '@/domain/models/freelancer/freelancer-model'
import { getRequest, updateRequest } from '../helper/http-request-decorators'
import { IHttpClient } from '../protocols/httpClient'

export default class Freelancers implements IFreelancer {
  constructor(
    private readonly url: string,
    private readonly token: string,
    private readonly http: IHttpClient<FreelancerList>
  ) {}

  @getRequest
  async list(queryString: string): Promise<IHttpResponse<FreelancerList>> {
    const queryStringParsed = new URLSearchParams(queryString)
    return await this.http.request({
      method: 'get',
      url: `${this.url}`,
      headers: { Authorization: `Bearer ${this.token}` },
      params: queryStringParsed
    })
  }
}
