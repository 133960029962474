import { IBasesManagement } from '@/domain/bases/bases-management'
import { IHttpResponse } from '@/domain/models/api-responses'
import { BaseModel } from '@/domain/models/bases/base-model'

export const basesStoreFactory = (basesHttp: IBasesManagement) => ({
  state (): object {
    return {
      bases: {
        data: [],
        statusCode: 0,
        pagesLimit: 0
      },
      activeBase: {},
      loading: false,
      searchText: '',
      pageNumber: 1,
      pageSize: 10,
      changes: false
    }
  },
  getters: {
    bases (state: any) {
      return state.bases.data
    },
    searchText (state: any) {
      return state.searchText
    },
    basesPageSize (state: any) {
      return state.bases.pagesLimit
    },
    activeBase (state: any) {
      return state.activeBase
    }
  },
  mutations: {
    SET_ACTIVE_BASE (state: any, payload: BaseModel) {
      state.activeBase = payload
    },
    SET_LOADING (state: any) {
      state.loading = true
    },
    ADD_BASE (state: any, payload: BaseModel) {
      state.bases.data.push(payload)
    },
    LOAD_BASES (state: any, payload: IHttpResponse<BaseModel>) {
      state.bases = payload
      state.loading = false
    },
    SET_SEARCH_TEXT (state: any, payload: string) {
      state.searchText = payload
    },
    UPDATE_BASE (state: any, payload: { id: string, base: BaseModel }) {
      const { id, base } = payload
      const index = state.bases.data.findIndex((b: BaseModel) => b.id === id)
      if (index >= 0) {
        state.base.data[index] = base
      }
    },
    CLEAR_BASE(state: any){
      state.bases =  {
        data: [],
        statusCode: 0,
        pagesLimit: 0
      }
    }
  },
  actions: {
    async createBase (context: any, payload: BaseModel) {
      await basesHttp.newBase(payload)
      context.commit('ADD_BASE', payload)
    },
    async loadBases (context: any, payload: { pageNumber: number, searchText?: string, pageSize?: number }) {
      context.commit('SET_LOADING')
      const { pageNumber, pageSize } = payload
      const response = await basesHttp.listAll(pageNumber, context.state.searchText, pageSize || 5)
      context.commit('LOAD_BASES', response)
      return context.state
    },
    async loadBaseByCode (context: any, payload: string) {
      const response = await basesHttp.findByCode(payload)
      if (!response.error) {
        await context.commit('SET_ACTIVE_BASE', response.data[0])
      }
      return response
    },
    async updateBase (context: any, payload: { id: string, base: BaseModel }) {
      const { id, base } = payload
      const response = await basesHttp.updateBase(id, base)
      context.commit('UPDATE_BASE', { id, base: response.data[0] })
      return response
    },
    async clearBase(context: any) {
      context.commit('CLEAR_BASE')
    }
  }
})
