<template>
  <section>
    <div class="flex text-sm flex-row mt-5  w-full -mb-1">
      <router-link :to="detailCreate">
        <button v-if="showAddButton"  @click="$emit('add')"  class="bg-gray-300 px-2 rounded py-1  mr-2 inline hover:bg-gray-400 focus:outline-none">
          <i class="fas fa-plus text-xs"></i>
            {{addButtonText}}
        </button>
      </router-link>

      <button v-if="showConfigButton" @click="$emit('configs')" class="bg-gray-300 px-2 rounded py-1  mr-auto self-end float-right inline hover:bg-gray-400 focus:outline-none">
        <i class="fas fa-sliders-h"></i>
          {{configButtonText}}
      </button>

     
      

    </div>
  </section>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'ListMenuOptions',
  props: {
    detailCreate: {
      type: String,
      default: ''
    },
    addButtonText: {
      type: String,
      default: 'Criar'
    },
    configButtonText : {
      type: String,
      default: "Configurações"
    },
    showAddButton: {
      type: Boolean,
      default: false
    },
    showConfigButton: {
      type: Boolean,
      default: false
    }
  }
})
</script>
