<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'LinkFormButton',
  props: {
    text: String,
    to: String
  }
})
</script>

<template>
   <router-link
      :to="to"
      class=" inline-block hover:underline text-sm focus:outline-none focus:underline">
      {{ text }}
    </router-link>
</template>
