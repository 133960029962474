<template>
  <section class="mt-5  flex justify-between flex-col items-center animate__animated animate__fadeIn">
    <img src="../../assets/svgs/illustrations/no_data.svg" class="mb-4">
    <span class="font-mont font-thin  px-4"> {{ text }}</span>
    <button v-if="createMode" class="text-center w-full text-lg text-blue-500 px-3 py-1 rounded focus:outline-none hover:underline" @click="goToCreate">  {{ createText }} </button>
  </section>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'NoDataFound',
  props: {
    text: String,
    createMode: {
      type: Boolean,
      default: false
    },
    createText: {
      type: String,
      default: null
    },
    createRoute: {
      type: String,
      default: null
    },
    backButton: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    goBack () {
      const pathParser = this.$route.fullPath.split('/')
      const path = '/' + pathParser.slice(1, pathParser.length - 1).join('/')
      this.$router.replace(path)
    },
    goToCreate () {
      this.$router.push(this.createRoute)
    }
  }

})
</script>
