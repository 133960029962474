<template>
  <div class="flex flex-col  w-full justify-center items-center mt-5">
    <div class="">
      <button
       type="button"
        @click="previous"
        :disabled="count === 1"
        :class="{
          'opacity-50': count === 1,
          'hover:bg-gray-400': count > 1
        }"
        class="bg-gray-300  mr-2  p-2 rounded-r rounded-md  focus:outline-none"
      >
        <i class="fas fa-angle-double-left text-xs"></i>
        Anterior
      </button>
      <button
        type="button"
        @click="next"
        :disabled="count === pageLimit"
        :class="{
          'opacity-50': count === pageLimit,
          'hover:bg-gray-400': count !== pageLimit
        }"
        class="bg-gray-300 p-2 rounded-l rounded-md  focus:outline-none"
      >
        Próximo
        <i class="fas fa-angle-double-right text-xs"></i>
      </button>
    </div>
  </div>

</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'Pagination',
  props: {
    pageLimit: {
      type: Number,
      required: true
    },
    pageNumber: {
      type: Number,
      default: 1
    }
  },
  data () {
    return {
      count: this.pageNumber
    }
  },
  methods: {
    previous () {
      this.$emit('previous', --this.count)
    },
    next () {
      this.$emit('next', ++this.count)
    }
  },
  watch: {
    pageNumber () {
      this.count = this.pageNumber
    }

  }
})
</script>
