<template>
<div  class="bg-white flex flex-col p-4 rounded-md w-full md:w-1/2 lg:w-1/4  animate__animated animate__fadeIn" @click.stop=""  >
  <div v-if="!isCustomer" class="flex flex-row md:flex-col md:items-start ">
    <label class="text-xs text-gray-400 font-semibold mr-1 "> Fornecedor </label>
    <span class="uppercase  mb-3"> {{ activeBase.provider || '-' }} </span>
  </div>
  <div class="flex flex-row md:flex-col md:items-start ">
    <label class="text-xs text-gray-400 font-semibold mr-1"> Referência </label>
    <span class="uppercase mb-3"> {{ activeBase.providerRef || '-' }} </span>
  </div>
   <div v-if="!isCustomer" class="flex flex-row md:flex-col md:items-start ">
    <label class="text-xs text-gray-400 font-semibold mr-1"> Código </label>
    <span class="uppercase  mb-3"> {{ activeBase.code || '-' }} </span>
  </div>
   <div v-if="!isCustomer" class="flex flex-row md:flex-col md:items-start ">
    <label class="text-xs text-gray-400 font-semibold mr-1"> Descrição </label>
    <span class="uppercase  mb-3"> {{ activeBase.description || '-'  }} </span>
  </div>
    <div class="flex flex-row md:flex-col md:items-start ">
    <label class="text-xs text-gray-400 font-semibold mr-1"> Nome </label>
    <span class="uppercase  mb-3"> {{ activeBase.name || '-'  }} </span>
  </div>
    <div class="flex flex-row md:flex-col md:items-start ">
    <label class="text-xs text-gray-400 font-semibold mr-1"> Composição </label>
    <span class="uppercase  mb-3"> {{ activeBase.composition || '-' }} </span>
  </div>
    <div class="flex flex-row md:flex-col md:items-start ">
    <label class="text-xs text-gray-400 font-semibold mr-1"> Tecido </label>
    <span class="uppercase  mb-3"> {{ activeBase.tissue || '-' }} </span>
  </div>
  <div class="flex flex-col">
    <div class="flex flex-wrap">
      <span  v-for="tags of activeBase.tags" :key="tags"  class="rounded-full text-xs bg-gray-300 border-gray-400 border px-2 mr-2 mb-1 "> {{ tags }}</span>
    </div>
  </div>
  <button v-if="canEditBase" class="bg-blue-600 p-2 rounded uppercase mt-2 text-white" @click="goTo"> Editar base</button>
  <button class="bg-red-600 p-2 rounded uppercase mt-2 text-white" @click="$emit('close')"> Fechar </button>
</div>
</template>

<script lang="ts">
import { BaseModel } from '@/domain/models/bases/base-model'
import { Token } from '@/presentation/helpers/token-local-storage'
import Vue from 'vue'
export default Vue.extend({
  name: 'BasesModalCard',
  props: {
    activeBase: {
      type: Object as () => BaseModel
    }
  },
  methods: {
    goTo() {
      let code = this.activeBase.code
      if (this.activeBase.psID) {
        code = `ps_${this.activeBase.psID}`
      }
      const route = this.$router.resolve({path: `/portal/estilo/bases/view/${code}`})
      window.open(route.href, '_blank')
    }
  },
  computed: {
    canEditBase() {
      return Token.validRole('manager', 'administrator')
    },
    isCustomer(){
      return Token.validRole('customer')
    }
  }
})
</script>