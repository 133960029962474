<template>
<section class="flex flex-col lg:flex-row ">
  <div v-for="drawing of [1, 2, 3,4,5,6]" :key="drawing.id"  class="flex flex-col b-2 w-full lg:w-1/6 mr-2 bg-white px-3 py-8 rounded-lg border shadow text-center animate-pulse" >
    <div class="mb-3 bg-gray-300 rounded-full w-40 h-40 self-center"></div>
    <span class="uppercase text-xl font-medium rounded-md  bg-gray-200"> &#8203;  </span>
    <span class="font-medium mb-2  bg-gray-200 mt-2 "> &#8203; </span>
    <div class="mt-2 flex flex-col p-2  rounded-lg  bg-gray-100">
    <div class="flex flex-wrap items-center justify-center h-24 "></div></div>
    <div class="flex flex-row self-end py-2 absolute bottom-0 ">
      <button  class="focus:outline-none mr-1 mt-4 flex justify-center  items-center bg-gray-200 w-6 h-6 py-1  rounded-full  text-white text-xs flex-col"></button>
    </div>
  </div>
</section>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: "DrawinListSkeleton"
})
</script>