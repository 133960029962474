<template>
  <section>
    <div
      v-for="base in bases"
      :key="base.id"
      @click="goToDetail(base)"
      class="mt-3 flex border flex-col w-full lg:flex-row lg:justify-around  lg:items-center  bg-white   p-2 relative cursor-pointer  hover:-translate-y-1 hover:bg-gray-100"
    >
      <div class="px-2 self-center">
        <i
          v-if="base.complete"
          title="cadastro completo"
          class="fas fa-check text-green-700 text-sm"
        ></i>
        <i
          v-if="!base.complete"
          title="cadastro incompleto"
          class="fas fa-minus text-yellow-500 text-sm"
        ></i>
      </div>
      <div v-for="(header, key) in headers" :key="header">
        <BasesCard :title="header" :value="base[key] || '-'" />
      </div>
      <span
        class="absolute right-0 mr-4 self-center text-sm text-gray-500 hidden lg:inline "
      >
        <i class="fas fa-chevron-right"></i
      ></span>
    </div>
  </section>
</template>

<script lang="ts">
import Vue from 'vue'
import BasesCard from '@/presentation/components/cards/bases-card.vue'
import { BaseModel } from '@/domain/models/bases/base-model'

export default Vue.extend({
  name: 'BasesList',
  components: {
    BasesCard
  },
  data() {
    return {
      headers: {
        provider: 'Fornecedor',
        providerRef: 'Referência',
        code: 'Código',
        description: 'Descrição',
        name: 'Nome',
        composition: 'Composição',
        tissue: 'Tecido'
      }
    }
  },
  props: {
    bases: {
      type: Array,
      required: true
    }
  },
  methods: {
    goToDetail(base: BaseModel) {
      if (base.psID !== null && base.psID !== undefined) {
        this.$router.push({ path: `bases/view/ps_${base.psID}` })
        return
      }
      this.$router.push({ path: `bases/view/${base.code}` })
    }
  }
})
</script>
