import { IHttpClient } from "@/data/protocols/httpClient"
import { LucinRoute } from "@/main/configs/routes/routes-paths"
import { Token } from "@/presentation/helpers/token-local-storage"
import { IColorsService } from "@/domain/colors/colors"
import { DrawinColor } from "@/domain/models/drawins/drawin-model"
import ColorsService from "@/data/colors/colors"

export const colorsHttpFactory = (http: IHttpClient<DrawinColor>): IColorsService=> {
    return new ColorsService(LucinRoute.colors, Token.getToken(), http)
}
  