import { ISignupManagement } from '@/domain/admin/signup-management/signup-management'
import { UserModel } from '@/domain/models/user-model'

export const signupStoreFactory = (signup: ISignupManagement) => ({
  state (): { signupRegister: UserModel[], loading: boolean } {
    return {
      signupRegister: null,
      loading: true
    }
  },
  getters: {
    signupRegisters (state: any) {
      return state.signupRegister.data
    },
    loading (state: any) {
      return state.loading
    }
  },
  mutations: {
    load (state: any, payload: UserModel[]) {
      state.signupRegister = payload
      state.loading = false
    },
    push (state: any, payload: UserModel[]) {
      state.signupRegister.data.push(payload)
    },
    removeFromList (state: any, payload: string[]) {
      state.signupRegister.data = state.signupRegister.data.filter((s: UserModel) => !payload.includes(s.email))
    }
  },
  actions: {
    async loadSignups (context: any, payload: { pageNumber: number, pageSize: number }) {
      const result = await signup.list(payload.pageNumber, payload.pageSize)
      context.commit('load', result)
    },
    async removeSignup (context: any, payload: string[]) {
      await signup.reprobate(payload)
      context.commit('removeFromList', payload)
    },
    async approveSignup (context: any, payload: string[]) {
      await signup.approves(payload)
      context.commit('removeFromList', payload)
    }
  }
})
