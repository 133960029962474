export function updateRequest(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value

  descriptor.value = async function (...args: any[]) {
    const response = await originalMethod.apply(this, args)
    const { errorMessage, statusCode, error } = response

    if (error) {
      throw { errorMessage, statusCode }
    }

    const { data } = response

    return {
      data,
      statusCode
    }
  }
  
  return descriptor
}

export function getRequest(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value

  descriptor.value = async function (...args: any[]) {
    const response = await originalMethod.apply(this, args)
    const { errorMessage, statusCode, error } = response
    if (error) {
      throw { errorMessage, statusCode }
    }
    const { data, pagesLimit, dataCount } = response

    return {
      data,
      statusCode,
      pagesLimit,
      dataCount
    }
  }

  return descriptor
}



export function getFileRequest(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value

  descriptor.value = async function (...args: any[]) {
    
    return await originalMethod.apply(this, args)
   
  }

  return descriptor
}



export function postRequest(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value

  descriptor.value = async function (...args: any[]) {
    const response = await originalMethod.apply(this, args)
    const { errorMessage, statusCode, error } = response

    if (error) {
      throw { errorMessage, statusCode }
    }

    const { data } = response

    return {
      data,
      statusCode
    }
  }

  return descriptor
}
