import Freelancers from '@/data/freelancers/freelancer'
import { IHttpClient } from '@/data/protocols/httpClient'
import { FreelancerList } from '@/domain/models/freelancer/freelancer-model'
import { LucinRoute } from '@/main/configs/routes/routes-paths'
import { Token } from '@/presentation/helpers/token-local-storage'

export const freelancerHttpFactory = (
  http: IHttpClient<FreelancerList>
): Freelancers => {
  return new Freelancers(LucinRoute.freelancers, Token.getToken(), http)
}
