<template>
    <label class="text-green-500 font-semibold rounded-lg cursor-pointer focus:outline-none">
      <input  @click="toggle($event.srcElement.checked, name)" :checked="value" type="checkbox" class="form-checkbox focus:outline-none">
      <span class="font-medium text-gray-500"> {{ label }} </span>
    </label>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  inheritAttrs: true,
  name: 'SampleCheckbox',
  props: {
    label: String,
    name: String,
    value: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    toggle (checked: boolean, name: string) {
      this.$emit('chkToggle', { name, checked })
    }
  }
})
</script>

<style lang="scss" scoped>
  [type="checkbox"] {
    box-sizing: border-box;
    background-color: red;
    padding: 0;
  }

  .form-checkbox {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
    display: inline-block;
    vertical-align: middle;
    background-origin: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    flex-shrink: 0;
    color: currentColor;
    background-color: #fff;
    border-color: #e2e8f0;
    border-width: 1px;
    border-radius: 0.25rem;
    height: 1.2em;
      width: 1.2em;
  }

  .form-checkbox:checked {
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
    border-color: transparent;
    background-color: currentColor;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  }
</style>
