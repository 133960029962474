import ConfigService from "@/data/configs/config"
import { IHttpClient } from "@/data/protocols/httpClient"
import { IConfig } from "@/domain/config/config"
import { ConfigModel } from "@/domain/models/configs/config-model"
import { LucinRoute } from "@/main/configs/routes/routes-paths"
import { Token } from "@/presentation/helpers/token-local-storage"

export const configHttpFactory = (http: IHttpClient<ConfigModel>): IConfig=> {
    return new ConfigService(LucinRoute.config, Token.getToken(), http)
}
  