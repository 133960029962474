<script lang="ts">
import Vue from 'vue'
import SampleInput from '@/presentation/components/forms/sample-input.vue'
import FormTextHeader from '@/presentation/components/forms/form-header.vue'
import SendFormButton from '@/presentation/components/forms/send-form-button.vue'
import LinkButton from '@/presentation/components/buttons/link-router-button.vue'
import { IRecoverPassword } from '../../../domain/auth/recover-password'
import { Toastr } from '../../helpers/toastr-configs'
import { Token } from '../../helpers/token-local-storage'

export default Vue.extend({
  name: 'ResetPasswordPage',
  components: {
    SampleInput,
    FormTextHeader,
    SendFormButton,
    LinkButton
  },
  props: {
    recoverPassword: Object as () => IRecoverPassword
  },
  methods: {
    async onSubmit (data: {password: string, password_confirm: string}) {
      try {
        const token = this.$route.query.ref as string
        const { password, password_confirm: passwordConfirmation } = data
        const response = await this.recoverPassword.send({
          token,
          password,
          passwordConfirmation
        })
        Toastr.successBottomCenter(this, 'Senha alterada com sucesso!')
        Token.setToken(response)
        this.$router.push('/portal')
      } catch (error) {
        Toastr.errorTopRight(this, error.errorMessage)
      }
    }
  },
  created () {
    if (!this.$route.query.ref) {
      this.$router.push('/forgot_password')
    }
  }

})
</script>

<template>
    <!-- {{ this.$route.query }} -->
   <section class="w-full max-w-xs bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mx-2 animate__animated animate__fadeIn">
    <FormTextHeader title="Alterar senha" />
    <FormulateForm values="values" @submit="onSubmit">
      <SampleInput label="Nova senha" name="password" validation="^required" placeholder="informe uma senha"  type="password"/>
      <SampleInput label="Confirme a senha" name="password_confirm" validation="^required|confirm" placeholder="confirme sua senha"  type="password" />
      <div class="w-full mb-1">
        <SendFormButton text="ENVIAR"/>
        <LinkButton class="text-red-500 block w-full  text-center  " to="/" text="Voltar"/>
      </div>
    </FormulateForm>
  </section>
</template>
