
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'WrapperWithoutNavigation',
  beforeCreate () {
    document.body.className = 'bg-svg-01'
  }
})
</script>

<template>
  <div class="container">
    <div class="grid grid-cols-1 h-screen w-screen ">
      <div class="flex flex-row items-center justify-center h-screen">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
