<template>
  <section> 
    <div class="flex flex-col lg:flex-row  ">
      <ChartCard
        title="Quantidade de desenhos cadastrados"
        subtitle="Desenhos cadastrados agrupados por tipo"
        :loading="drawinsGroupedByYearsAndMonths.loading"
      > 
        <Bars
          :labels="drawinsGroupedByYearsAndMonths.labels"
          :datasets="drawinsGroupedByYearsAndMonths.dataset"
        /> 
      </ChartCard>
    </div>
  </section>
</template>
<script lang="ts">
  import Vue from 'vue'
  import Bars from '@/presentation/components/charts/chart-bar.vue'
  import ChartCard from '@/presentation/components/charts/_base/_card-chart.vue'
  import { Toastr } from '@/presentation/helpers/toastr-configs'
  export default Vue.extend({
    name: 'DashboardPage',
    components: {
      Bars,
      ChartCard
    },
    props: {
      drawinsReport: {
        required: true,
      
      }
    },
    data() {
      return {
        drawinsGroupedByYearsAndMonths: {
          loading: true,
          labels: [],
          dataset: []
        },
        designersPerfomace: {
          loading: true,
          labels: [],
          dataset: []
        }
      }
    },
    async created() {

    try {
      const drawinsGrouped = await this.drawinsReport.groupByMonthAndYears()
      this.drawinsGroupedByYearsAndMonths.loading = false
      this.drawinsGroupedByYearsAndMonths.dataset = drawinsGrouped.data[0].result
      this.drawinsGroupedByYearsAndMonths.labels = drawinsGrouped.data[0].labels
      }catch(error){
        console.log(error)
        Toastr.errorBottomRight(this,'Oops, occoreu um erro ao gerar o relatorio')
      }
    
    }
   
  })
</script>