<template>
  <section>
    <div class="flex flex-col w-full">
      <div class="flex  w-full  mb-4  justify-between  gap-2">
        <div class=" flex gap-2  w-full  text-sm">
          <input
            @input="onQuickSearch($event.target.value)"
            v-model="searchText"
            class="w-full border rounded pl-4 py-1 focus:border-2 focus:border-blue-500 focus:outline-none"
            type="text"
            placeholder="Pesquisa rápida (código, nome do desenho ou nome do cliente)"
            name=""
            id=""
          />

          <span
            @click="showFilters = !showFilters"
            class="flex gap-2 border bg-white  py-2 px-4 items-center justify-between  rounded-md cursor-pointer hover:bg-gray-100 "
            :class="{
              ' border-blue-500  text-blue-500': queryString !== ''
            }"
          >
            <span>Filtros </span>
            <i v-if="queryString === ''" class="fas fa-sliders-h"></i>
            <i
              v-else
              @click="onFilterFreelancer('')"
              class="fas fa-times text-red-700 cursor-pointer"
            ></i>
          </span>

          <button
            @click="sendBulkMarkAsPaid()"
            :disabled="selectedDrawins.length <= 0"
            :class="{
              'bg-gray-300 text-gray-500 cursor-not-allowed':
                selectedDrawins.length <= 0,
              'bg-green-100 text-green-700 cursor-pointer  border-green-300 hover:bg-green-300 focus:outline-none':
                selectedDrawins.length > 0
            }"
            class="h-10  px-2 rounded py-1  text-sm whitespace-no-wrap"
          >
            <i class="fas fa-check  p-1"></i> Marcar como pago
          </button>

          <button
            @click="sendBulkReport()"
            :disabled="selectedDrawins.length <= 0"
            :class="{
              'bg-gray-300 text-gray-500 cursor-not-allowed':
                selectedDrawins.length <= 0,
              'bg-blue-100 text-blue-700 cursor-pointer  border-blue-300 hover:bg-blue-200 focus:outline-none':
                selectedDrawins.length > 0
            }"
            class="h-10  px-2 rounded py-1  text-sm whitespace-no-wrap"
          >
            <i class="fas fa-file-alt  p-1"></i> Enviar relatório
          </button>
        </div>
      </div>

      <div
        class="self-center overflow-x-auto border w-full  text-sm rounded-lg border-gray-400"
      >
        <div class="inline-block min-w-full align-middle">
          <div class="overflow-hidden ">
            <table class="min-w-full divide-y divide-gray-300 bg-white ">
              <thead class="bg-gray-100">
                <tr>
                  <th
                    scope="col"
                    class="py-3 px-6 text-sm  tracking-wider text-center uppercase "
                  ></th>
                  <th
                    scope="col"
                    class="hidden  lg:inline py-3 px-6 text-sm  tracking-wider text-center uppercase "
                  ></th>
                  <th
                    scope="col"
                    class="py-3 px-6 text-sm  tracking-wider text-left uppercase "
                  >
                    Lucin Ref
                  </th>
                  <th
                    scope="col"
                    class="py-3 px-6 text-sm  tracking-wider text-left uppercase "
                  >
                    Desenho
                  </th>
                  <th
                    scope="col"
                    class="py-3 px-6 text-sm  tracking-wider text-left uppercase "
                  >
                    Freelancer
                  </th>
                  <th
                    scope="col"
                    class="py-3 px-6 text-sm  tracking-wider text-left uppercase "
                  >
                    Data desenho
                  </th>
                  <th
                    scope="col"
                    class="py-3 px-6 text-sm  tracking-wider text-left uppercase "
                  >
                    Valor (R$)
                  </th>
                  <th
                    scope="col"
                    class="py-3 px-6 text-sm  tracking-wider text-left uppercase "
                  >
                    Pagamento
                  </th>
                  <th
                    scope="col"
                    class="py-3 px-6 text-sm  tracking-wider text-left uppercase "
                  >
                    Progresso
                  </th>
                </tr>
              </thead>
              <tbody
                class="bg-white divide-y divide-gray-200      text-gray-800"
              >
                <tr
                  class="hover:bg-gray-100 cursor-pointer"
                  :key="freelancer.id"
                  v-for="freelancer of freelancerDrawin"
                  @click="setActiveFreelancer(freelancer)"
                >
                  <td @click.stop="" class="py-3 px-6  text-sm  ">
                    <SampleCheckbox
                      @chkToggle="selectFreelancer(freelancer.id)"
                      class="text-blue-500"
                      label=""
                    />
                  </td>
                  <td class="py-3 px-6  text-sm hidden  lg:inline   ">
                    <img
                      class="rounded-lg   w-10 h-10"
                      width="200"
                      height="200"
                      :src="
                        freelancer.imageUrl ||
                          require('../../assets/images/no_image.png')
                      "
                      alt=""
                    />
                  </td>
                  <td class="py-3 px-6 text-sm capitalize  whitespace-nowrap ">
                    {{ freelancer.lucinRef || '-' }}
                  </td>
                  <td class="py-3 px-6 text-sm cap  whitespace-nowrap ">
                    {{ freelancer.name || '-' }}
                  </td>

                  <td class="py-3 px-6 text-sm  capitalize  ">
                    <span
                      v-if="
                        new Set(
                          freelancer.steps
                            .filter(s => s.designerId?.name)
                            .map(s => s.designerId?.name)
                        ).size > 1
                      "
                    >
                      <img
                        v-for="(steps, index) of freelancer.steps"
                        class="rounded-full border border-gray-300 w-10 h-10 inline-block hidden  md:inline-block "
                        :class="{
                          '-ml-3': index > 0
                        }"
                        width="200"
                        height="200"
                        :src="
                          steps.designerId?.avatarUrl ||
                            require('../../assets/images/no_avatar.png')
                        "
                        alt=""
                      />
                    </span>

                    <img
                      v-else
                      class="rounded-full border capitalize hidden  md:inline-block   border-gray-300 w-10 h-10"
                      width="200"
                      height="200"
                      :src="
                        freelancer.steps[0]?.designerId?.avatarUrl ||
                          require('../../assets/images/no_avatar.png')
                      "
                      alt=""
                    />

                    {{
                      new Set(
                        freelancer.steps
                          .filter(s => s.designerId?.name)
                          .map(s => s.designerId?.name)
                      ).size == 1
                        ? freelancer.steps[0].designerId.name.split(' ')[0]
                        : '+1 freelancer'
                    }}
                  </td>
                  <td class="py-3 px-6 text-sm capitalize  whitespace-nowrap ">
                    {{
                      new Date(freelancer.registerDate).toLocaleDateString(
                        'pt-BR'
                      ) ||
                        new Date(freelancer.createdAt).toLocaleDateString(
                          'pt-BR'
                        ) ||
                        '-'
                    }}
                  </td>
                  <td
                    class="py-3 px-6 text-sm  capitalize  whitespace-nowrap  text-gray-700 font-semibold "
                  >
                    R${{
                      freelancer.steps
                        .reduce((p, c) => c.freelancerPrice + p, 0)
                        .toFixed(2)
                        .replace('.', ',') || new Number().toFixed(2)
                    }}
                  </td>
                  <td class="py-3 px-6 text-sm capitalize   whitespace-nowrap ">
                    <span
                      class=" text-yellow-600"
                      :class="{
                        'text-green-600': freelancer.freelancerPaid
                      }"
                    >
                      <i
                        class="fas "
                        :class="{
                          'fa-check': freelancer.freelancerPaid,
                          'fa-clock': !freelancer.freelancerPaid
                        }"
                      ></i>
                      {{
                        freelancer.freelancerPaid ? 'Pago' : 'Pendente'
                      }}</span
                    >
                  </td>
                  <td class="py-3 px-6 text-sm    whitespace-nowrap ">
                    <div
                      class="w-full  lg:w-24 flex flex-col self-center lg:self-auto mt-2 lg:mt-0"
                    >
                      <div class="flex bg-gray-200 rounded-lg  relative h-4">
                        <div
                          class=" cursor-default bg-green-400 text-xs font-bold leading-none flex items-center rounded-lg  justify-center py-1 text-center text-white"
                          v-bind:style="{
                            width:
                              getFreelancerDrawinProgress(freelancer) * 100 +
                              '%'
                          }"
                        ></div>
                        <div
                          class=" absolute w-full h-full text-xs font-semibold text-gray-800 justify-center   text-center"
                        >
                          {{
                            getFreelancerDrawinProgress(freelancer) > 0
                              ? (
                                  getFreelancerDrawinProgress(freelancer) * 100
                                ).toFixed(0) + '%'
                              : '0%'
                          }}
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="editMode"
      class="bg-white  fixed z-50 h-screen top-0 right-0 w-full lg:w-1/4 border-l border-gray-400 leading-6 font-euclid   "
    >
      <div class="flex flex-col h-full w-full overflow-y-auto ">
        <div
          class="flex justify-between border-b border-1 py-4 px-6  font-semibold text-gray-700 mb-8"
        >
          <span class="">Gerenciar Freelancer</span>
          <span class="pl-4">
            <i
              @click="editMode = !editMode"
              class="fas fa-times cursor-pointer transition duration-500 ease-in-out hover:border-red-600 hover:text-red-600"
            ></i>
          </span>
        </div>

        <div class="flex flex-col py-6 flex-grow  w-full p-4">
          <div class="flex flex-col items-center w-full justify-center mb-6 ">
            <img
              class="rounded-lg h-40 w-40 object-cover "
              :src="
                activeFreelancer.imageUrl ||
                  require('../../assets/images/no_image.png')
              "
            />
            <span class="p-4">
              {{ activeFreelancer.name || activeFreelancer.lucinRef || '-' }}
              <i
                @click="goToDrawin(activeFreelancer.id)"
                class="p-2 fas fa-external-link-alt text-sm cursor-pointer text-blue-600"
              ></i>
            </span>
          </div>

          <div
            class="flex flex-col   border-t  w-full mt-2"
            v-for="step of activeFreelancer.steps"
          >
            <div class="flex">
              <div
                class="flex flex-col font-semibold justify-between bg-gray-100  border-l border-r w-full text-xs "
              >
                <!-- <span class="p-2"> # </span> -->
                <span class="p-2 border-b"> Freelancer </span>
                <span class="p-2 border-b"> Step </span>
                <span class="p-2 border-b"> Inicio </span>
                <span class="p-2 border-b"> Termino </span>
                <span class="p-2 border-b"> Status </span>
                <span class="p-2 border-b"> R$ </span>
              </div>

              <div
                class="flex flex-col justify-between  border-r  w-full text-xs capitalize"
              >
                <span class="p-2  border-b"> {{ step.designerId.name }} </span>
                <span class="p-2  border-b"> {{ step.name }} </span>
                <span class="p-2  border-b">
                  {{
                    step.startDate
                      ? new Date(step.startDate).toLocaleDateString('pt-br')
                      : '-'
                  }}
                </span>
                <span class="p-2  border-b">
                  {{
                    step.endDate
                      ? new Date(step.endDate).toLocaleDateString('pt-br')
                      : '-'
                  }}
                </span>
                <span class="p-2  border-b"> {{ step.status }} </span>
                <span class="p-2  border-b font-bold">
                  R$ {{ step.freelancerPrice?.toFixed(2) || 0 }}
                </span>
              </div>
            </div>
          </div>

          <div class="self-end p-4">
            Total: R$
            {{
              activeFreelancer.steps
                ?.reduce((p, c) => {
                  if (!c.freelancerPrice) {
                    return 0
                  }
                  return c.freelancerPrice + p
                }, 0)
                ?.toFixed(2) || 0
            }}
          </div>

          <div class=" flex mt-4">
            <button
              @click="markAsPaid(activeFreelancer)"
              :disabled="activeFreelancer.freelancerPaid"
              class="bg-white border border-green-400  px-2 rounded py-1  text-sm mr-2 self-end float-right inline hover:bg-green-200 focus:outline-none"
              :class="{
                'bg-green-200 cursor-not-allowed':
                  activeFreelancer.freelancerPaid
              }"
            >
              <i class="fas fa-check text-green-800 p-1"></i
              >{{
                activeFreelancer.freelancerPaid ? 'Pago' : 'Marcar como pago'
              }}
            </button>
            <button
              @click="sendReport(activeFreelancer)"
              class="bg-white border border-blue-400 px-2 rounded py-1  text-sm mr-auto self-end float-right inline hover:bg-blue-200 focus:outline-none"
            >
              <i class="fas fa-file-alt text-blue-600 p-1"></i> Gerar relatório
            </button>
          </div>
        </div>

        <div
          id="footer"
          class="sticky z-50 bottom-0 w-full bg-white  border-t border-1 py-4 px-6 flex justify-end"
        >
          <span
            @click="editMode = !editMode"
            class="border px-2 py-1 rounded-md cursor-pointer transition duration-500 ease-in-out hover:border-red-600 hover:text-red-600"
            >Fechar</span
          >
        </div>
      </div>
    </div>

    <div
      v-if="editMode"
      tabindex="2"
      @click="editMode = false"
      class="mask absolute w-full bg-gray-500 z-40 opacity-50 h-screen inset-0 cursor-pointer"
    ></div>

    <KeepAlive>
      <DrawinFilterSideBar
        v-if="showFilters"
        @opened="showFilters = $event"
        @onFilter="onFilterFreelancer"
        @onGenerateDrawinReport=""
        :showExcelButton="false"
        title="Filtros de Freelancer"
        :freelancerContext="true"
      />
    </KeepAlive>

    <Pagination
      class="mb-4"
      :pageNumber="pageNumber"
      @previous="onPaginate($event)"
      @next="onPaginate($event)"
      :pageLimit="pagesLimit"
    />
  </section>
</template>
<script lang="ts">
import Vue from 'vue'
import SearchInput from '@/presentation/components/inputs/input-search.vue'
import { FreelancerDrawin } from '@/domain/models/freelancer/freelancer-model'
import ListMenuOptions from '@/presentation/components/lists/list-menu-options.vue'
import Pagination from '@/presentation/components/utils/pagination.vue'
import IFreelancer from '@/domain/freelancers/freelancer'
import SampleCheckbox from '@/presentation/components/forms/sample-checkbox.vue'
import DrawinFilterSideBar from '@/presentation/components/drawins-view/DrawinFilterSideBar.vue'

import nProgress from 'nprogress'
import { IDrawin } from '@/domain/drawins/drawins'
import { Toastr } from '@/presentation/helpers/toastr-configs'
export default Vue.extend({
  name: 'FreelancerManagementPage',
  components: {
    SearchInput,
    ListMenuOptions,
    Pagination,
    SampleCheckbox,
    DrawinFilterSideBar
  },
  props: {
    freelancerHttp: {
      required: true,
      type: Object as () => IFreelancer
    },
    drawinHttp: {
      required: true,
      type: Object as () => IDrawin
    }
  },
  data() {
    return {
      editMode: false,
      freelancerDrawin: [],
      activeFreelancer: null,
      pageNumber: 1,
      pageSize: 8,
      pagesLimit: 1,
      searchText: '',
      queryString: '',
      selectedDrawins: [],
      searchTimeout: null,
      showFilters: false
    }
  },

  methods: {
    async onQuickSearch(searchText: string) {
      clearTimeout(this.searchTimeout)
      this.searchTimeout = setTimeout(async () => {
        this.searchText = searchText
        this.pageNumber = 1
        await this.findFreelancer(``)
      }, 500)
    },
    async findFreelancer() {
      nProgress.start()
      try {
        const result = await this.freelancerHttp.list(
          `pageSize=${this.pageSize}&pageNumber=${this.pageNumber}&searchText=${this.searchText}&${this.queryString}&`
        )
        if (!result) {
          return
        }
        this.pagesLimit = result.pagesLimit
        this.freelancerDrawin = result.data
      } catch (error) {}
      nProgress.done()
    },
    async onPaginate(page: number) {
      this.pageNumber = page
      this.findFreelancer()
    },
    async onFilterFreelancer(queryParams: string) {
      this.showFilters = !this.showFilters
      if (this.queryString == queryParams) return
      this.searchText = ''
      this.queryString = queryParams
      this.pageNumber = 1
      const result = await this.freelancerHttp.list(
        `pageNumber=${this.pageNumber}&pageSize=${this.pageSize}&${queryParams}`
      )
      if (!result) {
        return
      }
      this.pagesLimit = result.pagesLimit
      this.freelancerDrawin = result.data
    },
    setActiveFreelancer(activeFreelancer: FreelancerDrawin) {
      this.activeFreelancer = activeFreelancer
      this.editMode = true
    },
    goToDrawin(drawinId: string) {
      const route = this.$router.resolve({
        path: `/portal/estilo/desenhos/${drawinId}/arte`
      })
      window.open(route.href, '_blank')
    },
    selectFreelancer(drawinId: string) {
      const index = this.selectedDrawins.indexOf(drawinId)
      if (index === -1) {
        return this.selectedDrawins.push(drawinId)
      }
      this.selectedDrawins.splice(index, 1)
    },
    getFreelancerDrawinProgress(activeFreelancer: FreelancerDrawin): number {
      let stepsFinished = 0
      for (const step of activeFreelancer.steps) {
        if (step.designerFinish) {
          stepsFinished++
        }
      }
      return stepsFinished / activeFreelancer.steps.length
    },

    async markAsPaid(activeFreelancer: FreelancerDrawin) {
      try {
        nProgress.start()
        activeFreelancer.freelancerPaid = true
        this.activeFreelancer = {
          ...activeFreelancer,
          drawin: { ...activeFreelancer, freelancerPaid: true }
        }
        await this.drawinHttp.markFreelancerPaid([activeFreelancer.id])
        Toastr.successBottomRight(this, 'Freelancer pago')
        nProgress.done()
      } catch (error) {
        Toastr.errorTopRight(this, error)
      }
    },
    async sendBulkMarkAsPaid() {
      try {
        nProgress.start()
        await this.drawinHttp.markFreelancerPaid(this.selectedDrawins)
        await this.freelancerSearchText(this.searchText)
        Toastr.successBottomRight(this, 'Freelancer pago')
        nProgress.done()
      } catch (error) {
        Toastr.errorTopRight(this, error)
      }
    },
    async sendReport(activeFreelancer: FreelancerDrawin) {
      try {
        nProgress.start()
        await this.drawinHttp.sendFreelancerReport([activeFreelancer.id])
        Toastr.successBottomRight(
          this,
          'O relatório está sendo gerado e será enviado no seu e-mail!'
        )
        nProgress.done()
      } catch (error) {
        Toastr.errorTopRight(this, error)
      }
    },
    async sendBulkReport() {
      try {
        if (this.selectedDrawins.length <= 0) {
          return
        }
        nProgress.start()
        await this.drawinHttp.sendFreelancerReport(this.selectedDrawins)
        Toastr.successBottomRight(
          this,
          'O relatório está sendo gerado e será enviado no seu e-mail!'
        )
        nProgress.done()
      } catch (error) {
        Toastr.errorTopRight(this, error)
      }
    }
  },
  async created() {
    this.findFreelancer(this.searchText)
    document.onkeydown = evt => {
      ;(evt as any) = evt || window.event
      if (evt.keyCode == 27) {
        if (this.editMode) {
          this.editMode = false
        }
      }
    }
  }
})
</script>

<style>
/* CHECKBOX TOGGLE SWITCH */
/* @apply rules for documentation, these do not work as inline style */
.toggle-checkbox:checked {
  @apply: right-0 border-green-400;
  right: 0;
  border-color: #68d391;
}
.toggle-checkbox:checked + .toggle-label {
  @apply: bg-green-400;
  background-color: #68d391;
}
</style>
