
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'Loading'
})
</script>

<template>
<div class="loader-wrap">
   <div class="loader"><span class="loader-item"></span><span class="loader-item"></span><span class="loader-item"></span><span class="loader-item"></span><span class="loader-item"></span><span class="loader-item"></span><span class="loader-item"></span><span class="loader-item"></span>
      <span
         class="loader-item"></span><span class="loader-item"></span>
   </div>
</div>
</template>

<style lang="scss" scoped>

   .loader{
      $loader-size: 4.5;
      $animation-duration: 2s;
      $animation-speed: 10;
      $animation-scale: 1.5;
      $loader-color: #4299e1;
      $loader-text-color: #4299e1;

      position: absolute;
      left: 0;right: 0;top: 0;bottom: 0;
      margin: auto;
      height: ($loader-size*10px);
      width: ($loader-size*20px);
      box-sizing: border-box;

   .loader-item{
      position: relative;
      float: left;
      height: ($loader-size*10px);
      width: ($loader-size*1px);
      margin: 0 ($loader-size/2)*1px;
      background-color: $loader-color;

      @for $i from 1 through 10 {
         &:nth-child(#{$i}){
            animation: loader-item-#{$i} $animation-duration linear infinite;
         }
      }

   }

   &:after{
      content: 'Carregando...';
      font-size: ($loader-size*4px);
      color: $loader-text-color;
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: ($loader-size*-8px);
      margin: auto;
   }

   @for $i from 0 through 9 {
      @keyframes loader-item-#{$i+1}{
         #{$i+1+$i*5 * 1%}{
            transform: scaleY(1);
         }
         #{$i+1+$i*5+$animation-speed * 1%}{
            transform: scaleY($animation-scale);
         }
         #{$i+1+$i*5+$animation-speed*2 * 1%}{
            transform: scaleY(1);
         }
         100%{
            transform: scaleY(1);
         }
      }
   }

}
</style>
