<template>
  <section class="w-screen h-screen">
    <NavigationWrapper>
      <router-view></router-view>
    </NavigationWrapper>
  </section>
</template>

<script>
import NavigationWrapper from './navigation/nav.vue'

export default {
  components: {
    NavigationWrapper
  },
  beforeCreate() {
    document.body.className = 'bg-default'
  }
}
</script>
