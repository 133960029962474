<template>
  <section class="w-full"> 

    <div class="flex flex-col items-center justify-center mb-12">
        <SearchInput  v-if="customerSearchText || customerList.length" :text="customerSearchText" @search="listAllCustomers" class="w-full md:w-1/3 self-center" />
    </div>

    <div class="flex flex-col w-full mb-4">
        <ListMenuOptions 
                    :showConfigButton="false" 
                    :showAddButton="true" 
                    addButtonText="Novo"
                    @add="addNewCustomer"
                />

    </div>
    <div class="self-center overflow-x-auto border w-full  text-sm rounded-lg border-gray-400" >
      


    <div class="inline-block min-w-full align-middle">
                    <div class="overflow-hidden ">
                        <table class="min-w-full divide-y divide-gray-300 bg-white ">
                            <thead class="bg-gray-100">
                                <tr>
                                    <th scope="col" class="py-3 px-6 text-sm  tracking-wider text-left uppercase ">
                                       Cliente
                                    </th>
                                    <th scope="col" class="py-3 px-6 text-sm  tracking-wider text-left uppercase ">
                                       Sigla
                                    </th>
                                    <th scope="col" class="py-3 px-6 text-sm  tracking-wider text-left uppercase ">
                                        Cód. Fabrica
                                    </th>
                                </tr> 
                            </thead>
                            <tbody class="bg-white divide-y divide-gray-200      text-gray-800"> 
                                <tr @click="editMode(customer)" class="hover:bg-gray-100 cursor-pointer" :key="customer.id" v-for="customer of customerList" >
                                    <td  class="py-3 px-6  text-sm  "> 
                                        {{ customer.name}}
                                    </td>
                                    <td class="py-3 px-6  text-sm     ">
                                        {{ customer.prefix}}
                                    </td>
                                    <td class="py-3 px-6 text-sm capitalize  whitespace-nowrap ">{{ customer.factoryCode}}</td>
                  
                                </tr>
                            </tbody>
                        </table>
                    </div>
    </div>
  
    </div>  



    <div v-if="showAddModal"  class="bg-white  fixed z-50 h-screen top-0 right-0 w-full lg:w-1/4 border-l border-gray-400 leading-6 font-euclid   ">
            <div class="flex flex-col h-full w-full overflow-y-auto ">
                <div class="flex justify-between border-b border-1 py-4 px-6  font-semibold text-gray-700 mb-2">
                    <span class="">{{ editView ? 'Editar Cliente' : 'Novo Cliente'}}</span>
                    <span class="pl-4">
                        <i @click="showAddModal = !showAddModal" class="fas fa-times cursor-pointer transition duration-500 ease-in-out hover:border-red-600 hover:text-red-600"></i>
                    </span>
                </div>

                <div class="flex flex-col p flex-grow  w-full p-4" >
                    <FormulateForm name="customerForm" class="flex flex-col p flex-grow  w-full" @submit="onSubmit">
                        <FormulateInput
                        type="text"

                        name="name"
                        label="Nome"
                        label-class="mt-4 block uppercase text-gray-700 text-xs font-bold "
                        input-class="w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none"
                        :validation-messages="{
                                required: 'Campo obrigatório',
                            }"
                        validation="^required"
                        error-class="text-red-500 text-xs italic border-red-500"
                        input-hasErrors-class="appearance-none border border-red-500"
                        :v-model="activeCustomer.name"
                        :value="activeCustomer.name"
                        />
                        <FormulateInput
                        type="text"

                        name="prefix"
                        label="Abreviação"
                        label-class="mt-4 block uppercase text-gray-700 text-xs font-bold "
                        input-class="w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none"
                        :validation-messages="{
                            required: 'Campo obrigatório',
                        }"
                        :v-model="activeCustomer.prefix"
                        :value="activeCustomer.prefix"
                        validation="^required"
                        error-class="text-red-500 text-xs italic border-red-500"
                        input-hasErrors-class="appearance-none border border-red-500"
                        />

                        <FormulateInput
                        type="text"

                        name="factoryCode"
                        label="Cód. Fabrica"
                        :v-model="activeCustomer.factoryCode"
                        :value="activeCustomer.factoryCode"
                        label-class="mt-4 block uppercase text-gray-700 text-xs font-bold "
                        input-class="w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none"
                        />

                        <label class="block uppercase text-gray-700 text-xs font-bold  mt-4">
                            Responsável
                        </label>

                        <v-select
                            class="style-chooser"
                            placeholder="digite o nome do responsável"
                            :options="managerList"
                            :filterable="false"
                            :value="activeCustomer.manager?.name"
x                            label="email"
                            id="email"
                            name="email"
                            @search="listAllManagers"
                            @input="setOwner"
                        >
                            <template slot="no-options">
                                Informe o NOME do responsável
                            </template>
                            <template slot="option" slot-scope="option">
                                <div class="">
                                {{ option.name }}
                                </div>
                            </template>
                        </v-select>


                    <FormulateInput
                        type="submit"
                        label="Salvar"
                        input-class="border w-20 text-center mt-4 w-full self-end border-blue-600 text-blue-600 px-2 py-1 rounded-md cursor-pointer transition duration-500 ease-in-out hover:bg-blue-100 "
                    />

                    </FormulateForm>
                
                
                </div>
                 
                <div id="footer" class="sticky z-50 bottom-0 w-full bg-white  border-t border-1 py-4 px-6 flex justify-end">
                    <span @click="showAddModal = !showAddModal" class="border px-2 py-1 rounded-md cursor-pointer transition duration-500 ease-in-out hover:border-red-600 hover:text-red-600">Fechar</span>
                </div>
            </div>
        

           
        </div>

        <div v-if="showAddModal" tabindex="2"  @click="showAddModal = false" class="mask absolute w-full bg-gray-500 z-40 opacity-50 h-screen inset-0 cursor-pointer"></div>
        
        
        
  </section>
</template>

<script lang="ts">
import Vue from 'vue'
import SearchInput from '@/presentation/components/inputs/input-search.vue'
import nProgress from 'nprogress';
import ListMenuOptions from '@/presentation/components/lists/list-menu-options.vue'
import { Toastr } from '@/presentation/helpers/toastr-configs';
import { Customer } from '@/domain/models/customers/customers'; 


export default Vue.extend({
    name: 'CustomerPage',
    props: {
        customersHttp: {
            required: true
        },
        usersHttp: {
            required: true
        }
    },
    components: {
        SearchInput,
        ListMenuOptions,
    },
    data: function() {
        return {
            loadingCustomers: true,
            customerList: [],
            customerSearchText: '',
            showAddModal: false,
            activeCustomer: {
                name: '',
                prefix: '',
                factoryCode: '',
                manager: {
                    id: null,
                    name: '',
                },
            },
            editView: false,
            managerList: [],
        
        };
    },  
    async created() {
        await this.listAllCustomers(this.searchText)
    },
    methods: {
        async listAllCustomers (searchText: string) {
            nProgress.start()
            this.loadingCustomers = true
            this.customerSearchText = searchText
            const result = await this.customersHttp.listAll({ searchText })
            this.customerList = result.data
            this.loadingCustomers = false
            nProgress.done()
        }, 
        async listAllManagers(searchText: string) {
            nProgress.start()
            const result = await this.usersHttp.listAllManagers(searchText)
            this.managerList = result.data
            nProgress.done()
        }, 
        async addNewCustomer() {
            this.showAddModal = true
            this.activeCustomer = {
                name: '',
                prefix: '',
                factoryCode: '',
                manager: {
                    id: null,
                    name: '',
                },
            }
            this.editView = false
        },
        async onSubmit({ name, prefix, factoryCode}: { name: string, prefix: string, factoryCode: string }) {
            try {
                nProgress.start()
                if(this.editView) {
                    await this.customersHttp.update(this.activeCustomer.id, { name, prefix, factoryCode, manager: this.activeCustomer.manager?.id })
                    Toastr.successBottomRight(this, 'Cliente atualizado com sucesso')
                    this.showAddModal = false
                    await this.listAllCustomers(this.searchText)
                    return
                } else {
                    await this.customersHttp.create({ name, prefix, factoryCode, manager: this.activeCustomer.manager?.id })
                    this.$formulate.reset('customerForm')
                    Toastr.successBottomRight(this, 'Cliente cadastrado com sucesso')
                    await this.listAllCustomers(this.searchText)
                    return
                }
            } catch (error) {
                Toastr.errorBottomRight(this, error.errorMessage)
                return
            } finally {
                nProgress.done()
            }
        },
        async editMode(customer: Customer) {
            this.activeCustomer = customer
            this.showAddModal = true
            this.editView = true
        },
        async setOwner(e: any ) {
            if(!e) {
                this.activeCustomer.manager = {
                    id: null,
                    name: '',
                }
                return
            }
            this.activeCustomer.manager = e 
        }

    }
})
</script>