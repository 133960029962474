import { IHttpResponse } from "@/domain/models/api-responses";
import { getRequest } from "../helper/http-request-decorators";
import { IHttpClient } from "../protocols/httpClient";
import { ConfigModel } from "@/domain/models/configs/config-model";
import { IConfig } from "@/domain/config/config";

export default class ConfigService implements IConfig {
    constructor (
        private readonly url: string,
        private readonly token: string,
        private readonly http: IHttpClient<ConfigModel>
      ) {
    
      }

    @getRequest
    async findByName(name: string): Promise<IHttpResponse<ConfigModel>> {
        return await this.http.request({
            method: 'get',
            url: `${this.url}/${name}`,
            headers: { Authorization: `Bearer ${this.token}` },
          })
    }


}
