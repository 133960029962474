import { CredentialParams, ILogin } from '@/domain/auth/login'
import { IHttpResponse } from '@/domain/models/api-responses'
import { LoginAuthenticationModel } from '@/domain/models/login-authentication-model'
import { IHttpClient } from '../protocols/httpClient'

export class Login implements ILogin {
  constructor (
    private readonly http: IHttpClient<LoginAuthenticationModel>,
    private readonly url: string
  ) {}

  async auth (userCredentials: CredentialParams): Promise<IHttpResponse<LoginAuthenticationModel>> {
    const response = await this.http.request({
      body: userCredentials,
      method: 'post',
      url: this.url
    })

    const { error, errorMessage, statusCode } = response

    if (error) {
      throw { errorMessage, statusCode }
    }

    const { data } = response

    return {
      data,
      statusCode
    }
  }
}
