import { IDrawin } from '@/domain/drawins/drawins'
import { IHttpResponse } from '@/domain/models/api-responses'
import {
  DrawinCollectionModel,
  DrawinModel
} from '@/domain/models/drawins/drawin-model'
import { IHttpClient } from '@/data/protocols/httpClient'
import {
  getFileRequest,
  getRequest,
  postRequest,
  updateRequest
} from '@/data/helper/http-request-decorators'

export class Drawin implements IDrawin {
  constructor(
    private readonly url: string,
    private readonly token: string,
    private readonly http: IHttpClient<DrawinModel>
  ) {}

  @postRequest
  async newDrawin(params: {
    drawin: DrawinModel
    ignoreCodeGeneration?: boolean
  }): Promise<IHttpResponse<DrawinModel>> {
    return await this.http.request({
      method: 'post',
      url: this.url,
      body: params.drawin,
      params: {
        ignoreAutomaticCodeGeneration: params.ignoreCodeGeneration
      },
      headers: {
        Authorization: `Bearer ${this.token}`,
        'Content-type': 'multipart/form-data'
      }
    })
  }

  @getRequest
  async listDrawins(
    paramString: string,
    me?: boolean
  ): Promise<IHttpResponse<DrawinModel>> {
    const searchParams = new URLSearchParams(paramString)
    return await this.http.request({
      method: 'get',
      url: this.url,
      headers: { Authorization: `Bearer ${this.token}` },
      params: searchParams
    })
  }

  @getRequest
  async findDrawinById(id: string): Promise<IHttpResponse<DrawinModel>> {
    return await this.http.request({
      url: `${this.url}/${id}`,
      method: 'get',
      headers: { Authorization: `Bearer ${this.token}` }
    })
  }

  @getRequest
  async findCollectionById(id: string): Promise<IHttpResponse<any>> {
    return await this.http.request({
      url: `${this.url}/${id}/collection`,
      method: 'get',
      headers: { Authorization: `Bearer ${this.token}` }
    })
  }

  @updateRequest
  async updateDrawin(
    id: string,
    drawin: DrawinModel,
    typeJson: boolean = false
  ): Promise<IHttpResponse<DrawinModel>> {
    return await this.http.request({
      method: 'put',
      url: `${this.url}/${id}`,
      body: drawin,
      headers: {
        Authorization: `Bearer ${this.token}`,
        'Content-type': typeJson ? 'application/json' : 'multipart/form-data'
      }
    })
  }

  @updateRequest
  async approveDeveloper(id: string): Promise<IHttpResponse<DrawinModel>> {
    return await this.http.request({
      method: 'put',
      url: `${this.url}/${id}/approveDeveloper`,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

  @updateRequest
  async cancel(id: string): Promise<IHttpResponse<DrawinModel>> {
    return await this.http.request({
      method: 'put',
      url: `${this.url}/${id}/cancel`,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

  @updateRequest
  async reactivate(id: string): Promise<IHttpResponse<DrawinModel>> {
    return await this.http.request({
      method: 'put',
      url: `${this.url}/${id}/reactivate`,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

  @updateRequest
  async markFreelancerPaid(ids: string[]): Promise<IHttpResponse<DrawinModel>> {
    return await this.http.request({
      method: 'put',
      url: `${this.url}/1/freelancerPaid`,
      body: ids,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

  @postRequest
  async sendFreelancerReport(
    drawinIdList: string[]
  ): Promise<IHttpResponse<DrawinModel>> {
    return await this.http.request({
      method: 'post',
      body: drawinIdList,
      url: `${this.url}/freelancerSendReport`,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

  @getFileRequest
  async recordFile(id: string): Promise<IHttpResponse<any>> {
    return await this.http.request({
      url: `${this.url}/${id}/recordFile`,
      method: 'get',
      headers: { Authorization: `Bearer ${this.token}` },
      responseType: 'blob'
    })
  }

  @getRequest
  async listPurchaseOrder(id: string): Promise<IHttpResponse<any>> {
    return await this.http.request({
      url: `${this.url}/${id}/purchaseOrder`,
      method: 'get',
      headers: { Authorization: `Bearer ${this.token}` }
    })
  }
}
