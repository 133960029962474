var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('div',{staticClass:"flex w-full mb-4 justify-between gap-2"},[_c('div',{staticClass:"flex gap-2 w-full text-sm"},[(_vm.Token.validRole('administrator', 'manager'))?_c('RouterLink',{attrs:{"to":"desenhos/cadastro"}},[_c('span',{staticClass:"flex gap-2 font-semibold bg-white border py-2 px-4 items-center justify-between rounded-md cursor-pointer hover:bg-gray-100"},[_c('span',[_vm._v("Novo ")]),_c('i',{staticClass:"fas fa-plus text-sm"})])]):_vm._e(),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.quickSearchText),expression:"quickSearchText"}],staticClass:"w-full border rounded pl-4 py-1 focus:border-2 focus:border-blue-500 focus:outline-none",attrs:{"type":"text","placeholder":"Pesquisa rápida (código, nome do desenho ou nome do cliente)","name":"","id":""},domProps:{"value":(_vm.quickSearchText)},on:{"input":[function($event){if($event.target.composing)return;_vm.quickSearchText=$event.target.value},function($event){return _vm.onQuickSearch($event.target.value)}]}}),_c('span',{staticClass:"flex gap-2 border bg-white py-2 px-4 items-center justify-between rounded-md cursor-pointer hover:bg-gray-100",class:{
          ' border-blue-500  text-blue-500': _vm.activeQueryFilters !== ''
        },on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[_c('span',[_vm._v("Filtros ")]),(_vm.activeQueryFilters === '')?_c('i',{staticClass:"fas fa-sliders-h"}):_c('i',{staticClass:"fas fa-times text-red-700 cursor-pointer",on:{"click":function($event){return _vm.onFilterDrawins('')}}})])],1),_c('div',{staticClass:"flex gap-2 text-sm"},[_c('div',{staticClass:"bg-gray-200 border text-sm text-gray-700 leading-none rounded-md inline-flex"},[_c('button',{staticClass:"inline-flex items-center transition-colors ease-in focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-r-md px-4 py-2",class:{
            'bg-white text-blue-500  rounded-md': _vm.drawinViewMode === 'list'
          },attrs:{"id":"list"},on:{"click":function($event){_vm.drawinViewMode = 'list'}}},[_c('svg',{staticClass:"fill-current w-4 h-4 mr-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('line',{attrs:{"x1":"8","y1":"6","x2":"21","y2":"6"}}),_c('line',{attrs:{"x1":"8","y1":"12","x2":"21","y2":"12"}}),_c('line',{attrs:{"x1":"8","y1":"18","x2":"21","y2":"18"}}),_c('line',{attrs:{"x1":"3","y1":"6","x2":"3.01","y2":"6"}}),_c('line',{attrs:{"x1":"3","y1":"12","x2":"3.01","y2":"12"}}),_c('line',{attrs:{"x1":"3","y1":"18","x2":"3.01","y2":"18"}})]),_c('span',[_vm._v("Lista")])]),_c('button',{staticClass:"inline-flex items-center transition-colors ease-in focus:outline-none hover:text-blue-400 focus:text-blue-400 rounded-l-md px-4 py-2 active",class:{
            'bg-white text-blue-600 rounded-md': _vm.drawinViewMode === 'grid'
          },attrs:{"id":"grid"},on:{"click":function($event){_vm.drawinViewMode = 'grid'}}},[_c('svg',{staticClass:"fill-current w-4 h-4 mr-2",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","stroke":"currentColor","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}},[_c('rect',{attrs:{"x":"3","y":"3","width":"7","height":"7"}}),_c('rect',{attrs:{"x":"14","y":"3","width":"7","height":"7"}}),_c('rect',{attrs:{"x":"14","y":"14","width":"7","height":"7"}}),_c('rect',{attrs:{"x":"3","y":"14","width":"7","height":"7"}})]),_c('span',[_vm._v("Grid")])])])])]),_c('KeepAlive',[(_vm.showFilters)?_c('DrawinFilterSideBar',{on:{"opened":function($event){_vm.showFilters = $event},"onFilter":_vm.onFilterDrawins,"onGenerateDrawinReport":_vm.generateDrawinReport}}):_vm._e()],1),(_vm.drawinViewMode === 'grid')?_c('DrawinGridView',{attrs:{"drawinList":_vm.drawinList},on:{"onGenerateRecordFile":_vm.generateRecordFile,"onCancelDrawin":_vm.cancelDrawin,"onReactivateDrawin":_vm.reactiveDrawin,"onDeveloperApproved":_vm.approveDeveloper,"onLoadMoreDrawins":_vm.nextDrawinPage}}):_vm._e(),(_vm.drawinViewMode === 'list')?_c('DrawinListView',{attrs:{"drawinList":_vm.drawinList},on:{"onGenerateRecordFile":_vm.generateRecordFile,"onCancelDrawin":_vm.cancelDrawin,"onReactivateDrawin":_vm.reactiveDrawin,"onDeveloperApproved":_vm.approveDeveloper,"onLoadMoreDrawins":_vm.nextDrawinPage}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }