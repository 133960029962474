<template>
  <section>
    <!-- <span class="text-xs text-gray-600 uppercase lg:hidden"> {{ step.name }}</span> -->
      <span class="rounded-full  text-white bg-gray-600 px-2 text-xs font-bold mb-1 lg:mb-0" :class="{
        'bg-orange-500': step.status === 'Em andamento',
        'bg-blue-600': step.status === 'Aguardando cliente',
        'bg-green-600': step.status === 'Finalizado'
      }">
        {{ step.status || 'Aguardando'}}
      </span>
  </section>
</template>

<script lang="ts">
import { DrawinStep } from '@/domain/models/drawins/drawin-model'
import Vue from 'vue'
export default Vue.extend({
  name: 'BadgeDrawinStepsStatus',
  props: {
    step: {
      required: true,
      type: Object as () => DrawinStep
    }
  }
})
</script>