import { ISignUp } from '@/domain/auth/signup'
import { SignUpModel } from '@/domain/models/user-signup-model'
import { IHttpResponse } from '@/domain/models/api-responses'
import { IHttpClient } from '../protocols/httpClient'

export class SignUp implements ISignUp {
  constructor (
    private readonly http: IHttpClient<SignUpModel>,
    private readonly url: string
  ) { }

  async add (newUser: SignUpModel): Promise<IHttpResponse<any>> {
    const response = await this.http.request({
      method: 'post',
      body: newUser,
      url: this.url
    })

    const { errorMessage, statusCode } = response

    if (response.error) {
      throw { errorMessage, statusCode }
    }

    const { data } = response

    return {
      data,
      statusCode
    }
  }
}
