<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'DropFiles',
  data: function () {
    return {
      fileList: []
    }
  },
  props: {
    textButton: {
      type: String,
      default: 'Adicionar arquivo'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String
    },
    label: {
      type: String
    },
    dropText: {
      type: String,
      required: true
    },
    description: {
      type: String
    },
    image: {
      type: Array,
      default: null
    }
  },
  created () {
    if (this.image) {
      this.fileList.push(...this.image)
    }
  },
  methods: {
    emmiter () {
      this.$emit('fileChange', this.fileList)
    },
    insertFile (fileList: FileList) {
      if (!this.multiple) {
        if (!this.verifyAcceptType(fileList[0].type)) {
          return
        }
        if (this.fileList.length) {
          this.fileList = [fileList[0]]
          this.emmiter()
          return
        }
        this.fileList.push(fileList[0])
        this.emmiter()
        return
      }
      for (let i = 0; i < fileList.length; i++) {
        if (!this.verifyAcceptType(fileList[i].type)) {
          delete fileList[i]
        }
      }
      this.fileList.push(...fileList)
      this.emmiter()
    },
    removeFile (index: number) {
      this.fileList.splice(index, 1)
    },
    isImage (file: File | string) {
      if (this.isFileParam(file)) {
        return file
      }
      const parserFile = file as File
      return parserFile.type.startsWith('image')
    },
    verifyAcceptType (fileType: string) {
      const type = this.accept.split('/')[0]
      return fileType.startsWith(type)
    },
    isFileParam (file: File | string) {
      if (!this.image) {
        return false
      }
      if (typeof file === 'string' && this.image.includes(file)) {
        return true
      }
      return false
    },
    generateFilePreview (file: File) {
      if (this.isFileParam(file)) {
        return file
      }
      return URL.createObjectURL(file)
    }
  }
})
</script>

<template>
  <div class="mb-4 flex flex-col">
  <span v-if="label" class="block uppercase text-gray-700 text-xs font-bold "> {{ label }} </span>
   <ul v-if="fileList.length" class="flex flex-col " @drop.prevent="insertFile($event.dataTransfer.files)" @dragover.prevent>
      <li v-for="(file, index) of fileList" :key="index" class="flex flex-row mb-1" @click="$refs.fileInput.click()" >
        <div class="select-none cursor-pointer rounded-md flex flex-1 items-center p-4 border ">
          <div class="flex flex-col rounded-md  justify-center items-center mr-4">
            <div v-if="isImage(file)">
              <img :src="generateFilePreview(file)" class="w-10 h-10 ">
            </div>
            <span v-else> <i class="fas fa-folder-open"></i> </span>
          </div>
          <div class="flex-1 pl-1 mr-16">
            <div class="font-medium truncate w-40 " :title="file.name">{{ typeof file === 'string' ? file.split('/')[file.split('/').length - 1] :file.name }} </div>
          </div>
          <div @click.stop="removeFile(index)"> <i class="fas fa-trash text-gray-500 cursor-pointer hover:text-red-600" ></i> </div>
        </div>
      </li>
    </ul>
    <span v-if="fileList.length" class="block text-gray-700 text-xs "> {{ description }} </span>
    <div v-if="!fileList.length">
      <div
        @click="$refs.fileInput.click()"
        @drop.prevent="insertFile($event.dataTransfer.files)"
        @dragover.prevent
        class="flex justify-center items-center border border-gray-500 rounded-md border-dotted h-20  w-full cursor-pointer hover:bg-gray-200 text-gray-500">
        <i class="far fa-file-alt mx-2 "></i> {{ dropText }}
      </div>
    </div>
    <input  type="file" ref="fileInput" hidden @change="insertFile($event.target.files)" :accept="accept">
    <button v-if="fileList.length && multiple"  @click="$refs.fileInput.click()" class="rounded-md px-3 p-1 bg-gray-200 self-end focus:outline-none hover:bg-gray-300"> {{ textButton }} </button>
  </div>
</template>
