import CustomerService from "@/data/customers/customer"
import { IHttpClient } from "@/data/protocols/httpClient"
import { ICustomer } from "@/domain/customers/customer"
import { Customer } from "@/domain/models/customers/customers"
import { LucinRoute } from "@/main/configs/routes/routes-paths"
import { Token } from "@/presentation/helpers/token-local-storage"

export const customersHttpFactory = (http: IHttpClient<Customer>): ICustomer=> {
    return new CustomerService(LucinRoute.customers, Token.getToken(), http)
}
  