<template>
  <FormulateForm name="drawin" enctype="multipart/formdata" @submit="onSubmit">
    <section class="flex flex-col justify-center items-center mb-4">
      <div class="w-full md:w-1/2 bg-white shadow p-5 rounded flex flex-col">
        <BackHeader title="DESENVOLVIMENTO" />
        <DropFiles
          :image="
            activeDrawinInternal.imageUrl
              ? [activeDrawinInternal.imageUrl]
              : null
          "
          @fileChange="onFileChange"
          label="Insira a imagem do desenho"
          dropText="Clique aqui ou arraste uma imagem para adiciona-la ao desenho"
          accept="image/png,jpg,jpeg"
          description=""
        />
        <SampleInput
          :value="activeDrawinInternal.name"
          label="Nome do desenho"
          placeholder="informe o nome do desenho"
          name="name"
          id="name"
        />

        <label class="block uppercase text-gray-700 text-xs font-bold mt-4">
          tipo
        </label>
        <v-select
          @open="onOpenInputTypeSelect"
          placeholder="informe o tipo"
          class="style-chooser"
          :clearable="false"
          :input-error="!activeDrawinInternal.type && inputSelectTypeTouched"
          :options="[
            { header: 'Estampa', value: '' },
            { value: 'ESTAMPA CONVENCIONAL' },
            { value: 'ESTAMPA DIGITAL' },
            { value: 'ESTAMPA TRANSFER' },

            { header: 'Bordado', value: '' },
            { value: 'BORDADO' },

            { header: 'Fio tinto', value: '' },
            { value: 'LISTRA' },
            { value: 'XADREZ' },

            { header: 'Liso', value: '' },

            { value: 'LISO (DESENVOLVIMENTO)' },
            { value: 'LISO (L/D)' },

            { header: 'Outros', value: '' },
            { value: 'APLICAÇÕES' },
            { value: 'DEVORÊ' },
            { value: 'GUIPURE' },
            { value: 'JACQUARD' },
            { value: 'LAISE' },
            { value: 'MAQUINETADO' },
            { value: 'PAETÊ' },
            { value: 'RENDA' },
            { value: 'URAGIRI' }
          ]"
          label="value"
          :selectable="option => !option.hasOwnProperty('header')"
          v-model="activeDrawinInternal.type"
          @input="setType"
        >
          <template #option="{ header, value }">
            <span v-if="header" class="font-bold uppercase">{{ header }}</span>
            <span v-else class="">{{ value }}</span>
          </template>
        </v-select>
        <span
          v-if="!activeDrawinInternal.type && inputSelectTypeTouched"
          class="text-red-500 text-xs italic border-red-500"
        >
          Campo obrigátorio
        </span>
        <label class="block uppercase text-gray-700 text-xs font-bold mt-4">
          Cliente
        </label>
        <v-select
          :input-error="!customerId && inputSelectCustomerTouched"
          @open="onOpenInputCustomerSelect"
          class="style-chooser"
          :clearable="false"
          placeholder="Associe o desenho a um cliente cadastrado"
          :options="listCustomers"
          :filterable="false"
          :value="activeDrawinInternal.client"
          v-model="activeDrawinInternal.client"
          @input="setAccountId"
          label="name"
          id="client"
          name="client"
          @search="listAllCustomers"
        >
          <template slot="no-options">
            Informe o NOME do cliente, se o cadastro existir, buscaremos pra
            você :)
          </template>
          <template slot="option" slot-scope="option">
            <div class="">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <span
          v-if="!customerId && inputSelectCustomerTouched"
          class="text-red-500 text-xs italic border-red-500"
        >
          Campo obrigátorio
        </span>

        <FormulateInput
          :disabled="
            activeDrawinInternal.type !== 'LISO (L/D)' &&
              activeDrawinInternal.type !== 'LISOS'
          "
          :value="copyLucinRef"
          v-model="copyLucinRef"
          id="lucinRef"
          name="lucinRef"
          label="Ref. Lucin"
          label-class="mt-4 block uppercase text-gray-700 text-xs font-bold truncate lg:break-words"
          :validation-messages="{
            required: 'Campo obrigatório'
          }"
          placeholder="informe a referência"
          input-class="w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none"
          error-class="text-red-500 text-xs italic border-red-500"
          input-hasErrors-class="appearance-none border border-red-500"
        />

        <FormulateInput
          v-if="
            mode == 'collection' || (mode == 'edit' && activeDrawin.isChild)
          "
          :disabled="true"
          :value="childLucinRef"
          v-model="childLucinRef"
          id="childLucinRef"
          name="childLucinRef"
          label="Ref. Acervo"
          label-class="mt-4 block uppercase text-gray-700 text-xs font-bold truncate lg:break-words"
          validation="required"
          :validation-messages="{
            required: 'Campo obrigatório'
          }"
          placeholder="informe a referência"
          input-class="w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none"
          error-class="text-red-500 text-xs italic border-red-500"
          input-hasErrors-class="appearance-none border border-red-500"
        />

        <SampleInput
          :value="activeDrawinInternal.factory"
          label="Fabrica"
          placeholder="informe o nome da fabrica"
          name="factory"
          id="factory"
        />
        <SampleInput
          :value="activeDrawinInternal.stylist"
          label="Estilista"
          placeholder="informe o nome do estilista"
          name="stylist"
          id="stylist"
        />
        <SampleInput
          :value="activeDrawinInternal.styleCollection"
          label="Coleção"
          placeholder="informe a coleção"
          name="styleCollection"
          id="styleCollection"
        />
        <SampleInput
          :value="activeDrawinInternal.observation"
          label="Observação"
          placeholder="insira uma observação"
          name="observation"
          id="observation"
        />
      </div>
    </section>
    <section class="flex flex-col justify-center items-center mb-4">
      <div class="w-full md:w-1/2 bg-white shadow p-5 rounded flex flex-col">
        <span class="font-mont uppercase font-black text-center text-xl mb-">
          BASES
        </span>
        <div class="container flex mx-auto w-full items-center justify-center">
          <div class="flex flex-col w-full">
            <BaseCardSelection
              @removeBase="onRemoveBase"
              :selectedBases="selectedBases"
              v-if="selectedBases && selectedBases.length > 0"
            />
            <button
              @click="showModal"
              type="button"
              class="rounded-full w-3/4 self-center font-semibold border px-4 py-2 shadow hover:bg-gray-200 focus:outline-none"
            >
              Adicionar uma nova base
            </button>
          </div>
        </div>
        <modal
          name="my-first-modal"
          :width="800"
          :adaptive="true"
          :scrollable="true"
          height="auto"
          @closed="onSearchBase(null)"
        >
          <section class="flex flex-col p-4">
            <section class="relative py-1 m-2">
              <input
                ref="baseSearch"
                autofocus
                @input="debounceInput($event.target.value)"
                type="text"
                placeholder="Pesquisar base"
                class="w-full  px-3 py-2 border border-gray-400 border-box rounded leading-none animate__animated animate__fadeIn focus:border-blue-500 outline-none"
              />
              <button
                class="absolute top-0 right-0 mt-2 mr-4 cursor-default focus:outline-none"
              >
                <i class="fas fa-search text-gray-600"></i>
              </button>
            </section>
            <BaseCardSelection
              :baseSearchText="baseSearchText"
              @addBase="onAddBase"
            />
          </section>
        </modal>
      </div>
    </section>
    <section class="flex flex-col justify-center items-center mb-4">
      <div class="w-full md:w-1/2 bg-white shadow p-5 rounded flex flex-col">
        <span class="font-mont uppercase font-black text-center text-xl mb-">
          Variantes
        </span>
        <label class="block uppercase text-gray-700 text-xs font-bold mt-4">
          Variante
        </label>
        <div class="flex justify-items-center w-full">
          <v-select
            class="w-full style-chooser"
            placeholder="escolha uma variante"
            :options="colors"
            :filterable="false"
            v-model="activeColor"
            @input="onAddColor"
            @search="listAllColors"
            label="color"
            id="colors"
            name="colors"
          >
            <template slot="no-options">
              Informe a cor, se o cadastro existir, buscaremos pra você :)
            </template>
            <template slot="option" slot-scope="option">
              <div class="">{{ option.personalId }} - {{ option.color }}</div>
            </template>
          </v-select>
        </div>

        <div class="w-full mt-4 flex flex-col " :v-if="colorList.length">
          <span
            class="p-2 w-full text-sm font-semibold flex justify-between items-center mt-2 bg-gray-100 uppercase rounded-lg"
            v-for="color of colorList"
            :key="color.personalId"
          >
            <label class="self-start">
              {{ color.personalId }} - {{ color.color }}
            </label>
            <i
              class="fas fa-trash transition duration-500  text-gray-700 ease-in-out transform cursor-pointer hover:text-red-700"
              @click="onRemoveColor(color.color)"
            />
          </span>
        </div>
      </div>
    </section>
    <section class="flex flex-col justify-center items-center mb-4">
      <div class="w-full md:w-1/2 bg-white shadow p-5 rounded flex flex-col">
        <span class="font-mont uppercase font-black text-center text-xl mb-">
          DATAS
        </span>
        <SampleInput
          :value="
            this.mode === 'create'
              ? new Date().toISOString().split('T')[0]
              : activeDrawinInternal.registerDate.split('T')[0]
          "
          type="date"
          label="Data cadastro*"
          validation="required"
          placeholder="informe a data"
          name="registerDate"
          id="registerDate"
        />
        <SampleInput
          :value="
            activeDrawinInternal.productionDate
              ? activeDrawinInternal.productionDate.split('T')[0]
              : null
          "
          type="date"
          label="Data Entrega produção"
          placeholder="informe a data"
          name="productionDate"
          id="productionDate"
        />
        <SampleInput
          :value="
            activeDrawinInternal.factoryMaxDate
              ? activeDrawinInternal.factoryMaxDate.split('T')[0]
              : null
          "
          type="date"
          :label="
            activeDrawinInternal.type !== 'LISO' &&
            activeDrawinInternal.type !== 'LISO (L/D)'
              ? 'Data máx fabrica*'
              : 'Data máx fabrica'
          "
          :validation="
            activeDrawinInternal.type !== 'LISO' &&
            activeDrawinInternal.type !== 'LISO (L/D)'
              ? 'required'
              : ''
          "
          placeholder="informe a data"
          name="factoryMaxDate"
          id="factoryMaxDate"
        />
      </div>
    </section>
    <section class="flex flex-col justify-center items-center mb-4">
      <div class="w-full md:w-1/2 bg-white shadow p-5 rounded flex flex-col">
        <span class="font-mont uppercase font-black text-center text-xl mb-">
          Origem
        </span>
        <SampleSelectInput
          :value="activeDrawinInternal.studio"
          name="studio"
          :options="{
            DESENVOLVIMENTO: 'DESENVOLVIMENTO',
            ACERVO: 'ACERVO',
            FABRICA: 'FÁBRICA',
            CLIENTE: 'CLIENTE',
            LIVRO: 'LIVRO'
          }"
          placeholder="informe a origem"
          validation="required"
          label="ORIGEM"
          id="studio"
        />
        <SampleInput
          :value="activeDrawinInternal.studioRef"
          label="Referência"
          placeholder="informe a referência"
          name="studioRef"
          id="studioRef"
        />
        <SampleSelectInput
          :value="activeDrawinInternal.studioCurrency"
          name="studioCurrency"
          :options="{ R$: 'R$', US$: 'US$', '¥': '¥', '€': '€' }"
          placeholder="informe a moeda"
          label="MOEDA"
        />
        <SampleInput
          :value="activeDrawinInternal.studioPrice"
          label="Preço"
          validation="number"
          step="0.01"
          min="0"
          placeholder="informe o preço"
          type="number"
          name="studioPrice"
          id="studioPrice"
        />
        <div class="flex flex-col mt-2 lg:flex-row justify-between">
          <SampleCheckbox
            @chkToggle="onCheckboxToggle"
            :value="checkbox.studioPaid"
            label="Pago pela lucin"
            name="studioPaid"
            id="studioPaid"
          />
          <SampleCheckbox
            @chkToggle="onCheckboxToggle"
            :value="checkbox.invoiceDelivered"
            label="Invoice entregue"
            name="invoiceDelivered"
            id="invoiceDelivered"
          />
          <SampleCheckbox
            @chkToggle="onCheckboxToggle"
            :value="checkbox.paymentLink"
            label="Link pagamento"
            name="paymentLink"
            id="paymentLink"
          />
        </div>
      </div>
    </section>
    <section class="flex flex-col justify-center  items-center mb-4">
      <div class="w-full md:w-1/2 rounded flex bottom-0  flex-col">
        <SampleSendFormButton
          v-if="canEdit"
          :text="
            this.mode === 'edit'
              ? 'Atualizar'
              : `Cadastrar${this.mode === 'collection' ? ' Acervo' : ''}`
          "
        />
        <button
          v-if="canEdit && this.mode === 'edit'"
          type="button"
          @click="onCancel"
          class="bg-red-500 mt-2 text-center uppercase w-full rounded text-white py-2 px-2 focus:outline-none focus:shadow-outline hover:bg-red-700"
        >
          {{
            activeDrawinInternal.canceled
              ? 'Reativar desenho'
              : 'Cancelar desenho'
          }}
        </button>
        <LinkFormButton
          to="/portal/estilo/desenhos"
          class="text-red-600 text-lg w-full block mt-2 text-center"
          text="Voltar"
        />
      </div>
    </section>

    <section v-if="!wasInBottom">
      <button
        title="Cadastrar/salvar desenho"
        class="h-20 rounded-full right-0 px-1 fixed bottom-0 focus:outline-none  bg-blue-500 hover:bg-blue-700 w-20 mr-20 mb-10 text-white text-3xl"
      >
        <i class="fas fa-save"></i>
      </button>
    </section>
  </FormulateForm>
</template>

<script lang="ts">
import Vue from 'vue'
import SampleInput from '@/presentation/components/forms/sample-input.vue'
import DropFiles from '@/presentation/components/forms/drop-files.vue'
import BackHeader from '@/presentation/components/utils/back-header.vue'
import SampleInputSearch from '@/presentation/components/inputs/input-sample-search.vue'
import SampleSelectInput from '@/presentation/components/forms/sample-select-input.vue'
import BaseCardSelection from '@/presentation/components/cards/bases-card-selection.vue'
import SampleSendFormButton from '@/presentation/components/forms/send-form-button.vue'
import LinkFormButton from '@/presentation/components/buttons/link-router-button.vue'
import SampleCheckbox from '@/presentation/components/forms/sample-checkbox.vue'
import InputAutoCompleteSelect from '@/presentation/components/forms/select-autocomplete.vue'
import NProgress from 'nprogress'
import { Token } from '@/presentation/helpers/token-local-storage'

import { BaseModel } from '../../../../../domain/models/bases/base-model'
import { mapActions } from 'vuex'
import { Toastr } from '@/presentation/helpers/toastr-configs'
import { ICustomersAccount } from '@/domain/users/customers'
import { IColorsService } from '@/domain/colors/colors'

import { Customer } from '@/domain/models/customers/customers'
import { IConfig } from '@/domain/config/config'

export default Vue.extend({
  components: {
    SampleInput,
    DropFiles,
    BackHeader,
    SampleInputSearch,
    BaseCardSelection,
    SampleSelectInput,
    SampleSendFormButton,
    LinkFormButton,
    SampleCheckbox,
    InputAutoCompleteSelect
  },
  props: {
    activeDrawin: {
      default: (): Object => {
        return {}
      },
      type: Object
    },
    customersHttp: { type: Object as () => ICustomersAccount },
    colorsHttp: { type: Object as () => IColorsService },
    configsHttp: { type: Object as () => IConfig },
    mode: {
      type: String,
      default: 'create'
    }
  },
  data() {
    return {
      activeDrawinInternal: null,
      selectedFile: null,
      selectedBases: [],
      baseSearchText: null,
      checkbox: {
        studioPaid: this.activeDrawin.studioPaid,
        invoiceDelivered: this.activeDrawin.invoiceDelivered,
        paymentLink: this.activeDrawin.paymentLink
      },
      files: null,
      listCustomers: [],
      customerDebounce: null,
      colorsDebounce: null,
      copyLucinRef: this.activeDrawin.lucinRef,
      activeCodeCount: 0,
      childLucinRef: '',
      customerId: null,
      preRegister: false,
      activeColor: { color: '', pantone: '', personalId: '' },
      colorList: [],
      colors: [],
      wasInBottom: false,
      inputSelectTypeTouched: false,
      inputSelectCustomerTouched: false,
      basesTimeout: null
    }
  },
  mounted() {
    document
      .querySelector('.calcHeight')
      .addEventListener('scroll', this.onScroll)
  },
  beforeDestroy() {
    document
      .querySelector('.calcHeight')
      .removeEventListener('scroll', this.onScroll)
  },
  created() {
    this.activeDrawinInternal = this.activeDrawin
    this.colorList = this.activeDrawin?.colors || []
    if (this.mode === 'edit') {
      this.selectedBases = this.activeDrawin.basesId
      this.customerId = this.activeDrawin.customerId
      this.childLucinRef = this.activeDrawinInternal.childLucinRef
    }
    if (this.mode === 'collection') {
      this.activeDrawinInternal = {
        ...this.activeDrawinInternal,
        factory: '',
        stylist: '',
        styleCollection: '',
        observation: '',
        productionDate: null,
        factoryMaxDate: null,
        registerDate: new Date().toISOString().split('T')[0],
        studioRef: '',
        studio: '',
        studioCurrency: '',
        studioPrice: null,
        studioPaid: false,
        invoiceDelivered: false,
        paymentLink: false,
        type: '',
        client: '',
        lucinRef: this.copyLucinRef
      }
      this.copyLucinRef = ''
    }
    if (this.$route.query.lucinRef) {
      this.preRegister = true
      this.activeDrawinInternal.lucinRef = this.$route.query.lucinRef
      this.copyLucinRef = this.$route.query.lucinRef
      return
    }
    this.getLastCode()
  },
  methods: {
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 100 >= scrollHeight) {
        this.wasInBottom = true
      } else {
        this.wasInBottom = false
      }
    },
    onFileChange(event: any) {
      this.files = event
    },
    async setType(event: { value: string }) {
      if (event.value === 'LISO' || event.value === 'LISO (L/D)') {
        this.copyLucinRef = ''
      }

      if (!event?.value) {
        this.activeDrawin.type = ''
        return
      }

      this.activeDrawin.type = event.value
    },
    async listAllCustomers(
      searchText: string,
      loading: (value: boolean) => void
    ) {
      loading(true)
      if (!searchText) {
        clearTimeout(this.timeout)
        loading(false)
        return
      }

      clearTimeout(this.customerDebounce)
      this.customerDebounce = setTimeout(async () => {
        const { data } = await this.customersHttp.listAll({ searchText })
        this.listCustomers = data
        loading(false)
      }, 200)
    },
    async listAllColors(searchText: string, loading: (value: boolean) => void) {
      loading(true)
      if (!searchText) {
        clearTimeout(this.timeout)
        loading(false)
        return
      }

      clearTimeout(this.colorsDebounce)
      this.colorsDebounce = setTimeout(async () => {
        const { data } = await this.colorsHttp.listAll({ searchText })
        this.colors = data
        loading(false)
      }, 200)
    },
    showModal() {
      this.$modal.show('my-first-modal')
      setTimeout(
        () =>
          this.$nextTick(() => {
            const editButtonRef = this.$refs.baseSearch
            editButtonRef.focus()
          }),
        100
      )
    },
    onAddBase(base: BaseModel) {
      const index = this.selectedBases.findIndex(
        b => b.id === base.id || b.psID == base.psID
      )
      if (index >= 0) {
        return
      }
      this.selectedBases.push(base)
    },

    onAddColor(): void {
      if (this.activeColor?.color === '') {
        return
      }

      if (this.colorList.some(c => c.color === this.activeColor.color)) {
        return
      }

      this.colorList.push({
        color: this.activeColor.color,
        pantone: this.activeColor.pantone,
        personalId: this.activeColor.personalId
      })

      this.activeColor = {}
    },
    onRemoveColor(colorName: string) {
      if (this.colorList.length === 0) {
        return
      }

      const index = this.colorList.findIndex(c => c.color === colorName)
      if (index < 0) {
        return
      }

      this.colorList.splice(index, 1)
    },
    onRemoveBase(base: BaseModel) {
      const index = this.selectedBases.findIndex(b => b.id === base.id)
      this.selectedBases.splice(index, 1)
    },
    onSearchBase(text: string) {
      this.baseSearchText = text
    },
    setAccountId(event: Customer) {
      this.activeDrawin.client = event?.name || ''
      this.activeDrawin.customerId = event?.id || null
      this.customerId = event?.id || null

      if (
        this.activeDrawinInternal.type === 'LISO (L/D)' ||
        this.activeDrawinInternal.type === 'LISO' ||
        this.$route.query.lucinRef
      ) {
        return
      }

      if (event?.prefix) {
        const activeYear = new Date()
          .getFullYear()
          .toString()
          .slice(2)
        if (this.mode === 'edit') {
          this.copyLucinRef = `${this.activeDrawin.lucinRef.slice(0, -3)}${
            event.prefix
          }`
        } else if (this.mode === 'collection') {
          let sanitizeLucinRef = this.activeDrawinInternal.lucinRef.slice(0, -3)
          if (sanitizeLucinRef.toLowerCase().startsWith('l')) {
            sanitizeLucinRef = sanitizeLucinRef.slice(1)
          }
          sanitizeLucinRef = sanitizeLucinRef.replace('-', '').replace('_', '')
          this.childLucinRef = `A${activeYear}${sanitizeLucinRef}${
            event.prefix
          }${this.getLetterByIndex(
            this.activeDrawinInternal.children?.length + 1
          )}`
          this.copyLucinRef = `${activeYear}${this.activeCodeCount + 1}${
            event.prefix
          }`
        } else {
          this.copyLucinRef = `${activeYear}${this.activeCodeCount + 1}${
            event.prefix
          }`
        }
      } else {
        this.copyLucinRef = ''
        this.activeDrawin.lucinRef = this.copyLucinRef
      }
    },
    makeDrawin(event: any, isCollection: boolean) {
      const files = this.files ? this.files[0] : null
      const basesID = this.selectedBases.filter(b => !b.psID).map(b => b.id)
      const mergeData = {
        ...event,
        ...this.checkbox,
        basesId: basesID.length > 0 ? basesID : undefined,
        psBasesId: this.selectedBases.filter(b => b.psID).map(b => b.psID),
        client: this.activeDrawin.client,
        lucinRef: this.copyLucinRef,
        type: this.activeDrawin.type,
        isChild: isCollection,
        parentId: this.activeDrawin.id,
        childLucinRef: this.childLucinRef,
        customerId: this.customerId,
        colors: this.colorList
      }
      const formData = new FormData()
      formData.append('data', JSON.stringify(mergeData))
      formData.append('files', files)
      return formData
    },
    getLetterByIndex(index) {
      if (index >= 0 && index <= 25) {
        return String.fromCharCode(65 + index)
      }
      return 'Z'
    },
    async onCreate(formData: FormData) {
      await this.createDrawin({
        drawin: formData,
        ignoreCodeGeneration: this.preRegister
      })
      this.getLastCode()
      this.$formulate.reset('drawin')
      this.selectedBases = []
      this.checkbox.studioPaid = false
      this.checkbox.invoiceDelivered = false
      this.checkbox.paymentLink = false
      this.activeDrawin.type = ''
      this.activeDrawin.client = ''
      this.$formulate.setValues('drawin', {
        registerDate: new Date().toISOString().split('T')[0]
      })
      this.preRegister = false
      this.activeDrawinInternal.type = ''
      this.customerId = null
      this.inputSelectCustomerTouched = false
      this.inputSelectTypeTouched = false
      Toastr.successBottomRight(this, 'Desenho cadastrado com sucesso')
    },
    async onUpdate(formData: FormData) {
      const updatedDrawin = await this.updateDrawin({
        id: this.activeDrawin.id,
        drawin: formData
      })
      Toastr.successBottomRight(this, 'Desenho atualizado com sucesso')
      return updatedDrawin
    },
    async onSubmit(event: any) {
      if (!this.activeDrawinInternal.type) {
        this.inputSelectTypeTouched = true
        Toastr.errorBottomRight(this, 'Campo tipo é obrigatório')
        return
      }
      if (!this.customerId) {
        this.inputSelectCustomerTouched = true
        Toastr.errorBottomRight(this, 'Campo cliente é obrigatório')
        return
      }
      NProgress.start()
      try {
        const drawin = this.makeDrawin(event, this.mode === 'collection')
        if (this.mode !== 'edit') {
          return await this.onCreate(drawin)
        }
        await this.onUpdate(drawin)
      } catch (error) {
        Toastr.errorBottomRight(this, error.errorMessage)
      }

      NProgress.done()
    },
    async onCancel() {
      try {
        const cancel = !!this.activeDrawin.canceled
        const { id } = this.activeDrawin
        if (cancel) {
          await this.reactivate(id)
        } else {
          await this.cancel(id)
        }
        this.activeDrawin.canceled = !cancel

        Toastr.successBottomRight(
          this,
          `Desenhos ${cancel ? 'reativado' : 'cancelado'} com sucesso!`
        )
      } catch (error) {}
    },
    async getLastCode() {
      try {
        const result = await this.configsHttp.findByName('createDrawin')
        if (result || result.length > 0) {
          this.activeCodeCount = result.data[0].count
        }
        return
      } catch (error) {
        Toastr.errorBottomRight(this, 'Erro ao gerar codigo automatico')
      }
    },
    onCheckboxToggle(event: {
      name: 'studioPaid' | 'paymentLink' | 'invoiceDelivered'
      checked: boolean
    }) {
      const { name, checked } = event
      this.checkbox[name] = checked
    },
    onOpenInputTypeSelect() {
      this.inputSelectTypeTouched = true
    },
    onOpenInputCustomerSelect() {
      this.inputSelectCustomerTouched = true
    },
    debounceInput(value: string): void {
      clearTimeout(this.basesTimeout)
      this.basesTimeout = setTimeout(() => {
        this.onSearchBase(value)
      }, 700)
    },
    ...mapActions({
      createDrawin: 'createDrawin',
      loadDrawins: 'loadDrawins',
      updateDrawin: 'updateDrawin',
      cancel: 'cancel',
      reactivate: 'reactivate'
    })
  },
  computed: {
    canEdit() {
      return Token.validRole('administrator', 'manager')
    },
    emptyActiveDrawin(): boolean {
      return !this.activeDrawin || Object.keys(this.activeDrawin).length === 0
    }
  }
})
</script>
<style lang="scss">
.style-chooser .vs__search::placeholder {
  color: rgba(74, 85, 104, 0.5);
}

.style-chooser[input-error='false'] .vs__dropdown-toggle {
  height: 2.5rem;
  border-color: #cbd5e0;
}

.style-chooser[input-error='true'] .vs__dropdown-toggle {
  height: 2.5rem;
  border-color: red;
}
</style>
