import { IHttpResponse } from "@/domain/models/api-responses";
import { getRequest, postRequest, updateRequest } from "../helper/http-request-decorators";
import { IHttpClient } from "../protocols/httpClient";
import { ICustomer } from "@/domain/customers/customer";
import { Customer } from "@/domain/models/customers/customers";

export default class CustomerService implements ICustomer {
    constructor (
        private readonly url: string,
        private readonly token: string,
        private readonly http: IHttpClient<Customer>
      ) {
    
      }

    @getRequest
    async listAll(params: { [key: string]: string; }): Promise<IHttpResponse<Customer>> {
        return await this.http.request({
            method: 'get',
            url: `${this.url}`,
            headers: { Authorization: `Bearer ${this.token}` },
            params: {
              ...params
            }
          })
    }

    @postRequest
    async create(newCustomer: Partial<Customer>): Promise<IHttpResponse<any>> {
        return await this.http.request({
            method: 'post',
            url: `${this.url}`,
            headers: { Authorization: `Bearer ${this.token}` },
            body: {
              ...newCustomer
            }
          })
    }


    @updateRequest
    async update(id: string, customer: Partial<Customer>): Promise<IHttpResponse<any>> {
        return await this.http.request({
            method: 'put',
            url: `${this.url}/${id}`,
            headers: { Authorization: `Bearer ${this.token}` },
            body: {
              ...customer
            }
          })
    }



}
