var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[(_vm.setting.field === 'designerId' && _vm.setting.isImage)?_c('span',[_c('img',{staticClass:"w-20 h-20 lg:h-12 lg:w-12 rounded-full",attrs:{"src":(_vm.step[_vm.setting.field] && _vm.step[_vm.setting.field].avatarUrl) ||
          require('../../assets/images/no_avatar.png')}})]):_vm._e(),(_vm.setting.field === 'designerId' && _vm.setting.key === 'name')?_c('div',{staticClass:"flex flex-col mt-4 lg:mt-0"},[_c('span',{staticClass:"text-xs text-gray-600 uppercase lg:hidden"},[_vm._v(" "+_vm._s(_vm.setting.name))]),_c('span',{staticClass:"mb-1 lg:mb-0 uppercase truncate",attrs:{"title":_vm.step[_vm.setting.field] ? _vm.step[_vm.setting.field].name : 'SEM DESENHISTA'}},[_vm._v(" "+_vm._s(_vm.step[_vm.setting.field] ? _vm.step[_vm.setting.field].name : 'SEM DESENHISTA')+" ")])]):_vm._e(),(_vm.setting.field === 'designerId' && _vm.setting.key === 'roles')?_c('div',{staticClass:"lg:truncate flex flex-col"},[_c('span',{staticClass:"text-xs text-gray-600 uppercase lg:hidden"},[_vm._v(" "+_vm._s(_vm.setting.name))]),(
        _vm.step[_vm.setting.field] !== null &&
          (_vm.step[_vm.setting.field].roles.includes('freelancer') ||
            _vm.step.freelancer)
      )?_c('i',{staticClass:"fas fa-check text-green-700 mb-1 lg:mb-0 text"}):_c('i',{staticClass:"fas fa-minus text-gray-400 mb-1 lg:mb-0 text"})]):_vm._e(),(_vm.setting.field === 'name')?_c('v-popover',{staticClass:"cursor-pointer hover:font-semibold",attrs:{"offset":"8","placement":"top-center"}},[_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"text-xs text-gray-600 uppercase lg:hidden"},[_vm._v(" "+_vm._s(_vm.setting.name))]),_c('span',{staticClass:"mb-1 lg:mb-0"},[_vm._v(" "+_vm._s(_vm.step[_vm.setting.field].split('/')[0])+" ("+_vm._s(_vm.step.count + 1)+"x) ")])]),(_vm.isManager)?_c('template',{slot:"popover"},[_c('EmojiDrawins',{attrs:{"itemRef":_vm.step.code,"designerFinish":_vm.step.designerFinish,"showCustomerApprovation":_vm.step.needCustomerApprovation && !_vm.step.customerApproves},on:{"manager-reprovation":function($event){return _vm.managerReprobateStep({
            id: _vm.activeDrawin.id,
            params: { stepCode: $event }
          })},"customer-approvation":function($event){return _vm.customerApprovesStep({
            id: _vm.activeDrawin.id,
            params: { stepCode: $event }
          })}}})],1):_vm._e()],2):_vm._e(),(_vm.setting.date)?_c('div',{staticClass:"flex flex-col"},[_c('span',{staticClass:"text-xs text-gray-600 uppercase lg:hidden"},[_vm._v(" "+_vm._s(_vm.setting.name))]),_c('span',{staticClass:"mb-1 lg:mb-0"},[_vm._v(" "+_vm._s(_vm.step[_vm.setting.field] ? _vm.format(_vm.getUTCDate(_vm.step[_vm.setting.field]), 'dd/MM/yy', { timezone: 'America/Brazil' }) : '-')+" ")])]):_vm._e(),(_vm.setting.field === 'needCustomerApprovation')?_c('div',{staticClass:"lg:truncate flex flex-col"},[_c('span',{staticClass:"text-xs text-gray-600 uppercase lg:hidden"},[_vm._v(" "+_vm._s(_vm.setting.name))]),_c('i',{staticClass:"fas text-gray-400 mb-1 lg:mb-0 text",class:{
        'fa-times text-red-600':
          _vm.step.needCustomerApprovation && !_vm.step.customerApproves,
        'fa-minus': !_vm.step.needCustomerApprovation,
        'fa-check text-green-700':
          _vm.step.needCustomerApprovation && _vm.step.customerApproves
      }})]):_vm._e(),(_vm.setting.field === 'status')?_c('div',{staticClass:"flex flex-col lg:inline lg:truncate"},[_c('span',{staticClass:"text-xs mt-2 text-gray-600 uppercase lg:hidden"},[_vm._v(" "+_vm._s(_vm.setting.name))]),_c('BadgeDrawinStepsStatus',{attrs:{"step":_vm.step}})],1):_vm._e(),(_vm.setting.field === 'actions')?_c('div',{staticClass:"flex flex-col lg:truncate"},[_c('span',{staticClass:"text-xs mt-2 text-gray-600 uppercase lg:hidden"},[_vm._v(" "+_vm._s(_vm.setting.name))]),(
        _vm.isDesigner && _vm.step.status === 'Em andamento' && _vm.step.designerId
          ? _vm.activeUser === _vm.step.designerId.email
          : false
      )?_c('i',{staticClass:"fas fa-stop text-red-600 cursor-pointer mb-1 lg:mb-0",on:{"click":function($event){return _vm.showModal(_vm.step.code)}}}):_vm._e(),(
        _vm.isDesigner &&
          _vm.step.status === 'Aguardando' &&
          (_vm.step.designerId ? _vm.activeUser === _vm.step.designerId.email : true)
      )?_c('i',{staticClass:"fas fa-play text-gray-600 cursor-pointer mb-1 lg:mb-0",on:{"click":function($event){return _vm.drawinStep(_vm.step.code, 'set')}}}):_vm._e(),(
        !_vm.isDesigner ||
          _vm.step.status === 'Aguardando cliente' ||
          _vm.step.status === 'Finalizado' ||
          (_vm.step.designerId ? _vm.activeUser !== _vm.step.designerId.email : false) ||
          _vm.step.status === 'Aguardando cliente' ||
          (_vm.step.status !== 'Em andamento' && _vm.step.status !== 'Aguardando')
      )?_c('i',{staticClass:"fas fa-lock text-gray-600 mb-1 lg:mb-0"}):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }