<script lang="ts">
import Vue from 'vue'
import DrawinInfoCard from '@/presentation/components/cards/drawin-info-card.vue'
import DrawinsStepsList from '@/presentation/components/lists/drawins-steps-list.vue'
import { Toastr } from '@/presentation/helpers/toastr-configs'

export default Vue.extend({
  name: 'DrawinStepsPage',
  components: {
    DrawinInfoCard,
    DrawinsStepsList,
  },
  props: {
    activeDrawin: Object,
    drawinHttp: {
      required: true
    }
  },
  data: function() {
      return {
          loadingCollection: true,
          collection: null,
          loadingPurchase: true,
          purchaseList: null
        };
  },  
async created() {

  this.drawinHttp.findCollectionById(this.activeDrawin.id).then((r) => {
    this.loadingCollection = false
    this.collection = r.data[0]
  }).catch((e) => {
  }).finally(() => {
    this.loadingCollection = false
  })

  this.drawinHttp.listPurchaseOrder(this.activeDrawin.id).then((r) => {
    this.loadingPurchase = false
    this.purchaseList = r.data
  }).catch((e) => {
  }).finally(() => {
    this.loadingCollection = false
  })

},
})
</script>

<template>
  <section class="flex flex-col lg:flex-row">
    <div class="w-full mb-2 lg:mb-0 lg:w-1/4  flex flex-col">
      <DrawinInfoCard
        class="lg:mt-10"
        :drawin="activeDrawin"
        :loadingCollection="loadingCollection"
        :collection="collection"
        :loadingPurchase="loadingPurchase"
        :purchaseList="purchaseList"
      />
    </div>
    <div class="w-full">
      <DrawinsStepsList />
    </div>
  </section>
</template>
