var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',{staticClass:"z-0 mt-8"},[_c('span',{staticClass:"w-full bg-white"},[(_vm.drawins.length)?_c('p',{staticClass:"mt-5 -mb-5 self-start py-3 font-semibold text-gray-800"},[_vm._v(" "+_vm._s(_vm.title))]):_vm._e()]),_c('div',{staticClass:"mt-5 flex flex-col justify-center mb-5 w-full rounded-md relative"},[(!_vm.loading &&  _vm.pageListDrawins !== 1)?_c('div',{staticClass:"absolute hidden z-10 lg:inline left-0 -ml-1 bg-white rounded-full shadow hover:bg-gray-300",on:{"click":function($event){return _vm.nextDrawingList(false)}}},[_vm._m(0)]):_vm._e(),(!_vm.loading && _vm.pageListDrawins < _vm.drawinsPagesLimit)?_c('div',{staticClass:"absolute hidden z-10 lg:inline right-0 -mr-1 bg-white opacity- rounded-full shadow hover:bg-gray-300",on:{"click":function($event){return _vm.nextDrawingList()}}},[_vm._m(1)]):_vm._e(),(!_vm.loading && !_vm.isFreela)?_c('div',{staticClass:"flex flex-col lg:flex-row"},[_vm._l((_vm.drawins),function(drawing){return _c('div',{key:drawing.id,staticClass:"flex flex-col relative hover:bg-gray-200 mb-2 w-full lg:w-1/6 mr-2 bg-white px-3 py-8 rounded-md border text-center"},[(!drawing.developerApproved)?_c('div',{staticClass:"flex flex-col self-end absolute top-0 mt-4 mr-2 bg-gray-400 text-white items-center justify-center text-center rounded-full w-10 h-10",class:{
          'bg-red-700':  !drawing.developerApproved && _vm.days(drawing.factoryMaxDate) <= 7,
          'bg-green-500':  drawing.developerApproved,
        }},[(_vm.days(drawing.factoryMaxDate) > 0)?_c('span',{staticClass:"text-lg font-semibold"},[_vm._v(_vm._s(_vm._f("calculateDays")(drawing.factoryMaxDate))+" ")]):_vm._e(),(_vm.days(drawing.factoryMaxDate) > 0)?_c('span',{staticClass:"-mt-2 text-xs"},[_vm._v(" "+_vm._s(_vm._f("calculateDaysText")(drawing.factoryMaxDate))+" ")]):_c('i',{staticClass:"fas fa-exclamation",attrs:{"title":"Prazo expirado"}})]):_vm._e(),_c('div',{staticClass:"mb-3 self-center"},[_c('img',{staticClass:"mt-4 w-40 h-40 object-cover rounded-full hover:rounded-none",attrs:{"width":"200","height":"200","src":drawing.imageUrl || require('../../assets/images/no_image.png'),"alt":""}})]),_c('span',{staticClass:"uppercase text-xl font-medium truncate text-gray-700"},[_vm._v(_vm._s(drawing.lucinRef || ' - '))]),_c('span',{staticClass:"font-medium mb-2 truncate text-gray-700"},[_vm._v(_vm._s(drawing.name || ' - '))]),_c('span',{staticClass:"mt-2 text-sm truncate text-blue-500 block mb-5 uppercase"},[_vm._v(" "+_vm._s(drawing.client || ' - ')+" ")]),_c('div',{staticClass:"mt-2 flex flex-col p-2 rounded-lg mb-2 bg-gray-100"},[_c('span',{staticClass:"text-xs text-gray-500 tracking-tight"},[_vm._v(" Etapas ")]),_c('div',{staticClass:"flex flex-wrap items-center justify-center"},_vm._l((drawing.steps),function(step){return _c('span',{key:step.code,staticClass:"px-2 mt-2 mr-1 text-xs bg-gray-200 rounded-full py-1",class:{
                'bg-orange-200':step.status === 'Em andamento' || step.status === 'Aguardando cliente',
                'bg-red-200': step.status === 'Aguardando',
                'bg-green-300': step.status === 'Finalizado',
              },attrs:{"title":step.name}},[_c('i',{staticClass:"fas",class:{
                'fa-clock text-orange-400': step.status === 'Em andamento' || step.status === 'Aguardando cliente',
                'fa-times text-red-700': step.status === 'Aguardando',
                'fa-check text-green-500': step.status === 'Finalizado',
              }}),_vm._v(" "+_vm._s(_vm._f("steps")(step.name))+" ")])}),0)]),_c('div',{staticClass:"flex flex-row items-stretch self-end py-2 absolute bottom-0"},[_c('button',{staticClass:"focus:outline-none mr-1 mt-4 flex justify-center items-center bg-blue-500 w-6 h-6 py-1 rounded-full text-white text-xs flex-col",attrs:{"title":"Ver detalhes do desenho"},on:{"click":function($event){return _vm.goTo(drawing.id)}}},[_c('i',{staticClass:"fas fa-plus"})]),_c('button',{staticClass:"focus:outline-none flex justify-center items-center mr-1 mt-4 w-6 h-6 py-1 rounded-full text-white text-xs flex-col",class:{
                  'cursor-default': !_vm.isManager,
                  'bg-blue-500': drawing.developerApproved,
                  'bg-gray-500 opacity-50': !drawing.developerApproved
                },attrs:{"disabled":!_vm.isManager,"title":drawing.developerApproved? 'Developer aprovada' : 'Aguardando aprovação para iniciar Developer'},on:{"click":function($event){return _vm.openModal(drawing)}}},[_c('i',{staticClass:"far far fa-paper-plane"})])])])}),(_vm.drawins.length)?_c('Pagination',{staticClass:"lg:hidden",attrs:{"pageNumber":_vm.pageListDrawins,"pageLimit":_vm.drawinsPagesLimit},on:{"next":function($event){return _vm.nextDrawingList(true)},"previous":function($event){return _vm.nextDrawingList(false)}}}):_vm._e()],2):_vm._e(),(!_vm.loading && _vm.isFreela)?_c('div',{staticClass:"flex flex-col xl:flex-row"},[_vm._l((_vm.drawins),function(drawing){return _c('div',{key:drawing.id,staticClass:"flex flex-col relative hover:bg-gray-200 mb-2 w-full xl:w-1/6 mr-2 cursor-pointer bg-white px-3 rounded-md border text-center",on:{"click":function($event){return _vm.goTo(drawing.id)}}},[(drawing.freelancerPaid)?_c('div',{staticClass:"flex text-xs uppercase bg-green-200 px-2 font-semibold rounded-full text-green-800 self-end absolute top-0 mt-2 items-center justify-center"},[_c('i',{staticClass:"fas fa-check mr-2"}),_vm._v(" Pago ")]):_vm._e(),_c('div',{staticClass:"mb-3 self-center"},[_c('img',{staticClass:"mt-4 w-40 h-40 rounded-full object-cover hover:rounded-none",attrs:{"width":"200","height":"200","src":drawing.imageUrl || require('../../assets/images/no_image.png'),"alt":""}})]),_c('span',{staticClass:"uppercase text-xl font-medium truncate text-gray-700"},[_vm._v(_vm._s(drawing.lucinRef || ' - '))]),_c('span',{staticClass:"font-medium mb-8 truncate text-gray-700"},[_vm._v(_vm._s(drawing.name || ' - '))]),_c('div',{staticClass:"text-xs w-full flex font-semibold mb-2 truncate justify-between text-gray-700"},[_c('span',{staticClass:"bg-gray-200 text-gray-700 px-2 py-1 xl:mr-0 rounded-lg",class:{
             'bg-green-200 text-green': _vm.freelancerFinished(drawing.steps) 
            }},[_vm._v(" "+_vm._s(_vm.freelancerFinished(drawing.steps) ? "Finalizado" : "Em andamento")+" ")]),_c('span',{staticClass:"bg-gray-200 text-gray-700 px-2 py-1 rounded-lg"},[_c('i',{staticClass:"fas fa-coins mr-2"}),_vm._v(" R$ "+_vm._s(drawing.steps.reduce((p, c) => { if(c.designerId !== _vm.myUser.id) { return p } return c.freelancerPrice + p }, 0).toFixed(2) || 0)+" ")])])])}),(_vm.drawins.length)?_c('Pagination',{staticClass:"lg:hidden",attrs:{"pageNumber":_vm.pageListDrawins,"pageLimit":_vm.drawinsPagesLimit},on:{"next":function($event){return _vm.nextDrawingList(true)},"previous":function($event){return _vm.nextDrawingList(false)}}}):_vm._e()],2):_vm._e(),(_vm.loading)?_c('Skeleton'):_vm._e()],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-sm text-center rounded-full"},[_c('button',{staticClass:"flex flex-col items-center justify-center text-center focus:outline-none border rounded-full w-12 h-12",attrs:{"type":"button"}},[_c('span',{staticClass:"text-center fill-current"},[_c('i',{staticClass:"fas fa-chevron-left"})])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"text-sm text-center rounded-full"},[_c('button',{staticClass:"flex flex-col items-center justify-center text-center focus:outline-none border rounded-full w-12 h-12",attrs:{"type":"button"}},[_c('span',{staticClass:"text-center fill-current"},[_c('i',{staticClass:"fas fa-chevron-right"})])])])
}]

export { render, staticRenderFns }