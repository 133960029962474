<template>
  <div class="bg-white p-4 border  rounded-lg  w-full  lg:mt-4">
    <div>
      <div class="mb-2 pb-2">
        <h3 class="font-semibold text-lg">{{ title }}</h3>
        <p class="text-sm text-gray-500">{{ subtitle }}</p>
      </div>
      <slot v-if="!loading"> </slot>
      <div
        v-else
        class="flex flex-col bg-gray-100  rounded-lg animate-pulse h-40  justify-center items-center "
      >
        <span class="text-gray-600">
          Gerando relatório...
        </span>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'CardChart',
  props: {
    title: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    subtitle: {
      type: String,
      required: false
    }
  }
})
</script>
