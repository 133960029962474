<template>
  <section class="flex  flex-col w-full md:w-7/12 lg:w-7/12 xl:w-1/2   animate__animated animate__fadeIn">
    <div v-for="signup in data" :key="signup.id" @click="onSelect($event, signup.email)" class="bg-white mb-3 px-4 py-4 shadow rounded cursor-pointer selectable" >
    <div class="flex flex-col justify-center flex-wrap items-center md:justify-between md:flex-row xl:flex-row xl:justify-between" >
      <span class="text-sm uppercase font-bold "> {{ signup.name }} </span>
      <span class="text-sm font-medium  uppercase  "> {{ signup.email }} </span>
      <span class="text-sm font-medium  uppercase  "> {{ signup.roles[0] }} </span>
      <span class="text-sm font-medium  uppercase  ">
          <span
            class="relative inline-block px-3 py-1 text-sm  leading-tight">
            <span aria-hidden class="absolute inset-0 bg-yellow-400 opacity-50 rounded-full"></span>
            <span class="relative ">Pendente</span>
          </span>
      </span>
    </div>
    <div class="mt-2 border-t-2 text-center relative">
      <button  @click.stop="onReprobates(signup.email)" class="mt-2 mr-4 text-sm bg-red-600 text-white rounded py-1 px-2 hover:bg-red-700 focus:outline-none">
          Reprovar
      </button>
      <button @click.stop="onApproves(signup.email)" class="mt-2 mr-4 bg-green-500 text-sm text-white py-1 rounded px-2 hover:bg-green-700 focus:outline-none">
          Aprovar
      </button>
      <span class="float-right bottom-0 right-0  -mb-3 -mr-1 text-gray-600 opacity-50 text-sm absolute"> <time-ago :datetime="signup.createdAt" locale="pt_BR"></time-ago> </span>
    </div>
</div>
  </section>

</template>

<script lang="ts">
import Vue from 'vue'
import TimeAgo from 'vue2-timeago'

export default Vue.extend({
  name: 'SignupCard',
  components: {
    TimeAgo
  },
  props: {
    data: Array
  },
  methods: {
    onSelect (event: MouseEvent, userEmail: string) {
      this.highlightSelect(event)
      this.$emit('onSelect', userEmail)
    },
    onApproves (userEmail: string) {
      this.$emit('onApproves', userEmail)
    },
    onReprobates (userEmail: string) {
      this.$emit('onReprobates', userEmail)
    },
    highlightSelect (event: any) {
      let el = event.target

      while (!el.classList.contains('selectable')) {
        el = el.parentElement
      }

      ['border', 'border-2', 'bg-gray-200'].map(cls => el.classList.toggle(cls))
    }
  }
})
</script>
