var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col w-full justify-center items-center mt-5"},[_c('div',{},[_c('button',{staticClass:"bg-gray-300 mr-2 p-2 rounded-r rounded-md focus:outline-none",class:{
        'opacity-50': _vm.count === 1,
        'hover:bg-gray-400': _vm.count > 1
      },attrs:{"type":"button","disabled":_vm.count === 1},on:{"click":_vm.previous}},[_c('i',{staticClass:"fas fa-angle-double-left text-xs"}),_vm._v(" Anterior ")]),_c('button',{staticClass:"bg-gray-300 p-2 rounded-l rounded-md focus:outline-none",class:{
        'opacity-50': _vm.count === _vm.pageLimit,
        'hover:bg-gray-400': _vm.count !== _vm.pageLimit
      },attrs:{"type":"button","disabled":_vm.count === _vm.pageLimit},on:{"click":_vm.next}},[_vm._v(" Próximo "),_c('i',{staticClass:"fas fa-angle-double-right text-xs"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }