<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'SampleSelectInput',
  inheritAttrs: true,
  props: {
    label: String,
    placeholder: String,
    options: Object,
    validation: String,
    name: String,
    value: {
      type: [String, Number, Boolean],
      default: null
    }
  },
  methods: {
    onChange (event: any) {
      this.$emit('onChange', event)
    }
  }
})
</script>

<template>
  <div>
    <FormulateInput
      @input="onChange($event)"
      :value="value"
      v-bind="$attrs" 
      label-class="mt-4 block uppercase text-gray-700 text-xs font-bold "
      input-class="w-full bg-white px-3 py-2 border border-gray-400  text-gray-500 border-box rounded leading-none outline-none hover:rounded "
      input-hasErrors-class="appearance-none border border-red-500"
      input-isValid-class="text-gray-900"
      :options="options"
      :name="name"
      type="select"
      :placeholder="placeholder"
      :label="label"
      :validation="validation"
      :validation-messages="{
          required: 'Campo obrigatório'
      }"
      error-class="text-red-500 text-xs italic border-red-500"
    />
  </div>
</template>
