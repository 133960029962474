import { IDrawinsSteps, DrawinStepParams } from '@/domain/drawins/drawins-steps/drawins-steps'
import { DrawinModel } from '@/domain/models/drawins/drawin-model'
import { IHttpClient } from '@/data/protocols/httpClient'
import { IHttpResponse } from '@/domain/models/api-responses'
import { updateRequest } from '@/data/helper/http-request-decorators'

export class DrawinsSteps implements IDrawinsSteps {
  constructor (
    private url: string,
    private token: string,
    private readonly http: IHttpClient<DrawinModel>
  ) {

  }
  @updateRequest
  async setStep (id: string, params: DrawinStepParams): Promise<IHttpResponse<DrawinModel>> {
    return await this.http.request({
      method: 'put',
      url: `${this.url}/${id}/setStep`,
      body: params,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }
  
  @updateRequest
  async designerFinish(id: string, params: DrawinStepParams): Promise<IHttpResponse<DrawinModel>> {
    return await this.http.request({
      method: 'put',
      url: `${this.url}/${id}/designerFinish`,
      body: params,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

  @updateRequest
  async approveDesignerDrawinStep(id: string, params: DrawinStepParams): Promise<IHttpResponse<DrawinModel>>{
    return await this.http.request({
      method: 'put',
      url: `${this.url}/${id}/approveDesignerStep`,
      body: params,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

  @updateRequest
  async reprobateDesignerDrawinStep(id: string, params: DrawinStepParams): Promise<IHttpResponse<DrawinModel>>{
    return await this.http.request({
      method: 'put',
      url: `${this.url}/${id}/reprobateStep`,
      body: params,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

  @updateRequest
  async customerApprovesDrawinsStep(id: string, params: DrawinStepParams): Promise<IHttpResponse<DrawinModel>>{
       return await this.http.request({
      method: 'put',
      url: `${this.url}/${id}/customerApproveDesignerStep`,
      body: params,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

  @updateRequest
  async customerRepprovesDrawinStep(id: string, params: DrawinStepParams): Promise<IHttpResponse<DrawinModel>>{
    return await this.http.request({
      method: 'put',
      url: `${this.url}/${id}/customerReprobateDesignerStep`,
      body: params,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

}
