var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col mx-2 xl:flex-row h-60"},[_c('div',{staticClass:"bg-white min-h-60 w-full xl:w-1/6 mt-8 xl:mt-0 rounded-lg border overflow-auto xl:mr-4"},[_vm._m(0),_c('div',{staticClass:"bg-white flex flex-col justify-center w-full p-4"},[_c('span',{staticClass:"text-center font-bold uppercase font-mono text-gray-700 mb-2 h-10"},[_vm._v(" Cliente x Qtd. Desenhos x Tipo ")]),_c('span',{staticClass:"text-xs mb-2 font-thin text-gray-700 h-10 py-2"},[_vm._v(" Relatório de quantidades de desenhos por cliente e tipo de estampa. ")]),_c('label',{staticClass:"block uppercase text-gray-700 text-xs font-bold mt-4"},[_vm._v(" Cliente ")]),_c('v-select',{staticClass:"style-chooser",attrs:{"placeholder":"cliente","options":_vm.listCustomers,"filterable":false,"value":_vm.drawinQtyType.customerName,"label":"name","id":"client","name":"client"},on:{"search":_vm.listAllCustomers,"input":function($event){return _vm.setCustomerName(_vm.drawinQtyType, $event)}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{},[_vm._v(" "+_vm._s(option.name)+" ")])]}}])},[_c('template',{slot:"no-options"},[_vm._v(" Informe o NOME do cliente, se o cadastro existir, buscaremos pra você :) ")])],2),_c('FormulateInput',{attrs:{"type":"date","name":"Data de inicio","value":_vm.drawinQtyType.startDate,"label":"De","validation":"after:2020-09-20","label-class":"mt-4 block uppercase text-gray-700 text-xs font-bold truncate lg:break-words","input-class":"w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none","error-class":"text-red-500 text-xs italic border-red-500","input-has-errors-class":"appearance-none border border-red-500","placeholder":"","validation-messages":{
          after: 'Data inicial deve ser depois de 20/09/2020'
        },"min":"2020-09-20","max":new Date().toISOString().split('T')[0]},model:{value:(_vm.drawinQtyType.startDate),callback:function ($$v) {_vm.$set(_vm.drawinQtyType, "startDate", $$v)},expression:"drawinQtyType.startDate"}}),_c('FormulateInput',{attrs:{"value":_vm.drawinQtyType.endDate,"type":"date","name":"Data final","label":"Até","label-class":"mt-4 block uppercase text-gray-700 text-xs font-bold truncate lg:break-words","input-class":"w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none","error-class":"text-red-500 text-xs italic border-red-500","input-has-errors-class":"appearance-none border border-red-500","validation-messages":{
          before: 'Data final deve ser depois da data inicial'
        },"min":_vm.drawinQtyType.startDate,"input-container-class":"normal-text-color"},model:{value:(_vm.drawinQtyType.endDate),callback:function ($$v) {_vm.$set(_vm.drawinQtyType, "endDate", $$v)},expression:"drawinQtyType.endDate"}}),_c('button',{staticClass:"flex justify-center",class:{
          'bg-gray-400  text-black cursor-pointer hover:bg-gray-200 text-sm font-mono uppercase font-semibold  mt-6 p-1 rounded-lg ':
            _vm.drawinQtyType.startDate && _vm.drawinQtyType.endDate,
          'bg-gray-200  cursor-not-allowed text-gray-400 text-sm font-mono uppercase font-semibold  mt-6 p-1 rounded-lg  ':
            !_vm.drawinQtyType.startDate || !_vm.drawinQtyType.endDate
        },attrs:{"type":"button","disabled":!_vm.drawinQtyType.startDate || !_vm.drawinQtyType.endDate},on:{"click":_vm.generateDrawinQtyType}},[_vm._v(" Gerar relatório ")])],1)]),_c('div',{staticClass:"bg-white w-full xl:w-1/6 mt-8 xl:mt-0 rounded-lg border overflow-auto xl:mr-4"},[_vm._m(1),_c('div',{staticClass:"bg-white flex flex-col justify-center w-full p-4"},[_c('span',{staticClass:"text-center font-bold uppercase font-mono text-gray-700 mb-2 h-10"},[_vm._v(" Developer em andamento ")]),_c('span',{staticClass:"text-xs mb-2 font-thin text-gray-700 h-10 py-2"},[_vm._v(" Relatório de desenhos com developer enviados ou fazendo. ")]),_c('label',{staticClass:"block uppercase text-gray-700 text-xs font-bold mt-4"},[_vm._v(" Cliente ")]),_c('v-select',{staticClass:"style-chooser",attrs:{"placeholder":"cliente","options":_vm.listCustomers,"filterable":false,"value":_vm.developerInProgress.customerName,"label":"name","id":"client","name":"client"},on:{"search":_vm.listAllCustomers,"input":function($event){return _vm.setCustomerName(_vm.developerInProgress, $event)}},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('div',{},[_vm._v(" "+_vm._s(option.name)+" ")])]}}])},[_c('template',{slot:"no-options"},[_vm._v(" Informe o NOME do cliente, se o cadastro existir, buscaremos pra você :) ")])],2),_c('FormulateInput',{attrs:{"type":"date","name":"Data de inicio","value":_vm.developerInProgress.startDate,"label":"De","validation":"after:2020-09-20","label-class":"mt-4 block uppercase text-gray-700 text-xs font-bold truncate lg:break-words","input-class":"w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none","error-class":"text-red-500 text-xs italic border-red-500","input-has-errors-class":"appearance-none border border-red-500","placeholder":"","validation-messages":{
          after: 'Data inicial deve ser depois de 20/09/2020'
        },"min":"2020-09-20","max":new Date().toISOString().split('T')[0]},model:{value:(_vm.developerInProgress.startDate),callback:function ($$v) {_vm.$set(_vm.developerInProgress, "startDate", $$v)},expression:"developerInProgress.startDate"}}),_c('FormulateInput',{attrs:{"value":_vm.developerInProgress.endDate,"type":"date","name":"Data final","label":"Até","label-class":"mt-4 block uppercase text-gray-700 text-xs font-bold truncate lg:break-words","input-class":"w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none","error-class":"text-red-500 text-xs italic border-red-500","input-has-errors-class":"appearance-none border border-red-500","validation-messages":{
          before: 'Data final deve ser depois da data inicial'
        },"min":_vm.developerInProgress.startDate,"input-container-class":"normal-text-color"},model:{value:(_vm.developerInProgress.endDate),callback:function ($$v) {_vm.$set(_vm.developerInProgress, "endDate", $$v)},expression:"developerInProgress.endDate"}}),_c('button',{class:{
          'bg-gray-400  text-black cursor-pointer hover:bg-gray-200 text-sm font-mono uppercase font-semibold  mt-6 p-1 rounded-lg ':
            _vm.developerInProgress.startDate && _vm.developerInProgress.endDate,
          'bg-gray-200  cursor-not-allowed text-gray-400 text-sm font-mono uppercase font-semibold  mt-6 p-1 rounded-lg  ':
            !_vm.developerInProgress.startDate || !_vm.developerInProgress.endDate
        },attrs:{"type":"button","disabled":!_vm.developerInProgress.startDate || !_vm.developerInProgress.endDate},on:{"click":_vm.generateDeveloperInProgress}},[_vm._v(" Gerar relatório ")])],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bg-gray-100 rounded-tr-lg rounded-tl-lg flex justify-center w-full p-4"},[_c('img',{staticClass:"h-40 w-full",attrs:{"src":require("../../assets/svgs/illustrations/date_picker.svg"),"alt":"","srcset":"","loading":"lazy"}})])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"bg-gray-100 rounded-tr-lg rounded-tl-lg flex justify-center w-full p-4"},[_c('img',{staticClass:"h-40 w-full",attrs:{"src":require("../../assets/svgs/illustrations/sent.svg"),"alt":"","srcset":"","loading":"lazy"}})])
}]

export { render, staticRenderFns }