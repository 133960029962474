<template>
  <section>
        <div class="mt-4 -mb-1 px-4 justify-around w-full hidden lg:flex  whitespace-no-wrap lg:d lg:flex-row">
          <span class="cursor-pointer" v-for="column of availableColumns" :key="column.id" :class="column.class" @click="sort(column.name)"> 
            {{ column.alias }} 
            <i class="fas  text-gray-500" :class="{
              'fa-sort-alpha-down': column.orderState === 1,
              'fa-sort-alpha-up': column.orderState === -1
            }" v-if="column.orderState !== 0"></i>
          </span>
        </div>
      <div v-for="dvl of develop" :key="dvl.id"  >
        <div  @click.self="toggleDiv" :class="{
          'bg-yellow-200': dvl.urgent && dvl.activeStatus !== 'received',
        }" class="border  bg-white  z-10 mt-2 flex flex-col items-start p-4 lg:items-center justify-around w-full lg:flex-row lg:h-20 cursor-pointer hover:bg-gray-100">
           <div class="flex flex-col ">
            <span  class="text-gray-500 uppercase text-sm font-hairline lg:hidden"> Desenho </span>
            <div class="flex flex-col mb-4 lg:mb-0 lg:self-auto bg-gray-300  w-20 lg:w-12 ">
              <img class="h-20 w-20 lg:w-12 lg:h-12 border  " :src="dvl.drawing.imageUrl || require('../../assets/images/no_image.jpg') " >
            </div>
          </div>
           <div class="flex flex-col  text-gray-600 lg:w-32 truncate">
              {{ dvl.drawing.lucinRef || '-' }}
          </div>
           <div class="flex flex-col  text-gray-600 lg:w-40 truncate ">
              {{ dvl.drawing.name || '-'}}
          </div>
           <div class="flex flex-col text-gray-600 lg:w-32 ">
               {{dvl.status | firstUpload | formatDate }}
          </div>
          <div class="flex flex-col  text-gray-600 lg:w-40">
              {{dvl.status | findStart | formatDate }}
          </div>         
          <div class="flex flex-col  text-gray-600 lg:w-32" 
          :class="{ 
            'text-blue-700': dvl.activeStatus === 'sent_first_soff',
            'text-green-700': dvl.activeStatus === 'received'
            }">
              {{ dvl.activeStatus | statusTranslate(statusOptions) }}
          </div>
          <div class="flex flex-col text-gray-600 text-center lg:w-40">
            {{ dvl.daysInFactory ? dvl.daysInFactory : $options.filters.daysRemaining($options.filters.findStart(dvl.status))}}
          </div>
           <div class="flex flex-col text-gray-600 lg:w-24 ">
              {{ dvl.drawing.client || '-'}}
          </div>
          <div class="flex flex-col text-gray-600 lg:w-24 ">
              {{ format(new Date(dvl.drawing.factoryMaxDate), 'dd/MM/yy')  || '-'}}
          </div>
        </div>
        <div class="animate__animated animate__fadeIn border hidden flex flex-col  bg-gray-200 p-2" >
          <div class="flex flex-col xl:flex-row justify-between" >
              <FormulateForm v-if="canEdit" class="flex flex-col lg:flex-row xl:flex-row " name="base" @submit="onAddDevelopStatus($event, dvl.id, dvl.status)">
                <SampleInput  class="text-sm mr-2 w-full xl:w-1/2" type="date"  validation="required" label="Data" name="date" id="productionDate"/>
                <SampleSelectInput  class=" w-full xl:w-1/2" name="status" validation="required" :options="filterOptions(dvl.status)" placeholder="informe o status" label="Status"/>
                <button   v-if="canEdit"  type="submit" class="mt-8 mx-4 focus:outline-none">
                  <i class="fas fa-plus-circle text-2xl text-green-600"></i>
                </button>
              </FormulateForm>

              <FormulateForm class="flex justify-center   w-full lg:w-3/4 items-center  flex-col xl:justify-self-end  xl:flex-row"  @submit="onUpdateDevelop($event, dvl)">
                <SampleInput :disabled="!canEdit" :value="dvl.sentToFactoryBy" class="mr-2 text-lg w-full  xl:w-1/5 " type="text" label="Responsável pelo envio" name="sentToFactoryBy" />
                <SampleSelectInput
                    :disabled="!canEdit"
                    :value="dvl.courier"
                    class="mr-2 w-full xl:w-1/5" 
                    name="courier"
                    :options="{
                      '': null,
                      'DHL': 'DHL',
                      'FEDEX': 'FEDEX',
                      'TNT': 'TNT',
                      'OTHER': 'OUTRO'
                    }"
                    label="Courier"/>
                <SampleInput :disabled="!canEdit" :value="dvl.awb" class="mr-2 text-lg w-full  xl:w-1/5" type="text" label="AWB" name="awb"/>
                <SampleInput :disabled="!canEdit" :value="dvl.estimatedDelivery ? dvl.estimatedDelivery.split('T')[0] : null" class="text-sm mr-2 w-full  xl:w-1/5" type="date" label="Data" name="estimatedDelivery" />
                <SampleInput :disabled="!canEdit" :value="dvl.drawingFactory" class="mr-2 text-lg w-full  xl:w-1/5 " type="text" label="Desenho da fabrica" name="drawingFactory" />
                <SampleCheckbox v-if="canEdit" :disabled="!canEdit" :value="dvl.urgent" class="mt-8 w-full  xl:w-1/5" label="Urgente"  name="urgent"  @chkToggle="dvl.urgent = $event.checked"/>
                <button v-if="canEdit" type="submit" class="text-xs h-6 text-white ml-6 mr-2  w-full xl:w-1/12  mt-8 p-1 rounded bg-green-600 focus:outline-none">
                  Salvar
                </button>
              </FormulateForm>
          </div>
          <div class="mt-4 border-t border-gray-400 flex">
            <div class="p-2 border-r w-1/2">
             <span class="font-thin " >Histórico</span>
              <div class="flex flex-col items-start">
                <div v-for="(b, index) of sortHistoric(dvl.status) " :key="b.status + index" class="py-1 px-2 mt-1 mr-1 text-sm w-full flex flex-row  justify-between hover:bg-gray-300">
                  <div class="">
                    <span class="font-semibold">{{ b.date | formatDate }} </span> &nbsp;
                    <span> - {{ b.status | statusTranslate(statusOptions) }}</span>
                    <span v-if="b.status === 'received' && dvl.awb " class="font-semibold">{{  ` [AWB ${dvl.awb}]`  }}</span>
                  </div>
                  <span v-if="canEdit" class="cursor-pointer" @click="removeStatus(dvl.id, b.status)"> <i class="fas fa-times text-red-600 " ></i></span>
                </div>
              </div>
            </div>
            <div class="flex flex-col items-center p-2 border-r w-1/6">
              <span class="self-start font-thin" >Tipo</span> 
              <span class="whitespace-pre-wrap p-2"> {{ dvl.drawing.type }} </span>
            </div>
            <div class="p-2 w-1/2">
              <span class="font-thin" >Bases</span> 
               <div class="flex flex-col items-start">
                 <span v-for="b of dvl.bases" :key="b.id" @click="activeBase=b" class="bg-white border rounded-full py-1 px-2 mt-1 mr-1 cursor-pointer hover:bg-gray-100">  {{ b.name  }}  </span>
               </div>
            </div>
          </div>
          <div class="border-t pt-2 flex justify-end">
            <button class="text-blue-600 focus:outline-none" @click="openActiveDrawin(dvl.drawingId)"> Ver desenho </button>
          </div>
        </div>
      </div>
    <div v-if="activeBase"  class="fixed w-full  bg-black  bg-opacity-25 h-100 inset-0 z-50 overflow-hidden flex justify-center items-center cursor-pointer"  @click="activeBase=null">
      <BasesModalCard @close="activeBase=null" :activeBase="activeBase" />
    </div>
  </section>
</template>

<script lang="ts">
    // TODO REPLICACAO DE CODIGO - COMPONETIZACAO TOTALMENTE NECESSARIA

  import Vue from 'vue'
  import DevelopCard from '@/presentation/components/cards/develop-card.vue'
  import { Token } from '@/presentation/helpers/token-local-storage'
  import {DeveloperStatus, DrawingDeveloperModel} from '@/domain/models/develop/develop-model'
  import { format } from 'date-fns'
  import SampleSelectInput from '@/presentation/components/forms/sample-select-input.vue'
  import SampleInput from '@/presentation/components/forms/sample-input.vue'
  import SampleCheckbox from '@/presentation/components/forms/sample-checkbox.vue'
  import {mapActions} from "vuex";
  import NProgress from 'nprogress'
  import {Toastr} from "@/presentation/helpers/toastr-configs";
  import {DatesHelper} from "@/presentation/helpers/dates-helper";
  import BasesModalCard from '@/presentation/components/cards/bases-modal-card.vue'
  import {BaseModel} from "@/domain/models/bases/base-model";



  export default Vue.extend({
    name: 'DevelopList',
    components: {
      DevelopCard,
      SampleSelectInput,
      SampleCheckbox,
      SampleInput,
      BasesModalCard
    },
    data () {
      return {
        columns: [
          {id: 1 , name: '', alias: '#', class:'lg:w-12 text-center', show:true, orderState: 0 },
          {id: 2 , name: 'drawing.name', alias: 'DESENHO', class:'text-gray-600 lg:w-32', show:true,  orderState: 0},
          {id: 3 , name: 'drawing.lucinRef', alias: 'NOME', class:'text-gray-600 lg:w-40', show:true,  orderState: 0},
          {id: 4 , name: 'upload', alias: 'UPLOAD', class:'text-gray-600 lg:w-32', show:true,  orderState: 0},
          {id: 5 , name: 'start', alias: 'START', class:'text-gray-600 lg:w-40', show:true,  orderState: 0},
          {id: 6 , name: 'activeStatus', alias: 'STATUS', class:'text-gray-600 lg:w-32', show:true,  orderState: 0},
          {id: 7 , name: 'daysInFactory', alias: 'DIAS NA FÁBRICA', class:'text-gray-600 text-center lg:w-40', show:true,  orderState: 0},
          {id: 8 , name: 'drawing.client', alias: 'CLIENTE', class:'text-gray-600 lg:w-24', show:true,  orderState: 0},
          {id: 9 , name: 'drawing.factoryMaxDate', alias: 'ENTREGA', class:'text-gray-600 lg:w-24', show:true,  orderState: 0},

        ],
        open: false,
        canEdit: Token.validRole('administrator', 'manager'),
        statusOptions: {
          'first_upload': '1º UPLOAD',
          'making_first_soff': 'FAZENDO 1º S/OFF',
          'sent_first_soff':'ENVIOU 1º S/OFF',
          'second_upload':'2º UPLOAD',
          "making_second_soff": "FAZENDO 2º S/OFF",
          "send_second_soff": "ENVIOU 2º S/OFF",
          'third_upload':'3º UPLOAD',
          "making_third_soff": "FAZENDO 3º S/OFF",
          "send_third_soff": "ENVIOU 3º S/OFF",
          "making_lab_dip": "FAZENDO L/D",
          "remaking_lab_dip": "REFAZENDO L/D",
          "remaking_hand_loom": "FAZENDO H/LOOM",
          'received': 'RECEBIDO',
          "sample_send_courier": "AMOSTRA ENVIADA POR COURIER",
          "direct_production":"PRODUÇÃO DIRETA",
          "without_s_off": "SEM S/OFF SÓ PILOTAGEM",
          "cancelled":"CANCELADO"
        },
        activeBase: null,
      }
    },
    props: {
      develop: {
        type: Array as () => DrawingDeveloperModel[],
        required: true
      }
    },
    methods: {
      format,
      toggleDiv(e: any){
        e.target.nextElementSibling.classList.toggle('hidden')
      },
      sortHistoric(dvl: any[]){
          return [...dvl].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      },
      showModal(base: BaseModel){
        this.activeBase= base
      },
      sort(columnName:string){
        if(!columnName){
          return
        }

        const activeColumn = this.columns.find(c => c.name === columnName)

        if(!activeColumn){
          return 
        }
        const resetColumns = this.columns.filter(c => c.name !== columnName).map(c => c.orderState = 0)
        activeColumn.orderState += activeColumn.orderState === 1 ? -2 : 1
      
        this.$emit('sort', {  sort:  activeColumn.orderState === 0 ? null:`${activeColumn.orderState >= 0 ? '+' : '-'}${columnName}`   })
      },
      async onAddDevelopStatus(data :any, id: string, status: DeveloperStatus[]){
        NProgress.start()
        if(status?.length){
          if(status.findIndex(c => c.status === data.status) >= 0){
            NProgress.done()
            return
          }
        }
        const parserToDate = new Date(data.date).toISOString()
        await this.setDeveloperStatus({id, ...data})
        NProgress.done()
      },
      async onUpdateDevelop(data: any, developer: DrawingDeveloperModel){
        NProgress.start()
        const value = { ...data, urgent: developer.urgent}
        await this.updateDeveloper({id: developer.id, ...value})
        NProgress.done()
        Toastr.successBottomRight(this, 'Develop atualizada com sucesso!')
      },
      async openActiveDrawin(id: string){
        const route = this.$router.resolve({path: `/portal/estilo/desenhos/${id}/arte`})
        window.open(route.href, '_blank')
      },
      filterOptions(value: DeveloperStatus[]){
        const v = value.map(v => v.status)
        return Object.keys(this.statusOptions)
          .filter(k => !v.includes(k as any))
          .reduce((previousValue: {[key:string]: string}, currentValue: string) => {
            previousValue[currentValue] = (this.statusOptions as {[key:string]: string})[currentValue]
            return previousValue
          }, {})
      },
      removeStatus(id: string, status: string): void {

        this.removeDeveloperStatus({ id, status })
      },
      ...mapActions({
        setDeveloperStatus: 'setDeveloperStatus',
        updateDeveloper: 'updateDeveloper',
        removeDeveloperStatus:  'removeDeveloperStatus'
      })
    },
    computed: {
      availableColumns(): any[] {
        return this.columns.filter((c: any) => c.show)
      }
    },
    filters: {
      statusTranslate:  (value: string, options: {[key:string]: string}): string => {
        return options[value] || '-'
      },
      firstUpload(value: DeveloperStatus[]){
        return value.find(c => c.status === 'first_upload')?.date || '-'
      },
      findStart(value: DeveloperStatus[]){
        if(!value){
          return value
        }
        return value.find(c => c.status === 'making_first_soff')?.date || "-"
      },
      formatDate(value: any){
        if(value === '-'){
          return value
        }
        return new Date(`${value.toString().split('T')[0]} 00:00`).toLocaleDateString('pt-br')
      },
      daysRemaining(value: Date){
        const days =  DatesHelper.daysBetweenDateAndToday(new Date(`${value.toString().split('T')[0]} 23:59`))
        if(!days){
          return '-'
        }
        return days
      },
    }
  })
</script>

