const baseUrl = process.env.VUE_APP_API_BASE_URL || 'http://localhost:5050/api/v1'
export const LucinRoute = {
  baseUrl : `${baseUrl}/`,
  login: `${baseUrl}/login`,
  signup: `${baseUrl}/signup`,
  forgotPassword: `${baseUrl}/forgotPassword`,
  recoverPassword: `${baseUrl}/recoverPassword`,
  bases: `${baseUrl}/bases`,
  drawins: `${baseUrl}/drawin`,
  customers: `${baseUrl}/customers`,
  designers: `${baseUrl}/designers`,
  changeAvatar: `${baseUrl}/me`,
  develop: `${baseUrl}/develop`,
  reports: `${baseUrl}/reports`,
  freelancers: `${baseUrl}/reports/freelancer-management`,
  config: `${baseUrl}/configs`,
  colors:  `${baseUrl}/colors`,
}
