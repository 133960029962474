<template>
  <div class="text-gray-800">
    <div
      v-for="(drawin, index) of drawinList"
      :key="drawin.id"
      class="flex flex-col w-60 md:w-full md:flex-row gap-4 cursor-pointer bg-white border rounded p-4 mb-6 hover:border-blue-400 relative"
      :class="{
        'opacity-50': drawin.canceled
      }"
      id="drawin-card"
      @click="openDrawinInNewTab(drawin.id)"
    >
      <span class="right-0 top-0 px-4 py-3 absolute">
        <DrawinListOptionsMenu
          v-if="Token.validRole('administrator', 'manager')"
          :drawin="drawin"
          :index="index"
          position="right"
          @onOpenOptionMenu="menuOpen = $event"
          @onGenerateRecordFile="onGenerateRecordFile"
          @onCancelDrawin="onCancelDrawin"
          @onReactivateDrawin="onReactivateDrawin"
          @onDeveloperApproved="onDeveloperApprove"
        />
      </span>
      <div class="flex justify-center self-center text-sm md:w-1/6">
        <img
          class=" h-40 w-40 rounded-lg border object-cover  "
          loading="lazy"
          :src="drawin.imageUrl || require('../../assets/images/no_image.png')"
        />
      </div>
      <div class="flex flex-col w-full mt-4 uppercase ">
        <p class="text-center font-semibold text-xl text-gray-800">
          {{ drawin.childLucinRef ? drawin.childLucinRef : drawin.lucinRef }}
        </p>

        <div class="flex gap-1" v-if="drawin.childLucinRef">
          <span class="text-gray-500 font-thin ">Lucin ref:</span>
          <span>{{ drawin.lucinRef }}</span>
        </div>

        <div class="flex gap-1">
          <span class="text-gray-500 font-thin ">Cliente:</span>
          <span>{{ drawin.client || '-' }}</span>
        </div>
        <div class="flex gap-1">
          <span class="text-gray-500 font-thin">tipo:</span>
          <span>{{ drawin.type || '-' }}</span>
        </div>

        <div class="flex gap-1">
          <span class="text-gray-500 font-thin">nome do desenho:</span>
          <span>{{ drawin.name || '-' }}</span>
        </div>
        <div class="flex gap-1 ">
          <span class="text-gray-500 font-thin">Compra:</span>
          <span>{{
            drawin.purchaseStatus == 'purchased' ? 'comprado' : 'Não comprado'
          }}</span>
        </div>

        <div class="flex gap-1">
          <span class="text-gray-500 font-thin ">Developer aprovado:</span>
          <span>{{ drawin.developerApproved ? 'SIM' : 'NÃO' }}</span>
        </div>
        <div class="flex gap-1">
          <span class="text-gray-500 font-thin ">Finalizado:</span>
          <span>{{ drawin.progress === 1 ? 'SIM' : 'NÃO' }}</span>
        </div>

        <div class="flex gap-1">
          <span class="text-gray-500 font-thin ">freelancer:</span>
          <span>{{
            drawin.steps.some(s => s.freelancer) ? 'SIM' : 'NÃO'
          }}</span>
        </div>

        <div class="flex gap-1">
          <span class="text-gray-500 font-thin ">Estilista:</span>
          <span>{{ drawin.stylist || '-' }}</span>
        </div>
        <div class="flex gap-1">
          <span class="text-gray-500 font-thin">Origem:</span>
          <span>{{ drawin.studio || '-' }}</span>
        </div>

        <div class="flex gap-1">
          <span class="text-gray-500 font-thin">Cadastro:</span>
          <span>{{
            drawin.registerDate
              ? new Date(drawin.registerDate).toLocaleDateString('pt-br')
              : '-'
          }}</span>
        </div>
        <div class="flex gap-1">
          <span class="text-gray-500 font-thin">Enviar até:</span>
          <span>{{
            drawin.factoryMaxDate
              ? new Date(drawin.factoryMaxDate).toLocaleDateString('pt-br')
              : '-'
          }}</span>
        </div>
        <div class="flex gap-1">
          <span class="text-gray-500 font-thin">Produção:</span>
          <span>{{
            drawin.productionDate
              ? new Date(drawin.productionDate).toLocaleDateString('pt-br')
              : '-'
          }}</span>
        </div>

        <div class="font-headline w-full font-bold text-sm mt-1  py-4 ">
          <span
            class="flex justify-between py-2 px-4  border md:w-full gap"
            :class="{
              'cursor-not-allowed opacity-50 hover:bg-white':
                drawin.basesId.length + drawin.psBasesId.length === 0,
              'hover:bg-gray-200':
                drawin.basesId.length + drawin.psBasesId.length >= 0
            }"
            @click.stop="findBasesByDrawinId(drawin.id, index)"
          >
            <p>Base</p>
            <i class="fas fa-chevron-down self-center"></i
          ></span>
          <div
            class=" bg-gray-100 border-l max-w-full  border-r border-b  p-2 animate__animated animate__fadeIn overflow-x-auto"
            v-if="drawinsBaseMap.find(x => x.drawinId === drawin.id)?.visible"
          >
            <table
              class="text-xs border md:w-full bg-white mt-2   text-left  "
              v-if="drawin.basesId?.length > 0"
            >
              <thead>
                <th class="border-r p-2">Fornecedor</th>
                <th class="border-r p-2">Referência</th>
                <th class="border-r p-2">Código</th>
                <th class="border-r p-2">Descrição</th>
                <th class="border-r p-2">Nome</th>
                <th class="border-r p-2">Composição</th>
                <th class="border-r p-2">Tecido</th>
              </thead>
              <tbody>
                <tr
                  v-for="b of drawin.basesId"
                  :key="b.id"
                  class="truncate border bg-white"
                >
                  <td class=" px-2 py-1 border-r ">
                    {{ b.provider || '-' }}
                  </td>
                  <td class=" px-2 py-1 border-r ">
                    {{ b.providerRef || '-' }}
                  </td>
                  <td class=" px-2 py-1 border-r ">
                    {{ b.code || '-' }}
                  </td>
                  <td class=" px-2 py-1 border-r ">
                    {{ b.description || '-' }}
                  </td>
                  <td class=" px-2 py-1 border-r ">
                    {{ b.name || '-' }}
                  </td>
                  <td class=" px-2 py-1 border-r ">
                    {{ b.composition || '-' }}
                  </td>
                  <td class=" px-2 py-1 border-r ">
                    {{ b.tissue || '-' }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <span
            class="flex justify-between py-2 px-4   border  w-full mt-4  transition-all duration-700"
            :class="{
              'cursor-not-allowed opacity-50 hover:bg-white':
                drawin.purchaseStatus !== 'purchased',
              'hover:bg-gray-200': drawin.purchaseStatus === 'purchased'
            }"
            @click.stop="findPurchase(drawin.id, index)"
          >
            <p>Compras</p>
            <i class="fas fa-chevron-down self-center"></i>
          </span>
          <div
            v-if="
              drawinsPurchaseMap.find(x => x.drawinId === drawin.id)?.visible
            "
            class="flex bg-gray-100 w-full border-l  border-r border-b  p-2 animate__animated animate__fadeIn overflow-x-auto"
          >
            <table
              class="text-xs border w-full bg-white mt-2 text-left"
              v-if="drawin.purchaseList?.length > 0"
            >
              <thead class="">
                <th class="border-r p-2">Num</th>
                <th class="border-r p-2">Cód</th>
                <th class="border-r p-2">Item</th>
                <th class="border-r p-2">Data</th>
                <th class="border-r p-2">Emissão</th>
                <th class="border-r p-2">Quantidade</th>
              </thead>
              <tbody>
                <tr
                  v-for="p of drawin.purchaseList"
                  :key="p.personalId"
                  class="truncate border bg-white"
                >
                  <td class=" px-2 py-1 border-r ">
                    {{ p.numSystem || '-' }}
                  </td>
                  <td class=" px-2 py-1 border-r ">
                    {{ p.code }}
                  </td>
                  <td class=" px-2 py-1 border-r ">
                    {{ p.name }} - ({{ p.color }})
                  </td>
                  <td class=" px-2 py-1 border-r ">
                    {{ new Date(p.purchaseDate).toLocaleDateString('pt-BR') }}
                  </td>
                  <td class=" px-2 py-1 border-r ">
                    {{ new Date(p.emissionDate).toLocaleDateString('pt-BR') }}
                  </td>
                  <td class="text-center">{{ p.quantity }} {{ p.unity }}</td>
                </tr>
                <tr>
                  <td colspan="5" class="text-center font-semibold border-r">
                    Total
                  </td>
                  <td colspan="1" class="text-center font-semibold border-r">
                    {{
                      drawin.purchaseList
                        .reduce((p, c) => c.quantity + p, 0)
                        ?.toFixed(2) || 0
                    }}
                    {{
                      drawin.purchaseList.every(p => p.unity === 'm') ? 'm' : ''
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { BaseModel } from '@/domain/models/bases/base-model';
import { DrawinModel } from '@/domain/models/drawins/drawin-model';
import { PurchaseOrderModel } from '@/domain/models/purchase-model';
import { axiosFactory } from '@/main/factories/http/axios-factory';
import { basesManagementFactory } from '@/main/factories/pages/style/bases/base-http-factory';
import { drawinHttpFactory } from '@/main/factories/pages/style/drawin/drawin-http-factory';
import nProgress from 'nprogress';
import Vue from 'vue'
import DrawinListOptionsMenu from '../drawins/DrawinListOptionsMenu.vue';
import { Token } from '@/presentation/helpers/token-local-storage';

export default Vue.extend({
  name: 'DrawinListView',
  props: {
    drawinList: {
      type: Array<DrawinModel>,
      required: true
    }
  },
  components: {
    DrawinListOptionsMenu
  },
  data() {
    return {
        drawinsPurchaseMap: new Array<{
        drawinId: string
        visible: boolean
        purchase: PurchaseOrderModel[]
      }>(),
      drawinsBaseMap: new Array<{
        drawinId: string
        visible: boolean
        bases: BaseModel[]
      }>(),
      drawinsHttp: drawinHttpFactory(axiosFactory()),
      basesHttp: basesManagementFactory(axiosFactory()),
      menuOpen: false,
      Token,
    }
  },
  methods: {
    openDrawinInNewTab(drawinId: string) {
        console.log(this.menuOpen)
        if(this.menuOpen) {
            return
        }
        window.open(`desenhos/${drawinId}/arte`, '_blank')
    },
    onGenerateRecordFile(drawinId: string) {
        this.$emit('onGenerateRecordFile', drawinId)
    },
    onCancelDrawin(drawinId: string, index: number) {
      this.$emit('onCancelDrawin', drawinId, index)
    },
    onReactivateDrawin(drawinId : string, index:number) {
        this.$emit('onReactivateDrawin', drawinId, index)
    },
    onDeveloperApprove(drawinId: string, index:number) {
        this.$emit('onDeveloperApproved', drawinId, index)
    },
    async findPurchase(drawinId: string, index: number) {
      if (this.drawinList[index].purchaseStatus !== 'purchased') {
        return
      }
      const purchaseListIndex = this.drawinsPurchaseMap.findIndex(
        p => p.drawinId === drawinId
      )
      if (purchaseListIndex >= 0) {
        Vue.set(
          this.drawinsPurchaseMap[purchaseListIndex],
          'visible',
          !this.drawinsPurchaseMap[purchaseListIndex].visible
        )
        return
      }

      nProgress.start()
      const result = await this.drawinsHttp.listPurchaseOrder(drawinId)
      if (!result) {
        nProgress.done()
        return
      }
      this.drawinsPurchaseMap.push({
        drawinId: drawinId,
        visible: true,
        purchase: result.data
      })
      this.drawinList[index].purchaseList = result.data

      nProgress.done()
    },
    async findBasesByDrawinId(drawinId: string, index: number) {
      if (
        this.drawinList[index].basesId.length === 0 &&
        this.drawinList[index].psBasesId.length === 0
      ) {
        return
      }

      const basesListIndex = this.drawinsBaseMap.findIndex(
        p => p.drawinId === drawinId
      )
      if (basesListIndex >= 0) {
        Vue.set(
          this.drawinsBaseMap[basesListIndex],
          'visible',
          !this.drawinsBaseMap[basesListIndex].visible
        )
        return
      }

      nProgress.start()
      const result = await this.basesHttp.findByDrawinId(drawinId)
      if (!result) {
        nProgress.done()
        return
      }
      this.drawinsBaseMap.push({
        drawinId: drawinId,
        visible: true,
        base: result.data
      })

      this.drawinList[index].basesId = result.data
      nProgress.done()
    }
  }
})
</script>
