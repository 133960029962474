import { IRecoverPassword, RecoverPasswordParams } from '@/domain/auth/recover-password'
import { LoginAuthenticationModel } from '@/domain/models/login-authentication-model'
import { IHttpClient } from '../protocols/httpClient'

export class RecoverPassword implements IRecoverPassword {
  constructor (
    private readonly http: IHttpClient<LoginAuthenticationModel>,
    private readonly url: string
  ) {}

  async send (params: RecoverPasswordParams): Promise<LoginAuthenticationModel> {
    const response = await this.http.request({
      body: params,
      method: 'put',
      url: this.url
    })

    const { error, statusCode, errorMessage } = response
    if (error) {
      throw { errorMessage, statusCode }
    }

    const [result] = response.data
    return result
  }
}
