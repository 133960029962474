import { IHttpRequest } from '@/data/protocols/http'
import { IHttpClient } from '@/data/protocols/httpClient'
import { IHttpResponse } from '@/domain/models/api-responses'
import axios from 'axios'

export class AxiosHttp implements IHttpClient<any> {

  private static _instance: AxiosHttp
  private constructor() {}

  static get instance() {
    if (!AxiosHttp._instance) {
      AxiosHttp._instance = new AxiosHttp()
    }
    return AxiosHttp._instance
  }

  async request<T> (request: IHttpRequest): Promise<IHttpResponse<T>> {
    try {
      const { url, method, body: data, headers, params, responseType } = request
      const response = await axios.request({
        url,
        method,
        data,
        headers,
        responseType,
        params,
        withCredentials: true
      })
      return response.data
    } catch (error) {
      return error.response.data
    }
  }
}
