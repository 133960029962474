<template>
  <div class="grid gap-4 md:grid-cols-4 md:gap-5  text-gray-800">
    <div
      v-for="(drawin, index) of drawinList"
      :key="drawin.id"
      class="cursor-pointer"
    >
      <div
        @click="openDrawinInNewTab(drawin.id)"
        class="flex flex-col flex-grow-0 border  bg-white rounded-lg p-4 mt-4 md:mt-0 min-h-full  relative "
        :class="{
          ' opacity-50': drawin.canceled,
          'hover:border-blue-400': !drawin.canceled
        }"
      >
        <DrawinOptionsMenu
          v-if="Token.validRole('administrator', 'manager')"
          :drawin="drawin"
          :index="index"
          position="left"
          @onOpenOptionMenu="onMenuOpen"
          @onGenerateRecordFile="onGenerateRecordFile"
          @onCancelDrawin="onCancelDrawin"
          @onReactivateDrawin="onReactivateDrawin"
          @onDeveloperApproved="onDeveloperApprove"
        />
        <div class="self-center h-40 w-40  ">
          <img
            class="h-40 w-40  border rounded-xl "
            :src="
              drawin.imageUrl || require('../../assets/images/no_image.png')
            "
          />
        </div>
        <div
          class="self-center w-full font-bold  mt-2 p-2 text-center  truncate"
        >
          {{ drawin.childLucinRef ? drawin.childLucinRef : drawin.lucinRef }}
        </div>

        <div class="self-center w-full  my-auto p-2 text-center  truncate ">
          {{ drawin.client }}
        </div>

        <div
          class="self-center w-full text-gray-600 my-auto p-2 text-center  truncate "
        >
          {{ drawin.type }}
        </div>

        <div class=" self-center   mt-4   w-8/12  ">
          <div
            class="flex justify-between lg:flex-row gap-1 md:flex-wrap w-full flex-col text-xs lg:text-sm"
          >
            <span class="  " title="Data produção">
              <i class="far fa-clock mr-1 "></i>
              <span v-if="drawin.productionDate"
                >{{
                  new Date(drawin.productionDate).toLocaleDateString('pt-Br')
                }}
              </span>
              <span v-else>&nbsp; N/D</span>
            </span>
            <span class="  " title="Data máx fábrica">
              <i class="far fa-building mr-1"></i>
              <span v-if="drawin.factoryMaxDate">
                {{
                  new Date(drawin.factoryMaxDate).toLocaleDateString('pt-Br')
                }}
              </span>
              <span v-else>&nbsp; N/D</span>
            </span>
          </div>
        </div>

        <div
          class="self-center w-full  text-blue-600 mt-4 flex flex-wrap justify-center text-center  gap-2  text-xs uppercase   "
        >
          <span
            v-if="drawin.purchaseStatus === 'purchased'"
            class="px-2  border bg-gray-100  whitespace-no-wrap rounded-full"
            >#Comprado</span
          >

          <span
            v-if="drawin.developerApproved"
            class=" px-2  border bg-gray-100   whitespace-no-wrap rounded-full"
          >
            #DEVELOPER APROVADO</span
          >
          <span
            v-if="drawin.steps.some(s => s.freelancer)"
            class=" px-2  border bg-gray-100   whitespace-no-wrap rounded-full"
          >
            #FREELANCER</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { DrawinModel } from '@/domain/models/drawins/drawin-model';
import DrawinOptionsMenu from '@/presentation/components/drawins/DrawinListOptionsMenu.vue';
import Vue from 'vue'
import { Token } from '@/presentation/helpers/token-local-storage';
export default Vue.extend({
  name: 'DrawinGridView',
  props: {
    drawinList: {
      type: Array<DrawinModel>,
      required: true
    },
  },
  components: {
    DrawinOptionsMenu
  },
  data() {
    return {
        purchaseStatusMap: new Map([
        ['pending', 'Não comprado'],
        ['canceled', 'Não comprado'],
        ['purchased', 'Comprado']
      ]),
      drawinIdToOptionMenu: '',
      hasAnyMenuOpen: false,
      Token
    }
  },
  methods: {
    resetOptionMenu(event: Event) {
      if (event.target !== document.getElementById('dropdown-menu')) {
        this.drawinIdToOptionMenu = ''
      }
    },
    openDrawinInNewTab(id: string) {
      if (this.hasAnyMenuOpen) {
        return
      }
      window.open(`desenhos/${id}/arte`, '_blank')
    },
    onGenerateRecordFile(drawinId: string) {
        this.$emit('onGenerateRecordFile', drawinId)
    },
    onCancelDrawin(drawinId: string, index: number) {
      this.$emit('onCancelDrawin', drawinId, index)
    },
    onReactivateDrawin(drawinId : string, index:number) {
        this.$emit('onReactivateDrawin', drawinId, index)
    },
    onDeveloperApprove(drawinId: string, index:number) {
        this.$emit('onDeveloperApproved', drawinId, index)
    },
    onMenuOpen(isOpen: boolean) {
      this.hasAnyMenuOpen = isOpen
    },
  },
  mounted() {
    document.addEventListener('click', this.resetOptionMenu)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.resetOptionMenu)
  }
})
</script>
