import { LoginAuthenticationModel } from '@/domain/models/login-authentication-model'

const LOCAL_STORAGE_NAME = 'portal-lucin'

export abstract class Token {
  static setToken (user: LoginAuthenticationModel) {
    localStorage.setItem(LOCAL_STORAGE_NAME, btoa(JSON.stringify(user)))
  }

  static getAllInfos(): object {
    const token = localStorage.getItem(LOCAL_STORAGE_NAME)
    if (!token) {
      return null
    }
    return JSON.parse(atob(token))
  }
  static getToken (): string {
    const token = localStorage.getItem(LOCAL_STORAGE_NAME)
    if (!token) {
      return null
    }
    return JSON.parse(atob(token)).token
  }

  static updateToken(field: string, value: any): void{
    const token = localStorage.getItem(LOCAL_STORAGE_NAME)
    if (!token) {
      return null
    }
    const parserToken = JSON.parse(atob(token))
    parserToken[field] = value
    localStorage.setItem(LOCAL_STORAGE_NAME, btoa(JSON.stringify(parserToken)))
  }

  static userInfo (): LoginAuthenticationModel {
    const token = localStorage.getItem(LOCAL_STORAGE_NAME)
    if (!token) {
      return null
    }
    return JSON.parse(atob(token))
  }

  static validRole(...roles: string[]): boolean {
    const token = localStorage.getItem(LOCAL_STORAGE_NAME)
    if (!token) {
      return false
    }
    const userInfos = JSON.parse(atob(token))
    return roles.some(r => userInfos.roles.includes(r))
  }

  static removeToken(): void{
    localStorage.removeItem(LOCAL_STORAGE_NAME)
  }
}
