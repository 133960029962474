import { getRequest } from '@/data/helper/http-request-decorators'
import { IHttpClient } from '@/data/protocols/httpClient'
import { ActivityModel } from '@/domain/models/activities'
import { IHttpResponse } from '@/domain/models/api-responses'
import {
  reportParams,
  ChartFormat,
  IDrawinsReports
} from '@/domain/reports/drawins/drawins-report'

export class DrawinsReports implements IDrawinsReports {
  constructor(
    private readonly http: IHttpClient<any>,
    private readonly url: string,
    private readonly token: string
  ) {}
  @getRequest
  async groupByMonthAndYears(
    params?: reportParams
  ): Promise<IHttpResponse<ChartFormat>> {
    return this.http.request({
      method: 'get',
      url: `${this.url}/1`,
      params,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

  @getRequest
  async designerPerformace(
    params?: reportParams
  ): Promise<IHttpResponse<ChartFormat>> {
    return this.http.request({
      method: 'get',
      url: `${this.url}/2`,
      params,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

  @getRequest
  async qtyDrawinsByType(params?: reportParams): Promise<IHttpResponse<null>> {
    return this.http.request({
      method: 'get',
      url: `${this.url}/drawinByCustomerAndType`,
      params,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

  @getRequest
  async drawinWithDeveloperInProgress(
    params?: reportParams
  ): Promise<IHttpResponse<null>> {
    return this.http.request({
      method: 'get',
      url: `${this.url}/drawinWithDeveloperInProgress`,
      params,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

  @getRequest
  async designersRanking(): Promise<any> {
    return this.http.request({
      method: 'get',
      url: `${this.url}/designers`,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

  @getRequest
  async recentActivities(): Promise<IHttpResponse<ActivityModel>> {
    return this.http.request({
      method: 'get',
      url: `${this.url}/recentActivities`,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

  @getRequest
  async myTasks(): Promise<any> {
    return this.http.request({
      method: 'get',
      url: `${this.url}/myTasks`,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

  @getRequest
  async drawinsFromCollection(
    params?: reportParams
  ): Promise<IHttpResponse<null>> {
    return this.http.request({
      method: 'get',
      url: `${this.url}/drawinsFromCollection`,
      params,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }

  @getRequest
  async drawins(queryParams: string): Promise<IHttpResponse<null>> {
    const searchParams = new URLSearchParams(queryParams)
    return this.http.request({
      method: 'get',
      url: `${this.url}/drawins`,
      params: searchParams,
      headers: {
        Authorization: `Bearer ${this.token}`
      }
    })
  }
}
