import { IHttpClient } from '@/data/protocols/httpClient'
import { Designers } from '@/data/users/designers/designers'
import { UserModel } from '@/domain/models/user-model'
import { LucinRoute } from '@/main/configs/routes/routes-paths'
import { Token } from '@/presentation/helpers/token-local-storage'

export const designersHttpFactory = (http: IHttpClient<UserModel>): Designers => {
  return new Designers(LucinRoute.baseUrl, Token.getToken(), http)
}


