<template>
  <section class="z-0 mt-8 ">
    <span class="w-full bg-white">
      <p class="mt-5 -mb-5 self-start py-3  font-semibold text-gray-800 " v-if="drawins.length"> {{  title }}</p>

    </span>
      <div class="mt-5  flex flex-col justify-center mb-5 w-full  rounded-md relative">
      <div v-if="!loading &&  pageListDrawins !== 1" class="absolute hidden z-10 lg:inline left-0 -ml-1 bg-white rounded-full shadow hover:bg-gray-300" @click="nextDrawingList(false)">
        <div class="text-sm text-center rounded-full">
            <button class="flex flex-col items-center  justify-center text-center focus:outline-none  border rounded-full w-12 h-12" type="button">
              <span class="text-center fill-current"> <i class="fas fa-chevron-left"></i> </span>
            </button>
        </div>
      </div>

      <div  v-if="!loading && pageListDrawins < drawinsPagesLimit" class="absolute hidden z-10 lg:inline right-0 -mr-1 bg-white opacity- rounded-full shadow hover:bg-gray-300" @click="nextDrawingList()">
        <div class="text-sm text-center rounded-full">
            <button class="flex flex-col items-center  justify-center text-center focus:outline-none  border rounded-full w-12 h-12" type="button">
              <span class="text-center fill-current"> <i class="fas fa-chevron-right"></i> </span>
            </button>
        </div>
      </div>

      <div  v-if="!loading && !isFreela" class="flex flex-col  lg:flex-row ">
        <div v-for="drawing of drawins" :key="drawing.id"  class="flex flex-col   relative hover:bg-gray-200  mb-2 w-full lg:w-1/6 mr-2 bg-white px-3 py-8 rounded-md border text-center">
          <div  v-if="!drawing.developerApproved" class="flex flex-col self-end absolute top-0 mt-4 mr-2 bg-gray-400 text-white items-center justify-center text-center rounded-full  w-10 h-10" :class="{
            'bg-red-700':  !drawing.developerApproved && days(drawing.factoryMaxDate) <= 7,
            'bg-green-500':  drawing.developerApproved,
          }"> 
            <span class="text-lg  font-semibold " v-if="days(drawing.factoryMaxDate) > 0" >{{ drawing.factoryMaxDate | calculateDays }} </span>
            <span class="-mt-2 text-xs" v-if="days(drawing.factoryMaxDate) > 0" > {{ drawing.factoryMaxDate | calculateDaysText }} </span>
            <i v-else class="fas fa-exclamation" title="Prazo expirado"></i>
          </div>
          <div class="mb-3 self-center">
            <img
              class=" mt-4 w-40 h-40  object-cover rounded-full  hover:rounded-none"
              width="200"
              height="200"
              :src=" drawing.imageUrl || require('../../assets/images/no_image.png')"
              alt=""
            />
          </div>
            <span class="uppercase text-xl font-medium truncate text-gray-700">{{ drawing.lucinRef || ' - ' }}</span>
            <span class="font-medium mb-2 truncate text-gray-700">{{ drawing.name || ' - ' }}</span>
            <span class="mt-2 text-sm truncate text-blue-500 block mb-5 uppercase"> {{ drawing.client || ' - ' }} </span>
          <div class="mt-2 flex flex-col p-2  rounded-lg mb-2 bg-gray-100">
                <span class="text-xs text-gray-500 tracking-tight"> Etapas </span>
                <div class="flex flex-wrap items-center justify-center  ">
                <span  v-for="step of drawing.steps" :key="step.code" :title="step.name" :class="{
                  'bg-orange-200':step.status === 'Em andamento' || step.status === 'Aguardando cliente',
                  'bg-red-200': step.status === 'Aguardando',
                  'bg-green-300': step.status === 'Finalizado',
                }" 
                  class="px-2 mt-2  mr-1 text-xs bg-gray-200 rounded-full py-1  "> 
                  <i class="fas" :class="{
                  'fa-clock text-orange-400': step.status === 'Em andamento' || step.status === 'Aguardando cliente',
                  'fa-times text-red-700': step.status === 'Aguardando',
                  'fa-check text-green-500': step.status === 'Finalizado',
                }"  ></i> {{ step.name | steps }} 
                </span>
                </div>

              </div>
              <div class="flex flex-row items-stretch self-end py-2 absolute bottom-0 ">
                <button title="Ver detalhes do desenho"  @click="goTo(drawing.id)" class="focus:outline-none mr-1 mt-4 flex justify-center  items-center bg-blue-500 w-6 h-6 py-1  rounded-full  text-white text-xs flex-col">
                  <i class="fas fa-plus"></i>  
                </button>
                <button
                  :disabled="!isManager"
                  :title="drawing.developerApproved? 'Developer aprovada' : 'Aguardando aprovação para iniciar Developer'"
                  @click="openModal(drawing)" class="focus:outline-none  flex justify-center  items-center mr-1 mt-4  w-6 h-6 py-1  rounded-full  text-white text-xs flex-col"
                  :class="{
                    'cursor-default': !isManager,
                    'bg-blue-500': drawing.developerApproved,
                    'bg-gray-500 opacity-50': !drawing.developerApproved
                  }"
                >
                  <i class="far far fa-paper-plane"></i>  
                </button>
              </div>
            
        </div>
        <Pagination v-if="drawins.length" @next="nextDrawingList(true)"  @previous="nextDrawingList(false)" :pageNumber="pageListDrawins" :pageLimit="drawinsPagesLimit" class="lg:hidden" />
      </div>
      
      <div  v-if="!loading && isFreela"  class="flex flex-col xl:flex-row ">
        <div v-for="drawing of drawins" :key="drawing.id"  class="flex flex-col  relative hover:bg-gray-200  mb-2 w-full xl:w-1/6 mr-2 cursor-pointer bg-white px-3  rounded-md border text-center"  @click="goTo(drawing.id)">
          <div  v-if="drawing.freelancerPaid"  class="flex text-xs uppercase bg-green-200 px-2 font-semibold rounded-full text-green-800 self-end absolute top-0 mt-2 items-center justify-center  "> 
            <i class="fas fa-check mr-2 "></i>            Pago 
          </div>
          <div class="mb-3 self-center">
            
            <img
              class=" mt-4 w-40 h-40 rounded-full object-cover  hover:rounded-none"
              width="200"
              height="200"
              :src=" drawing.imageUrl || require('../../assets/images/no_image.png')"
              alt=""
            />
          </div>
          <span class="uppercase text-xl font-medium truncate text-gray-700">{{ drawing.lucinRef || ' - ' }}</span>
          <span class="font-medium mb-8 truncate text-gray-700">{{ drawing.name || ' - ' }}</span>
         
          
          <div class="text-xs w-full flex font-semibold mb-2 truncate justify-between text-gray-700">
            <span class="bg-gray-200 text-gray-700 px-2 py-1 xl:mr-0  rounded-lg" :class="{
               'bg-green-200 text-green': freelancerFinished(drawing.steps) 
              }"
            > {{  freelancerFinished(drawing.steps) ? "Finalizado" : "Em andamento" }} </span>
        
            <span class="bg-gray-200 text-gray-700 px-2 py-1  rounded-lg " >
              <i class="fas fa-coins  mr-2"></i>
               R$ {{ drawing.steps.reduce((p, c) => {
                if(c.designerId !== myUser.id) {
                  return p
                }
                return  c.freelancerPrice + p
               }, 0).toFixed(2) || 0 }}
            </span>
            
         
          </div>

            
        </div>
        <Pagination v-if="drawins.length" @next="nextDrawingList(true)"  @previous="nextDrawingList(false)" :pageNumber="pageListDrawins" :pageLimit="drawinsPagesLimit" class="lg:hidden" />
      </div>

      <Skeleton  v-if="loading" />
      </div>
  </section>
</template>

<script lang="ts" >
import { DrawinModel, DrawinStep } from '@/domain/models/drawins/drawin-model'
import { DatesHelper } from '@/presentation/helpers/dates-helper'
import Pagination from '@/presentation/components/utils/pagination.vue'
import Skeleton from '@/presentation/components/skeletons/drawin-list-skletons.vue'
import Vue from 'vue'
import { Toastr } from '@/presentation/helpers/toastr-configs'
import { Token } from '@/presentation/helpers/token-local-storage'
export default Vue.extend({
  name: 'DrawinsMainList',
  data(){
    return {
      pageListDrawins: 1,
      DatesHelper,
      isManager: Token.validRole('manager', 'administrator'),
      isFreela: Token.validRole('freelancer'),
      myUser: Token.userInfo(),
    }
  },
  props: {
    title: {
      required: true,
      type: String
    },
    drawins: {
      required: true,
      type: Array as () => DrawinModel[]
    },
    drawinsPagesLimit: {
      required: true,
      type: Number
    },
    me: {
      default: false,
      type: Boolean
    },
    loading: {
      default: false,
      type: Boolean
    }
  },
  components: {
    Pagination,
    Skeleton,
  },
  methods: {
    goTo(drawingId: string) {
      this.$router.push(`/portal/estilo/desenhos/${drawingId}/arte`)
    },
   async nextDrawingList(inc: boolean = true){
      inc ? ++this.pageListDrawins : --this.pageListDrawins
      this.$emit('loadDrawins', {pageNumber: this.pageListDrawins, me: this.me})
    },
     days(dateString: string){
      return DatesHelper.daysBetweenTodayAnd(new Date(dateString))
    },
    openModal(drawing: DrawinModel){
      if(!this.isManager){
        return
      }
      if(drawing.developerApproved){
        Toastr.successBottomRight(this, 'Developer já aprovada!')
        return
      }
      this.$emit('openModal', drawing.id)
    },
    freelancerFinished(steps: DrawinStep[]): boolean{
      return steps.every(c => {
              if(c.designerId !== this.myUser.id) {
                return true
              }
              return c.designerFinish
            })
    }
  },
  filters : {
    steps: (stepName: string) => {
      stepName = stepName.replace(/\s|\//g, '_')
      if(stepName.includes('_')){
        const [firstPart, secondPart ] = stepName.split('_')
        return  `${firstPart.charAt(0)}${secondPart.charAt(0)}`
      }
      return  `${stepName.charAt(0)}${stepName.charAt(1)}`
    },
    calculateDaysText(dateString: string){
      const days  = DatesHelper.daysBetweenTodayAnd(new Date(dateString))
      if(days === 1) {
        return 'dia'
      }
      return 'dias'
    },
    calculateDays(dateString: string){
      return DatesHelper.daysBetweenTodayAnd(new Date(dateString))
    }
  }
})
</script>