import { DrawinModel, DrawinStep } from '@/domain/models/drawins/drawin-model'
import { IHttpResponse } from '@/domain/models/api-responses'
import { IDrawin } from '@/domain/drawins/drawins'
import {
  IDrawinsSteps,
  DrawinStepParams
} from '@/domain/drawins/drawins-steps/drawins-steps'
import Vue from 'vue'

export const drawinStoreFactory = (
  drawinHttp: IDrawin,
  drawinsStepsHttp: IDrawinsSteps
) => ({
  state(): object {
    return {
      drawins: {
        data: [],
        statusCode: 0,
        pagesLimit: 0,
        dataCount: 0
      },
      activeDrawin: {},
      filterOptions: [
        {
          active: false,
          name: 'developer-pending',
          alias: 'Apenas developer pendentes',
          description: 'Desenhos com Developer pendentes',
          value: 'developerApproved=false'
        },
        {
          active: false,
          name: 'only-progress',
          alias: 'Ocultar finalizados',
          description: 'Desenhos em andamento',
          value: 'progress!=1'
        },
        {
          active: false,
          name: 'only-my',
          alias: 'Apenas meus desenhos',
          description: 'Desenhos que eu participo',
          value: 'onlyMyDrawins=true'
        },
        {
          active: false,
          name: 'show-canceled',
          alias: 'Exibir apenas cancelados',
          description: 'Mostrar somente desenhos cancelados',
          value: 'canceled=true'
        },
        {
          active: false,
          name: 'hide-plain-print',
          alias: 'Ocultar lisos',
          description: 'Ocultar desenhos lisos',
          value: 'type!=/liso/i'
        },
        {
          active: false,
          name: 'only-purchase',
          alias: 'Apenas Comprados',
          description: 'Apenas desenhos comprados',
          value: 'purchaseStatus=purchased'
        }
      ],
      drawinsSearchText: '',
      drawinsPageNumber: 1,
      drawinsPageSize: 8,

      queryParams: ''
    }
  },
  getters: {
    drawins(state: any) {
      return state.drawins.data
    },
    drawinsCount(state: any) {
      return state.drawins.dataCount
    },
    drawinsSearchText(state: any) {
      return state.drawinsSearchText
    },
    drawinsPagesLimit(state: any) {
      return state.drawins.pagesLimit
    },
    activeDrawin(state: any) {
      return state.activeDrawin
    },
    activeDrawingPage(state: any) {
      return state.drawinsPageNumber
    },
    filterOptions(state: any) {
      return state.filterOptions
    },
    hasDrawinFilter(state: any) {
      return state.filterOptions.some((f: any) => f.active)
    },
    drawinsQuery(state: any) {
      return state.queryParams
    }
  },
  mutations: {
    ADD_DRAWIN(state: any, payload: DrawinModel) {
      state.drawins.data.push(payload)
    },
    SET_DRAWING_QUERY_PARAMS(state: any, payload: any) {
      state.queryParams = payload
    },
    LOAD_DRAWINS(state: any, payload: IHttpResponse<DrawinModel>) {
      state.drawins = payload
    },
    SET_ACTIVE_DRAWIN(state: any, payload: DrawinModel) {
      state.activeDrawin = payload
    },
    SET_DRAWIN_SEARCH_TEXT(state: any, payload: string) {
      state.drawinsSearchText = payload
    },
    SET_DRAWING_ACTIVE_PAGE(state: any, payload: number) {
      if (payload <= 0) {
        state.drawinsPageNumber = 1
        return
      }
      state.drawinsPageNumber = payload
    },
    UPDATE_DRAWIN(state: any, payload: { id: string; drawin: DrawinModel }) {
      const { id, drawin } = payload
      const index = state.drawins.data.findIndex(
        (b: DrawinModel) => b.id === id
      )
      if (index !== -1) {
        Vue.set(state.drawins.data, index, drawin)
      }
      state.activeDrawin = drawin
    },
    REMOVE_DRAWING_STEP(state: any, payload: number) {
      state.activeDrawin.steps.splice(payload, 1)
    },
    ADD_DRAWING_STEP(state: any, payload: DrawinStep) {
      state.activeDrawin.steps.push(payload)
    },
    SET_DRAWING_FILTER(state: any, payload: number) {
      state.filterOptions[payload].active = !state.filterOptions[payload].active
    }
  },
  actions: {
    async loadDrawins(context: any, payload: { [key: string]: any }) {
      let { me, pageSize = 8 } = payload

      let pageNumber: number
      if (payload.workspace) {
        pageNumber = payload.pageNumber
      } else {
        pageNumber =
          context.state.drawinsPageNumber <= 0
            ? 1
            : context.state.drawinsPageNumber
      }

      let queryParams = ''
        .concat('pageNumber=')
        .concat(pageNumber.toString())
        .concat('&pageSize=')
        .concat(payload.workspace ? 6 : pageSize)
      if (!payload.workspace) {
        context.state.filterOptions.forEach(f =>
          f.active ? (queryParams = queryParams.concat(`&${f.value}`)) : ''
        )
        queryParams = queryParams
          .concat(payload?.sort?.concat('&') || '')
          .concat('&searchText=')
          .concat(context.state.drawinsSearchText || '')
      }
      const response = await drawinHttp.listDrawins(queryParams, me)
      if (!payload.workspace) {
        context.commit('LOAD_DRAWINS', response)
      }

      return response
    },
    async loadDrawinById(context: any, payload: string) {
      if (context.state.activeDrawin.id === payload) {
        return new Promise(resolve => resolve(context.state.activeDrawin))
      }
      const httpResponse = await drawinHttp.findDrawinById(payload)
      if (!httpResponse.error) {
        context.commit('SET_ACTIVE_DRAWIN', httpResponse.data[0])
      }
      return httpResponse.data[0]
    },
    async createDrawin(
      context: any,
      payload: { drawin: DrawinModel; ignoreCodeGeneration: boolean }
    ) {
      await drawinHttp.newDrawin(payload)
      context.commit('ADD_DRAWIN', payload)
    },
    async updateDrawin(
      context: any,
      payload: { id: string; drawin: DrawinModel; typeJson?: boolean }
    ) {
      const { id, drawin, typeJson } = payload
      const response = await drawinHttp.updateDrawin(id, drawin, !!typeJson)
      context.commit('UPDATE_DRAWIN', { id, drawin: response.data[0] })
      return response
    },
    async setDrawinStep(
      context: any,
      payload: { id: string; params: DrawinStepParams }
    ) {
      const response = await drawinsStepsHttp.setStep(
        payload.id,
        payload.params
      )
      context.commit('SET_ACTIVE_DRAWIN', response.data[0])
      return response
    },
    async designerFinishStep(
      context: any,
      payload: { id: string; params: DrawinStepParams }
    ) {
      const response = await drawinsStepsHttp.designerFinish(
        payload.id,
        payload.params
      )
      context.commit('SET_ACTIVE_DRAWIN', response.data[0])
      return response
    },
    async managerReprobateStep(
      context: any,
      payload: { id: string; params: DrawinStepParams }
    ) {
      const response = await drawinsStepsHttp.reprobateDesignerDrawinStep(
        payload.id,
        payload.params
      )
      context.commit('SET_ACTIVE_DRAWIN', response.data[0])
      return response
    },
    async customerApprovesStep(
      context: any,
      payload: { id: string; params: DrawinStepParams }
    ) {
      const response = await drawinsStepsHttp.customerApprovesDrawinsStep(
        payload.id,
        payload.params
      )
      context.commit('SET_ACTIVE_DRAWIN', response.data[0])
      return response
    },
    async managerApprovesStep(
      context: any,
      payload: { id: string; params: DrawinStepParams }
    ) {
      const response = await drawinsStepsHttp.approveDesignerDrawinStep(
        payload.id,
        payload.params
      )
      context.commit('SET_ACTIVE_DRAWIN', response.data[0])
      return response
    },
    async approveDeveloper(context: any, payload: string) {
      const response = await drawinHttp.approveDeveloper(payload)
      context.commit('UPDATE_DRAWIN', { id: payload, drawin: response.data[0] })
      return response
    },
    async cancel(context: any, payload: string) {
      const response = await drawinHttp.cancel(payload)
      context.commit('SET_ACTIVE_DRAWIN', response.data[0])
      return response
    },
    async reactivate(context: any, payload: string) {
      const response = await drawinHttp.reactivate(payload)
      context.commit('SET_ACTIVE_DRAWIN', response.data[0])
      return response
    },
    async recordFile(
      context: any,
      payload: string
    ): Promise<IHttpResponse<Blob>> {
      return await drawinHttp.recordFile(payload)
    },
    setFilter(context: any, payload: string) {
      const index = context.state.filterOptions.findIndex(
        (f: any) => f.name === payload
      )
      if (index <= -1) {
        return
      }
      context.commit('SET_DRAWING_FILTER', index)
      return context.state.filterOptions
        .filter((f: any) => f.active)
        .map((c: any) => c.value)
    },
    removeDrawingStep(context: any, payload: number) {
      context.commit('REMOVE_DRAWING_STEP', payload)
    },
    addStepDrawing(context: any, payload: DrawinStep) {
      context.commit('ADD_DRAWING_STEP', payload)
    }
  }
})
