<template>
  <section class="pb-4">
    <div
      class="lg:flex hidden p-2 items-center text-center flex-row justify-around"
    >
      <!-- <span class="w-5  truncate"> &nbsp; </span> -->
      <span class="w-12 truncate tex"> &nbsp; </span>
      <span class="w-40 text-gray-500 font-semibold truncate uppercase">
        DESENHISTA</span
      >
      <span class="w-32 text-gray-500 font-semibold truncate">FREELANCER</span>
      <span class="w-32 text-gray-500 font-semibold truncate">ETAPA</span>
      <span class="w-32 text-gray-500 font-semibold truncate">INICIO</span>
      <span class="w-32 text-gray-500 font-semibold truncate">TERMINO</span>
      <span class="w-10 text-gray-500 font-semibold"> CLIENTE </span>
      <span class="w-40 text-gray-500 font-semibold  truncate"> STATUS </span>
      <span class="w-10 text-gray-500 font-semibold "> AÇÕES</span>
    </div>
    <!--  -->
    <div
      v-for="step of activeDrawin.steps"
      :key="step.id"
      class="flex flex-col items-center text-center p-2 border  border-gray-200  bg-white lg:flex-row lg:h-16 lg:items-center lg:justify-around"
    >
      <DrawinsStepsCard
        class="truncate"
        v-for="(setting, index) of stepsSettings"
        :key="index"
        :setting="setting"
        :step="step"
        :class="{ [setting.widthClass]: true }"
      />
    </div>
    <modal
      name="modal-leave-step"
      :width="400"
      :height="350"
      :scrollabe="true"
      :adaptive="true"
      @before-open="setActiveStep"
    >
      <section
        class="flex flex-col items-center justify-center w-full overflow-auto"
      >
        <div class="w-11/12 mt-4">
          <label class="block uppercase text-gray-700 text-xs font-bold ">
            Adicione uma observação para essa etapa</label
          >
          <textarea
            v-model="observation"
            rows="10"
            class="w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none"
          ></textarea>
        </div>
        <div class="w-11/12 mt-4">
          <label class="block uppercase text-gray-700 text-xs font-bold ">
            Medidas</label
          >
          <input
            v-model="measures"
            rows="10"
            class="w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none"
          />
        </div>
        <div
          class="w-full bg-white border-t flex justify-between absolute bottom-0"
        >
          <button
            type="submit"
            class="text-red-600  font-mont  border-r block w-full p-2 uppercase  hover:bg-gray-100 "
            @click="leaveStep"
          >
            Abandonar
          </button>
          <button
            type="submit"
            class="text-green-700 block w-full font-mont  font-thin p-2 uppercase  hover:bg-gray-100 "
            @click="designerFinish"
          >
            Finalizar
          </button>
        </div>
      </section>
    </modal>
  </section>
</template>

<script lang="ts">
import Vue from 'vue'
import DrawinsStepsCard from '@/presentation/components/cards/drawins-steps-card.vue'
import { mapGetters, mapActions } from 'vuex'
import SampleInput from '@/presentation/components/forms/sample-input.vue'

export default Vue.extend({
  name: 'DrawinsStepsList',
  components: {
    DrawinsStepsCard,
    SampleInput
  },
  data() {
    return {
      stepsSettings: {
        image: {
          field: 'designerId',
          name: '',
          widthClass: 'lg:w-12',
          isImage: true,
          show: true,
          date: false,
          textField: false
        },
        designerName: {
          field: 'designerId',
          name: 'Desenhista',
          key: 'name',
          widthClass: 'lg:w-40'
        },
        freelancer: {
          field: 'designerId',
          name: 'Freelancer',
          key: 'roles',
          widthClass: 'lg:w-32'
        },
        stepName: {
          field: 'name',
          name: 'Etapa',
          key: '',
          widthClass: 'lg:w-40'
        },
        startDate: {
          field: 'startDate',
          name: 'Data inicio',
          widthClass: 'lg:w-32',
          date: true
        },
        endDate: {
          field: 'endDate',
          name: 'Data termino',
          widthClass: 'lg:w-32',
          date: true
        },
        needCustomer: {
          field: 'needCustomerApprovation',
          name: 'Cliente aprovou',
          widthClass: 'lg:w-10'
        },
        status: { field: 'status', name: 'status', widthClass: 'lg:w-40' },
        actions: { field: 'actions', name: 'acoes', widthClass: 'lg:w-10' }
      },
      codeActiveStep: null,
      observation: null,
      measures: ''
    }
  },
  methods: {
    setActiveStep(event: any) {
      this.codeActiveStep = event.params.stepCode
    },
    clean() {
      this.$modal.hide('modal-leave-step')
      this.observation = null
      this.measures = ''
    },
    leaveStep() {
      this.setDrawinStep({
        id: this.activeDrawin.id,
        params: {
          stepCode: this.codeActiveStep,
          observation: this.observation,
          type: 'leave'
        }
      })
      this.clean()
    },
    designerFinish() {
      this.designerFinishStep({
        id: this.activeDrawin.id,
        params: {
          stepCode: this.codeActiveStep,
          observation: this.observation,
          measures: this.measures
        }
      })
      this.clean()
    },
    ...mapActions({
      setDrawinStep: 'setDrawinStep',
      designerFinishStep: 'designerFinishStep'
    })
  },
  computed: {
    ...mapGetters({ activeDrawin: 'activeDrawin' })
  }
})
</script>
