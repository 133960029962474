<script lang="ts">
import Vue from 'vue'
import { ILogin } from '@/domain/auth/login'
import { IHttpResponse } from '@/domain/models/api-responses'
import SampleInput from '@/presentation/components/forms/sample-input.vue'
import FormTextHeader from '@/presentation/components/forms/form-header.vue'
import SendFormButton from '@/presentation/components/forms/send-form-button.vue'
import LinkButton from '@/presentation/components/buttons/link-router-button.vue'
import { Token } from '@/presentation/helpers/token-local-storage'
import { Toastr } from '@/presentation/helpers/toastr-configs'

export default Vue.extend({
  name: 'LoginPage',
  components: {
    SampleInput,
    SendFormButton,
    LinkButton,
    FormTextHeader
  },
  props: {
    login: {
      type: Object as () => ILogin,
      required: true
    }
  },
  data () {
    return {
      email: '',
      password: '',
      values: {}
    }
  },
  methods: {
    async onSubmit (data: { email: string, password: string }) {
      try {
        const response = await this.login.auth(data)
        Token.setToken(response.data[0])
        this.$router.push('portal')
      } catch (error) {
          Toastr.errorTopRight(this, (error as IHttpResponse<any>).errorMessage )
      }
    }
  }
})
</script>

<template>
  <div class="w-full max-w-xs bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mx-2 animate__animated animate__fadeIn">
    <FormTextHeader title="lucin" />
    <FormulateForm values="values" @submit="onSubmit">
        <SampleInput label="E-mail" name="email" validation="^required|email" placeholder="informe seu e-mail" />
        <SampleInput type="password" label="Senha" name="password" validation="^required" placeholder="informe sua senha" />
        <div class="w-full mb-1">
           <SendFormButton class="mt-4" text="LOGIN"/>
           <LinkButton class="text-red-500 block w-full  text-center  " to="/forgot_password" text="Recuperar senha"/>
        </div>
        <div class="mt-4  -mb-6 pb-1 text-center">
          <span class="inline-block mt-2 text-sm mr-1"> Não tem uma conta? </span>
          <LinkButton class="text-blue-500" to="/signup" text="Clique aqui"/>
        </div>
    </FormulateForm>
  </div>
</template>
