import { Token } from '@/presentation/helpers/token-local-storage'
export const meStoreFactory = () => ({
  state(): object {
    return {
      me: {
        avatarUrl: ''
      }
    }
  },
  getters: {
    myAvatarUrl(state: any) {
      return state.me.avatarUrl
    }
  },
  mutations: {
    SET_NEW_AVATAR(state: any, payload: string) {
      state.me.avatarUrl = payload
    }
  },
  actions: {
    async updateAvatarUrl(context: any, payload: { avatarUrl: string }) {
      const { avatarUrl } = payload
      if (!avatarUrl) {
        return
      }
      context.commit('SET_NEW_AVATAR', avatarUrl)
    }
  }
})
