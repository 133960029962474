import { ISignupManagement } from '@/domain/admin/signup-management/signup-management'
import { IHttpClient } from '@/data/protocols/httpClient'
import { UserModel } from '@/domain/models/user-model'
import { IHttpResponse } from '@/domain/models/api-responses'

export class SignupManagement implements ISignupManagement {
  constructor (
    private readonly http: IHttpClient<UserModel>,
    private readonly token: string,
    private readonly baseUrl: string
  ) { }

  async list (page: number, size: number = 10): Promise<IHttpResponse<UserModel>> {
    const response = await this.http.request({
      method: 'get',
      headers: { Authorization: `Bearer ${this.token}` },
      url: `${this.baseUrl}/pendings`,
      params: { pageNumber: page, pageSize: size }
    })

    const { errorMessage, statusCode } = response

    if (response.error) {
      throw { errorMessage, statusCode }
    }

    const { data, pagesLimit, dataCount } = response

    return {
      data,
      pagesLimit,
      dataCount
    }
  }

  async reprobate (usersEmails: string[]): Promise<IHttpResponse<any>> {
    const response = await this.http.request({
      method: 'put',
      headers: { Authorization: `Bearer ${this.token}` },
      url: `${this.baseUrl}/reprobate`,
      body: {
        usersEmails
      }
    })

    const { errorMessage, statusCode } = response

    if (response.error) {
      throw { errorMessage, statusCode }
    }

    return { statusCode }
  }

  async approves (usersEmails: string[]): Promise<IHttpResponse<any>> {
    const response = await this.http.request({
      method: 'put',
      headers: { Authorization: `Bearer ${this.token}` },
      url: `${this.baseUrl}/approves`,
      body: {
        usersEmails
      }
    })

    const { errorMessage, statusCode } = response

    if (response.error) {
      throw { errorMessage, statusCode }
    }

    return { statusCode }
  }
}
