/* eslint-disable @typescript-eslint/no-unused-vars-experimental */
import WrapperWithNavigation from '@/presentation/components/_layout/wrapper-with-navigation.vue'
import WrapperWithoutNavigation from '@/presentation/components/_layout/wrapper-without-navigation.vue'
import { Token } from '@/presentation/helpers/token-local-storage'
import { signupStoreFactory } from '@/store/admin/signup-management/signup-store'
import { basesStoreFactory } from '@/store/bases/bases-store'
import { developStoreFactory } from '@/store/develop/develop-store'
import { drawinStoreFactory } from '@/store/drawin/drawin-store'
import store from '@/store/index'
import NProgress from 'nprogress'
import Vue, { h } from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import { axiosFactory } from '../factories/http/axios-factory'
import { SignupManagementFactory } from '../factories/pages/admin/list-signups-factory'
import { ForgotPasswordFactory } from '../factories/pages/auth/forgot-password-factory'
import { LoginFactory } from '../factories/pages/auth/login-page-factory'
import { RecoverPasswordFactory } from '../factories/pages/auth/recover-password-factory'
import { SignUpFactory } from '../factories/pages/auth/signup-page-factory'
import { developFactory } from '../factories/pages/develop/develop-factory'
import { drawinReportHttpFactory } from '../factories/pages/reports/drawins/drawins-report'
import { basesManagementFactory } from '../factories/pages/style/bases/base-http-factory'
import { drawinStepsHttpFactory } from '../factories/pages/style/drawin-steps/drawin-steps'
import { drawinHttpFactory } from '../factories/pages/style/drawin/drawin-http-factory'
import { customersHttpFactory } from '../factories/pages/users/customers'
import { designersHttpFactory } from '../factories/pages/users/designers'
import { meHttpFactory } from '../factories/pages/users/me'
import { freelancerHttpFactory } from '../factories/pages/freelancers/freelancer-http-factory'

import { configHttpFactory } from '../factories/pages/configs/config-factory'
import { colorsHttpFactory } from '../factories/pages/colors/colors-http-factory'

NProgress.configure({ showSpinner: false })

Vue.use(VueRouter)

const http = axiosFactory()
const freelancerHttp = freelancerHttpFactory(http)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: '',
    component: WrapperWithoutNavigation,
    children: [
      {
        path: '',
        component: () => import('@/presentation/pages/auth/login-page.vue'),
        props: { login: LoginFactory(http) }
      },
      {
        path: 'signup',
        component: () => import('@/presentation/pages/auth/signup-page.vue'),
        props: { signUp: SignUpFactory(http) }
      },
      {
        path: 'forgot_password',
        component: () =>
          import('@/presentation/pages/auth/forgot-password-page.vue'),
        props: { forgotPassword: ForgotPasswordFactory(http) }
      },
      {
        path: 'reset_password',
        component: () =>
          import('@/presentation/pages/auth/reset-password-page.vue'),
        props: { recoverPassword: RecoverPasswordFactory(http) }
      }
    ]
  },
  {
    path: '/portal',
    name: '',
    component: WrapperWithNavigation,
    beforeEnter(to, from, next) {
      const token = Token.getToken()

      if (!token) {
        return next('/')
      }

      if (!store.hasModule('drawinStore')) {
        store.registerModule(
          'drawinStore',
          drawinStoreFactory(
            drawinHttpFactory(http),
            drawinStepsHttpFactory(http)
          )
        )
      }

      if (!store.hasModule('basesStore')) {
        store.registerModule(
          'basesStore',
          basesStoreFactory(basesManagementFactory(http))
        )
      }

      if (!store.hasModule('developStore')) {
        store.registerModule(
          'developStore',
          developStoreFactory(developFactory(http))
        )
      }

      next()
    },
    children: [
      {
        path: '/',
        component: () =>
          import('@/presentation/pages/workspace/workspace-page.vue'),
        props: true,
        async beforeEnter(to, from, next) {
          NProgress.start()
          store.hasModule('drawin')
          to.params.reportsHttp = drawinReportHttpFactory(http) as any
          to.params.drawinsReportsHttp = drawinReportHttpFactory(http) as any
          NProgress.done()
          next()
        }
      },
      {
        path: 'perfil/editar',
        component: () =>
          import('@/presentation/pages/profile/edit-user-profile.vue'),
        props: { me: meHttpFactory(http) }
      },
      {
        path: 'admin/signup',
        component: () =>
          import('@/presentation/pages/admin/signup-management-page.vue'),
        props: { signup: SignupManagementFactory(http) },
        beforeEnter(to, from, next) {
          const validRole = Token.validRole('administrator', 'manager')
          if (!validRole) {
            return next('/')
          }
          if (!store.hasModule('signupStore')) {
            store.registerModule(
              'signupStore',
              signupStoreFactory(SignupManagementFactory(http))
            )
          }
          next()
        }
      },
      {
        path: 'estilo/bases',
        component: () =>
          import('@/presentation/pages/style/base/master/base-list-page.vue'),
        async beforeEnter(to, from, next) {
          const validRole = Token.validRole(
            'designer',
            'adminstrator',
            'manager'
          )
          if (!validRole) {
            Token.removeToken()
            return next('/')
          }

          NProgress.start()
          await store.dispatch('loadBases', { pageNumber: 1, pageSize: 5 })
          NProgress.done()
          next()
        }
      },
      {
        path: 'estilo/bases/view/:code',
        component: () =>
          import('@/presentation/pages/style/base/detail/base-detail-page.vue'),
        props: true,
        async beforeEnter(to, from, next) {
          NProgress.start()
          const response = await store.dispatch(
            'loadBaseByCode',
            to.params.code
          )
          const [base] = response.data
          if (base.tags !== undefined && base.tags !== null) {
            base.tags = base.tags.join(' ')
          }
          to.params.mode = 'edit'
          to.params.activeBase = base
          to.params.baseId = base.id
          NProgress.done()
          next()
        }
      },
      {
        path: 'estilo/bases/cadastro',
        component: () =>
          import('@/presentation/pages/style/base/detail/base-detail-page.vue')
      },
      {
        path: 'estilo/desenhos',
        props: true,
        component: () =>
          import(
            '@/presentation/pages/style/drawins/master/drawins-list-page2.vue'
          ),
        async beforeEnter(to, from, next) {
          if (Token.validRole('freelancer')) {
            return next('/')
          }
          to.params.drawinsHttp = drawinHttpFactory(http) as any
          to.params.reportsHttp = drawinReportHttpFactory(http) as any

          next()
        }
      },
      {
        path: 'estilo/desenhos/cadastro',
        props: {
          customersHttp: customersHttpFactory(http),
          configsHttp: configHttpFactory(http),
          colorsHttp: colorsHttpFactory(http)
        },
        component: () =>
          import(
            '@/presentation/pages/style/drawins/detail/drawin-detail-page.vue'
          )
      },
      {
        path: 'estilo/desenhos/editar/:id',
        props: true,
        component: () =>
          import(
            '@/presentation/pages/style/drawins/detail/drawin-detail-page.vue'
          ),
        async beforeEnter(to, from, next) {
          if (!Token.validRole('manager', 'administrator')) {
            return next('/')
          }
          NProgress.start()
          const response = await store.dispatch('loadDrawinById', to.params.id)
          to.params.activeDrawin = response
          to.params.mode = 'edit'
          to.params.customersHttp = customersHttpFactory(http) as any
          to.params.configsHttp = configHttpFactory(http) as any
          to.params.colorsHttp = colorsHttpFactory(http) as any
          NProgress.done()
          next()
        }
      },
      {
        path: 'estilo/desenhos/acervo/:id',
        props: true,
        component: () =>
          import(
            '@/presentation/pages/style/drawins/detail/drawin-detail-page.vue'
          ),
        async beforeEnter(to, from, next) {
          if (!Token.validRole('manager', 'administrator')) {
            return next('/')
          }
          NProgress.start()
          const response = await store.dispatch('loadDrawinById', to.params.id)
          to.params.activeDrawin = response
          to.params.mode = 'collection'
          to.params.customersHttp = customersHttpFactory(http) as any
          to.params.configsHttp = configHttpFactory(http) as any
          to.params.colorsHttp = colorsHttpFactory(http) as any
          NProgress.done()
          next()
        }
      },
      {
        path: 'estilo/desenhos/:id',
        props: true,
        component: () =>
          import(
            '@/presentation/pages/style/drawins/steps/drawin-steps-base-page.vue'
          ),
        children: [
          {
            path: 'arte',
            props: true,
            component: () =>
              import(
                '@/presentation/pages/style/drawins/steps/drawin-steps-page.vue'
              )
          },
          {
            path: 'configurar',
            props: true,
            component: () =>
              import(
                '@/presentation/pages/style/drawins/steps/drawins-steps-settings-page.vue'
              ),
            beforeEnter(to, from, next) {
              if (!Token.validRole('manager', 'administrator')) {
                return next('/')
              }
              to.params.designerHttp = designersHttpFactory(
                axiosFactory()
              ) as any
              to.params.drawinHttp = drawinHttpFactory(http) as any
              next()
            }
          }
        ],
        async beforeEnter(to, from, next) {
          NProgress.start()
          const response = await store.dispatch('loadDrawinById', to.params.id)
          to.params.activeDrawin = response
          to.params.mode = 'edit'
          to.params.customersHttp = customersHttpFactory(http) as any
          to.params.drawinHttp = drawinHttpFactory(http) as any
          NProgress.done()
          next()
        }
      },
      {
        path: 'estilo/developer',
        component: () =>
          import(
            '@/presentation/pages/style/developer/master/developer-page.vue'
          ),
        props: true,
        async beforeEnter(to, from, next) {
          if (Token.validRole('freelancer')) {
            return next('/')
          }
          NProgress.start()
          await store.dispatch('loadDevelop', { pageSize: 6, pageNumber: 1 })
          NProgress.done()
          next()
        }
      },
      {
        path: 'estilo/freelancer',
        component: () =>
          import('@/presentation/pages/reports/freelancer-management-page.vue'),
        props: true,
        async beforeEnter(to, from, next) {
          NProgress.start()
          const validRole = Token.validRole('administrator', 'manager')
          if (!validRole) {
            return next('/')
          }
          to.params.freelancerHttp = freelancerHttp as any
          to.params.drawinHttp = drawinHttpFactory(http) as any
          NProgress.done()
          next()
        }
      },
      {
        path: 'estilo/relatorios',
        component: () => import('@/presentation/pages/reports/report-page.vue'),
        props: {
          reportHttp: drawinReportHttpFactory(http),
          customersHttp: customersHttpFactory(http)
        },
        async beforeEnter(to, from, next) {
          const validRole = Token.validRole('administrator', 'manager')
          if (!validRole) {
            return next('/')
          }
          next()
        }
      },
      {
        path: 'estilo/clientes',
        component: () => import('@/presentation/pages/style/customer-page.vue'),
        props: {
          customersHttp: customersHttpFactory(http),
          usersHttp: designersHttpFactory(http)
        },
        async beforeEnter(to, from, next) {
          const validRole = Token.validRole('administrator', 'manager')
          if (!validRole) {
            return next('/')
          }
          next()
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
