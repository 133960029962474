var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('div',{staticClass:"flex flex-col w-full"},[_c('div',{staticClass:"flex w-full mb-4 justify-between gap-2"},[_c('div',{staticClass:"flex gap-2 w-full text-sm"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.searchText),expression:"searchText"}],staticClass:"w-full border rounded pl-4 py-1 focus:border-2 focus:border-blue-500 focus:outline-none",attrs:{"type":"text","placeholder":"Pesquisa rápida (código, nome do desenho ou nome do cliente)","name":"","id":""},domProps:{"value":(_vm.searchText)},on:{"input":[function($event){if($event.target.composing)return;_vm.searchText=$event.target.value},function($event){return _vm.onQuickSearch($event.target.value)}]}}),_c('span',{staticClass:"flex gap-2 border bg-white py-2 px-4 items-center justify-between rounded-md cursor-pointer hover:bg-gray-100",class:{
            ' border-blue-500  text-blue-500': _vm.queryString !== ''
          },on:{"click":function($event){_vm.showFilters = !_vm.showFilters}}},[_c('span',[_vm._v("Filtros ")]),(_vm.queryString === '')?_c('i',{staticClass:"fas fa-sliders-h"}):_c('i',{staticClass:"fas fa-times text-red-700 cursor-pointer",on:{"click":function($event){return _vm.onFilterFreelancer('')}}})]),_c('button',{staticClass:"h-10 px-2 rounded py-1 text-sm whitespace-no-wrap",class:{
            'bg-gray-300 text-gray-500 cursor-not-allowed':
              _vm.selectedDrawins.length <= 0,
            'bg-green-100 text-green-700 cursor-pointer  border-green-300 hover:bg-green-300 focus:outline-none':
              _vm.selectedDrawins.length > 0
          },attrs:{"disabled":_vm.selectedDrawins.length <= 0},on:{"click":function($event){return _vm.sendBulkMarkAsPaid()}}},[_c('i',{staticClass:"fas fa-check p-1"}),_vm._v(" Marcar como pago ")]),_c('button',{staticClass:"h-10 px-2 rounded py-1 text-sm whitespace-no-wrap",class:{
            'bg-gray-300 text-gray-500 cursor-not-allowed':
              _vm.selectedDrawins.length <= 0,
            'bg-blue-100 text-blue-700 cursor-pointer  border-blue-300 hover:bg-blue-200 focus:outline-none':
              _vm.selectedDrawins.length > 0
          },attrs:{"disabled":_vm.selectedDrawins.length <= 0},on:{"click":function($event){return _vm.sendBulkReport()}}},[_c('i',{staticClass:"fas fa-file-alt p-1"}),_vm._v(" Enviar relatório ")])])]),_c('div',{staticClass:"self-center overflow-x-auto border w-full text-sm rounded-lg border-gray-400"},[_c('div',{staticClass:"inline-block min-w-full align-middle"},[_c('div',{staticClass:"overflow-hidden"},[_c('table',{staticClass:"min-w-full divide-y divide-gray-300 bg-white"},[_vm._m(0),_c('tbody',{staticClass:"bg-white divide-y divide-gray-200 text-gray-800"},_vm._l((_vm.freelancerDrawin),function(freelancer){return _c('tr',{key:freelancer.id,staticClass:"hover:bg-gray-100 cursor-pointer",on:{"click":function($event){return _vm.setActiveFreelancer(freelancer)}}},[_c('td',{staticClass:"py-3 px-6 text-sm",on:{"click":function($event){$event.stopPropagation();}}},[_c('SampleCheckbox',{staticClass:"text-blue-500",attrs:{"label":""},on:{"chkToggle":function($event){return _vm.selectFreelancer(freelancer.id)}}})],1),_c('td',{staticClass:"py-3 px-6 text-sm hidden lg:inline"},[_c('img',{staticClass:"rounded-lg w-10 h-10",attrs:{"width":"200","height":"200","src":freelancer.imageUrl ||
                        require('../../assets/images/no_image.png'),"alt":""}})]),_c('td',{staticClass:"py-3 px-6 text-sm capitalize whitespace-nowrap"},[_vm._v(" "+_vm._s(freelancer.lucinRef || '-')+" ")]),_c('td',{staticClass:"py-3 px-6 text-sm cap whitespace-nowrap"},[_vm._v(" "+_vm._s(freelancer.name || '-')+" ")]),_c('td',{staticClass:"py-3 px-6 text-sm capitalize"},[(
                      new Set(
                        freelancer.steps
                          .filter(s => s.designerId?.name)
                          .map(s => s.designerId?.name)
                      ).size > 1
                    )?_c('span',_vm._l((freelancer.steps),function(steps,index){return _c('img',{staticClass:"rounded-full border border-gray-300 w-10 h-10 inline-block hidden md:inline-block",class:{
                        '-ml-3': index > 0
                      },attrs:{"width":"200","height":"200","src":steps.designerId?.avatarUrl ||
                          require('../../assets/images/no_avatar.png'),"alt":""}})}),0):_c('img',{staticClass:"rounded-full border capitalize hidden md:inline-block border-gray-300 w-10 h-10",attrs:{"width":"200","height":"200","src":freelancer.steps[0]?.designerId?.avatarUrl ||
                        require('../../assets/images/no_avatar.png'),"alt":""}}),_vm._v(" "+_vm._s(new Set( freelancer.steps .filter(s => s.designerId?.name) .map(s => s.designerId?.name) ).size == 1 ? freelancer.steps[0].designerId.name.split(' ')[0] : '+1 freelancer')+" ")]),_c('td',{staticClass:"py-3 px-6 text-sm capitalize whitespace-nowrap"},[_vm._v(" "+_vm._s(new Date(freelancer.registerDate).toLocaleDateString( 'pt-BR' ) || new Date(freelancer.createdAt).toLocaleDateString( 'pt-BR' ) || '-')+" ")]),_c('td',{staticClass:"py-3 px-6 text-sm capitalize whitespace-nowrap text-gray-700 font-semibold"},[_vm._v(" R$"+_vm._s(freelancer.steps .reduce((p, c) => c.freelancerPrice + p, 0) .toFixed(2) .replace('.', ',') || new Number().toFixed(2))+" ")]),_c('td',{staticClass:"py-3 px-6 text-sm capitalize whitespace-nowrap"},[_c('span',{staticClass:"text-yellow-600",class:{
                      'text-green-600': freelancer.freelancerPaid
                    }},[_c('i',{staticClass:"fas",class:{
                        'fa-check': freelancer.freelancerPaid,
                        'fa-clock': !freelancer.freelancerPaid
                      }}),_vm._v(" "+_vm._s(freelancer.freelancerPaid ? 'Pago' : 'Pendente'))])]),_c('td',{staticClass:"py-3 px-6 text-sm whitespace-nowrap"},[_c('div',{staticClass:"w-full lg:w-24 flex flex-col self-center lg:self-auto mt-2 lg:mt-0"},[_c('div',{staticClass:"flex bg-gray-200 rounded-lg relative h-4"},[_c('div',{staticClass:"cursor-default bg-green-400 text-xs font-bold leading-none flex items-center rounded-lg justify-center py-1 text-center text-white",style:({
                          width:
                            _vm.getFreelancerDrawinProgress(freelancer) * 100 +
                            '%'
                        })}),_c('div',{staticClass:"absolute w-full h-full text-xs font-semibold text-gray-800 justify-center text-center"},[_vm._v(" "+_vm._s(_vm.getFreelancerDrawinProgress(freelancer) > 0 ? ( _vm.getFreelancerDrawinProgress(freelancer) * 100 ).toFixed(0) + '%' : '0%')+" ")])])])])])}),0)])])])])]),(_vm.editMode)?_c('div',{staticClass:"bg-white fixed z-50 h-screen top-0 right-0 w-full lg:w-1/4 border-l border-gray-400 leading-6 font-euclid"},[_c('div',{staticClass:"flex flex-col h-full w-full overflow-y-auto"},[_c('div',{staticClass:"flex justify-between border-b border-1 py-4 px-6 font-semibold text-gray-700 mb-8"},[_c('span',{},[_vm._v("Gerenciar Freelancer")]),_c('span',{staticClass:"pl-4"},[_c('i',{staticClass:"fas fa-times cursor-pointer transition duration-500 ease-in-out hover:border-red-600 hover:text-red-600",on:{"click":function($event){_vm.editMode = !_vm.editMode}}})])]),_c('div',{staticClass:"flex flex-col py-6 flex-grow w-full p-4"},[_c('div',{staticClass:"flex flex-col items-center w-full justify-center mb-6"},[_c('img',{staticClass:"rounded-lg h-40 w-40 object-cover",attrs:{"src":_vm.activeFreelancer.imageUrl ||
                require('../../assets/images/no_image.png')}}),_c('span',{staticClass:"p-4"},[_vm._v(" "+_vm._s(_vm.activeFreelancer.name || _vm.activeFreelancer.lucinRef || '-')+" "),_c('i',{staticClass:"p-2 fas fa-external-link-alt text-sm cursor-pointer text-blue-600",on:{"click":function($event){return _vm.goToDrawin(_vm.activeFreelancer.id)}}})])]),_vm._l((_vm.activeFreelancer.steps),function(step){return _c('div',{staticClass:"flex flex-col border-t w-full mt-2"},[_c('div',{staticClass:"flex"},[_vm._m(1,true),_c('div',{staticClass:"flex flex-col justify-between border-r w-full text-xs capitalize"},[_c('span',{staticClass:"p-2 border-b"},[_vm._v(" "+_vm._s(step.designerId.name)+" ")]),_c('span',{staticClass:"p-2 border-b"},[_vm._v(" "+_vm._s(step.name)+" ")]),_c('span',{staticClass:"p-2 border-b"},[_vm._v(" "+_vm._s(step.startDate ? new Date(step.startDate).toLocaleDateString('pt-br') : '-')+" ")]),_c('span',{staticClass:"p-2 border-b"},[_vm._v(" "+_vm._s(step.endDate ? new Date(step.endDate).toLocaleDateString('pt-br') : '-')+" ")]),_c('span',{staticClass:"p-2 border-b"},[_vm._v(" "+_vm._s(step.status)+" ")]),_c('span',{staticClass:"p-2 border-b font-bold"},[_vm._v(" R$ "+_vm._s(step.freelancerPrice?.toFixed(2) || 0)+" ")])])])])}),_c('div',{staticClass:"self-end p-4"},[_vm._v(" Total: R$ "+_vm._s(_vm.activeFreelancer.steps ?.reduce((p, c) => { if (!c.freelancerPrice) { return 0 } return c.freelancerPrice + p }, 0) ?.toFixed(2) || 0)+" ")]),_c('div',{staticClass:"flex mt-4"},[_c('button',{staticClass:"bg-white border border-green-400 px-2 rounded py-1 text-sm mr-2 self-end float-right inline hover:bg-green-200 focus:outline-none",class:{
              'bg-green-200 cursor-not-allowed':
                _vm.activeFreelancer.freelancerPaid
            },attrs:{"disabled":_vm.activeFreelancer.freelancerPaid},on:{"click":function($event){return _vm.markAsPaid(_vm.activeFreelancer)}}},[_c('i',{staticClass:"fas fa-check text-green-800 p-1"}),_vm._v(_vm._s(_vm.activeFreelancer.freelancerPaid ? 'Pago' : 'Marcar como pago')+" ")]),_c('button',{staticClass:"bg-white border border-blue-400 px-2 rounded py-1 text-sm mr-auto self-end float-right inline hover:bg-blue-200 focus:outline-none",on:{"click":function($event){return _vm.sendReport(_vm.activeFreelancer)}}},[_c('i',{staticClass:"fas fa-file-alt text-blue-600 p-1"}),_vm._v(" Gerar relatório ")])])],2),_c('div',{staticClass:"sticky z-50 bottom-0 w-full bg-white border-t border-1 py-4 px-6 flex justify-end",attrs:{"id":"footer"}},[_c('span',{staticClass:"border px-2 py-1 rounded-md cursor-pointer transition duration-500 ease-in-out hover:border-red-600 hover:text-red-600",on:{"click":function($event){_vm.editMode = !_vm.editMode}}},[_vm._v("Fechar")])])])]):_vm._e(),(_vm.editMode)?_c('div',{staticClass:"mask absolute w-full bg-gray-500 z-40 opacity-50 h-screen inset-0 cursor-pointer",attrs:{"tabindex":"2"},on:{"click":function($event){_vm.editMode = false}}}):_vm._e(),_c('KeepAlive',[(_vm.showFilters)?_c('DrawinFilterSideBar',{attrs:{"showExcelButton":false,"title":"Filtros de Freelancer","freelancerContext":true},on:{"opened":function($event){_vm.showFilters = $event},"onFilter":_vm.onFilterFreelancer,"onGenerateDrawinReport":function($event){}}}):_vm._e()],1),_c('Pagination',{staticClass:"mb-4",attrs:{"pageNumber":_vm.pageNumber,"pageLimit":_vm.pagesLimit},on:{"previous":function($event){return _vm.onPaginate($event)},"next":function($event){return _vm.onPaginate($event)}}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('thead',{staticClass:"bg-gray-100"},[_c('tr',[_c('th',{staticClass:"py-3 px-6 text-sm tracking-wider text-center uppercase",attrs:{"scope":"col"}}),_c('th',{staticClass:"hidden lg:inline py-3 px-6 text-sm tracking-wider text-center uppercase",attrs:{"scope":"col"}}),_c('th',{staticClass:"py-3 px-6 text-sm tracking-wider text-left uppercase",attrs:{"scope":"col"}},[_vm._v(" Lucin Ref ")]),_c('th',{staticClass:"py-3 px-6 text-sm tracking-wider text-left uppercase",attrs:{"scope":"col"}},[_vm._v(" Desenho ")]),_c('th',{staticClass:"py-3 px-6 text-sm tracking-wider text-left uppercase",attrs:{"scope":"col"}},[_vm._v(" Freelancer ")]),_c('th',{staticClass:"py-3 px-6 text-sm tracking-wider text-left uppercase",attrs:{"scope":"col"}},[_vm._v(" Data desenho ")]),_c('th',{staticClass:"py-3 px-6 text-sm tracking-wider text-left uppercase",attrs:{"scope":"col"}},[_vm._v(" Valor (R$) ")]),_c('th',{staticClass:"py-3 px-6 text-sm tracking-wider text-left uppercase",attrs:{"scope":"col"}},[_vm._v(" Pagamento ")]),_c('th',{staticClass:"py-3 px-6 text-sm tracking-wider text-left uppercase",attrs:{"scope":"col"}},[_vm._v(" Progresso ")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"flex flex-col font-semibold justify-between bg-gray-100 border-l border-r w-full text-xs"},[_c('span',{staticClass:"p-2 border-b"},[_vm._v(" Freelancer ")]),_c('span',{staticClass:"p-2 border-b"},[_vm._v(" Step ")]),_c('span',{staticClass:"p-2 border-b"},[_vm._v(" Inicio ")]),_c('span',{staticClass:"p-2 border-b"},[_vm._v(" Termino ")]),_c('span',{staticClass:"p-2 border-b"},[_vm._v(" Status ")]),_c('span',{staticClass:"p-2 border-b"},[_vm._v(" R$ ")])])
}]

export { render, staticRenderFns }