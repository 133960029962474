<template>
  <div class="flex  w-full justify-between ">
    <div class=" flex gap-1 transition delay-700 ">
      <span
        @click.stop="openOptionMenu(drawin.id)"
        class="  rounded-full -mx-3 -mt-1 w-8 h-8 flex items-center justify-center hover:bg-gray-300 cursor-pointer"
      >
        <svg
          class="w-6 h-6 "
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            stroke="currentColor"
            stroke-linecap="round"
            stroke-width="3"
            d="M12 6h.01M12 12h.01M12 18h.01"
          />
        </svg>
      </span>
      <div
        id="dropdown-menu"
        v-if="drawinIdToOptionMenu === drawin.id"
        class="opacity-100  absolute  border rounded-lg  w-48  bg-white  group-hover:block "
        :class="{
          'left-0 ml-2': position === 'left',
          'right-0 mr-2': position === 'right'
        }"
      >
        <div
          class="py-2 p-2   hover:bg-gray-100 "
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="dropdown-button"
        >
          <button
            @click.stop="opendDrawinEditPage(drawin.id)"
            class="self-center w-full py-2 text-base font-medium  text-gray-900 focus:outline-none"
          >
            Editar
          </button>
        </div>

        <div
          class="py-2 p-2   hover:bg-gray-100 "
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="dropdown-button"
        >
          <button
            @click.stop="onGenerateRecordFile(drawin.id)"
            class="self-center w-full py-2 text-base font-medium  text-gray-900 focus:outline-none"
          >
            Ficha
          </button>
        </div>

        <div
          class=" py-2 p-2 hover:bg-gray-100"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="dropdown-button"
          v-if="!drawin.developerApproved"
        >
          <button
            @click.stop="onDeveloperApprove(drawin.id, index)"
            class="self-center w-full py-2 text-base font-medium text-gray-900 focus:outline-none"
          >
            Liberar developer
          </button>
        </div>
        <div
          class="py-2 p-2   hover:bg-gray-100"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="dropdown-button"
        >
          <button
            v-if="drawin.canceled"
            @click.stop="onReactivateDrawin(drawin.id, index)"
            class="self-center w-full py-2 text-base font-medium text-green-700 focus:outline-none"
          >
            Reativar desenho
          </button>
          <button
            v-else
            @click.stop="onCancelDrawin(drawin.id, index)"
            class="self-center w-full py-2 text-base font-medium text-red-700 focus:outline-none"
          >
            Cancelar desenho
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
import { DrawinModel } from '@/domain/models/drawins/drawin-model'

export default Vue.extend({
  name: 'DrawinOptionsMenu',
  props: {
    drawin: {
      type: Object as () => DrawinModel,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    position: {
      type: String,
      default: 'left'
    }
  },
  data() {
    return {
      purchaseStatusMap: new Map([
        ['pending', 'Não comprado'],
        ['canceled', 'Não comprado'],
        ['purchased', 'Comprado']
      ]),
      drawinIdToOptionMenu: ''
    }
  },
  methods: {
    resetOptionMenu(event: Event) {
      if (event.target !== document.getElementById('dropdown-menu')) {
        this.drawinIdToOptionMenu = ''
        this.$emit('onOpenOptionMenu', false)
      }
    },
    opendDrawinEditPage(drawinId: string) {
      window.open(`desenhos/editar/${drawinId}`, '_blank')
    },
    openDrawinConfigPage(drawinId: string) {
      window.open(`desenhos/${drawinId}/configurar`, '_blank')
    },
    openOptionMenu(drawinId: string) {
      this.drawinIdToOptionMenu = drawinId
      this.$emit('onOpenOptionMenu', true)
    },
    onGenerateRecordFile(drawinId: string) {
      this.$emit('onGenerateRecordFile', drawinId)
    },
    onCancelDrawin(drawinId: string, index: number) {
      this.$emit('onCancelDrawin', drawinId, index)
    },
    onReactivateDrawin(drawinId: string, index: number) {
      this.$emit('onReactivateDrawin', drawinId, index)
    },
    onDeveloperApprove(drawinId: string, index: number) {
      this.$emit('onDeveloperApproved', drawinId, index)
    }
  },
  mounted() {
    document.addEventListener('click', this.resetOptionMenu)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.resetOptionMenu)
  }
})
</script>
