<template>
<section class="relative mt-2">
  <input :value="text" @input="debounceInput($event.target.value)" type="text" placeholder="Pesquisar..." class="block w-full rounded-full border  bg-white h-10 px-5 pr-16  text-sm focus:border-2 focus:border-blue-500 focus:outline-none">
  <button class="absolute top-0 right-0 mt-2 mr-4 cursor-default focus:outline-none">
    <i class="fas fa-search text-gray-600"></i>
  </button>
</section>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'SearchInput',
  props: {
    text: [String]
  },
  data: function () {
    return {
      timeout: null
    }
  },
  methods: {
    debounceInput (value: string) :void {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$emit('search', value.trim())
      }, 700)
    }
  }
})
</script>
