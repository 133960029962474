<template>
  <section>
    <div v-if="designerFinish" class="reacts show border rounded-lg shadow-sm   bg-white " >
      <!-- <div class="circle" @click="$emit('manager-approvation', itemRef)" v-if="showManagerApprovation">
        <img
          src="https://res.cloudinary.com/prvnbist/image/upload/v1492531171/XZOPuv9_eyqlr2.png"
          alt=""
        />
        <p>Aprovar</p>
      </div> -->
      <div class="circle" @click="$emit('customer-approvation', itemRef)" v-if="showCustomerApprovation">
        <img
          src="https://res.cloudinary.com/prvnbist/image/upload/v1492531172/uIjLl6R_cmbnqb.png"
          alt=""
        />
        <p>Cliente</p>
      </div>
      <div class="circle"  @click="$emit('manager-reprovation', itemRef)" v-if="showManagerReprovation">
        <img
          src="https://res.cloudinary.com/prvnbist/image/upload/v1492531178/VDM3zOV_qy9fqj.png"
          alt=""
        />
        <p>Reprovar</p>
      </div>
      
    <span v-if="!showManagerApprovation && !showCustomerApprovation && !showManagerReprovation" > Não há ações disponiveis </span>
    </div>
  </section>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'EmojisDrawinsSteps',
  props: {
    itemRef: {
      type: String,
      required: true
    },
    designerFinish: {
      default: false
    },
    showManagerApprovation: {
      default: false
    },
    showCustomerApprovation: {
      default: false
    },
    showManagerReprovation: {
      default: true
    }
  }
})
</script>


<style lang="scss">
@mixin object($width, $height, $bg) {
  width: $width;
  height: $height;
  background: $bg;
}

@mixin transPos($top, $right, $bottom, $left, $transX, $transY) {
  position: absolute;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
  transform: translate($transX, $transY);
}

.reacts {
  @include object(150, 20px, #fff);
  // border-radius: 90px;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  height: 3rem;
  padding: 0px 1.2rem;
  align-items: center;
  // box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
  // opacity: 0;
  // overflow: hidden;
  .circle {
    @include object(24px, 24px, #edff97);
    border-radius: 50%;
    transform-origin: 50% 100%;
    animation: slide 0.2s ease-in-out forwards;
    margin-right: 5px;
    cursor: pointer;
    @keyframes slide {
      0% {
        transform: translateY(30px);
        opacity: 0;
      }
    }
    @for $i from 1 through 6 {
      &:nth-child(#{$i}) {
        animation-duration: calc(0.2s * #{$i});
      }
    }
    &:hover {
      transform: scale(1.3);
    }
    &:hover p {
      opacity: 1;
    }
    img {
      width: 100%;
    }
    position: relative;
    p {
      @include transPos(-30px, null, null, 50%, -50%, 0);
      background: rgba(#000, 0.5);
      padding: 4px 8px;
      display: block;
      color: #fff;
      font-size: 10px;
      border-radius: 90px;
      opacity: 0;
    }
  }
}

.like {
  @include object(auto, 40px, #fff);
  @include transPos(null, null, calc(50% - 150px), 50%, -50%, -50%);
  clear: both;
  line-height: 40px;
  padding: 0 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  i,
  p {
    display: inline-block;
    color: #558cff;
    font-size: 18px;
    margin: 0 3px;
  }
  p {
    font-family: Sans-serif;
  }
}



@media screen and (max-width: 576px) {
  .reacts {
    transform: scale(0.7);
  }
}
</style>