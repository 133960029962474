import { getRequest } from '@/data/helper/http-request-decorators'
import { IHttpClient } from '@/data/protocols/httpClient'
import { IHttpResponse } from '@/domain/models/api-responses'
import { UserModel } from '@/domain/models/user-model'
import { IDesigners } from '@/domain/users/designer'

export class Designers implements IDesigners {
  constructor(
    private readonly url: string,
    private readonly token: string,
    private readonly http: IHttpClient<UserModel>
  ) {}

  @getRequest
  async listAllDesigners(
    searchText?: string
  ): Promise<IHttpResponse<UserModel>> {
    return await this.http.request({
      method: 'get',
      url: `${this.url}designers`,
      headers: { Authorization: `Bearer ${this.token}` },
      params: {
        searchText
      }
    })
  }

  @getRequest
  async listAllManagers(
    searchText?: string
  ): Promise<IHttpResponse<UserModel>> {
    return await this.http.request({
      method: 'get',
      url: `${this.url}managers`,
      headers: { Authorization: `Bearer ${this.token}` },
      params: {
        searchText
      }
    })
  }
}
