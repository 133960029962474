<script lang="ts">
import Vue from 'vue'
import SampleInput from '@/presentation/components/forms/sample-input.vue'
import FormTextHeader from '@/presentation/components/forms/form-header.vue'
import SendFormButton from '@/presentation/components/forms/send-form-button.vue'
import LinkButton from '@/presentation/components/buttons/link-router-button.vue'
import { IForgotPassword } from '@/domain/auth/forgot-password'
import { Toastr } from '../../helpers/toastr-configs'

export default Vue.extend({
  name: 'ForgotPasswordPage',
  components: {
    SampleInput,
    FormTextHeader,
    SendFormButton,
    LinkButton
  },
  props: {
    forgotPassword: Object as () => IForgotPassword
  },
  methods: {
    async onSubmit (data: { email : string}) {
      try {
        if (!data.email) {
          return
        }
        const { email } = data
        await this.forgotPassword.send(email)
        this.$formulate.reset('forgotPassword')
        Toastr.successBottomCenter(this, 'Informações para a recuperação de senha foram enviadas para o seu e-mail!')
      } catch (error) {
        Toastr.errorTopRight(this, error)
      }
    }
  }
})
</script>

<template>
  <section class="w-full max-w-xs bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 mx-2 animate__animated animate__fadeIn">
    <FormTextHeader title="Recuperar senha" />
    <FormulateForm name="forgotPassword"  @submit="onSubmit">
      <SampleInput label="E-mail" name="email" validation="^required|email" placeholder="informe seu e-mail" />
      <div class="w-full mb-1">
          <SendFormButton text="ENVIAR"/>
          <LinkButton class="text-red-500 block w-full  text-center  " to="/" text="Voltar"/>
      </div>
    </FormulateForm>
  </section>
</template>
