<script lang="ts">
  import Vue from 'vue'
  import { Bar } from 'vue-chartjs'

  export default Vue.extend({
    name: 'ChartBar',
    extends: Bar,
    props: {
      labels: {
        type: Array,
        required: true
      },
      datasets: {
        required: true,
        type: Array
      }
    },
    mounted() {
      (this as any).renderChart({
      labels: this.labels,
      datasets: this.datasets
    }, {
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        xAxes: [{
          stacked: true,
          barPercentage: 1,
        }],
        yAxes: [{
          stacked: true
        }]
      }
    }
    )
    }
  })
</script>