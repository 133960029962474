<template>
<section class="relative">
  <input :value="text" @input="debounceInput($event.target.value)" type="text" :placeholder="placeholder" class="w-full  px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none">
  <button class="absolute top-0 right-0 mt-2 mr-4 cursor-default focus:outline-none">
    <i class="fas fa-search text-gray-600"></i>
  </button>
</section>
</template>

<script lang="ts">
import Vue from 'vue'

export default Vue.extend({
  name: 'SampleInputSearch',
  props: {
    text: [String],
    placeholder: String
  },
  data: function () {
    return {
      timeout: null
    }
  },
  inheritAttrs: true,
  methods: {
    debounceInput (value: string) :void {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.$emit('search', value.trim())
      }, 700)
    }
  }
})
</script>
