<template>
  <section class="mb-4">
    <label class="block uppercase text-gray-700 text-xs font-bold "> {{ label }} </label>
    <v-select
      class="style-chooser"
      :placeholder="placeholder"
      :options="options"
      :reduce="reduce"
      :filterable="false"
      :value="copyValue"
      label="name"
      id="client"
      name="client"
      @search="search"
      @input="onSelect"
    >
    <template slot="no-options">
     {{ noOptionsMessage }}
    </template>
    <template slot="option" slot-scope="option">
      <div class="">
         {{ option.name }}
      </div>
    </template>
    </v-select>
  </section>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'InputAutoCompleteSelect',
  props: {
    label: String,
    placeholder: String,
    reduce: Function,
    search: Function,
    options: {
      type: Array
    },
    noOptionsMessage: String,
    value: {
      default: null
    }
  },
  data() {
    return {
      copyValue: this.value
    }
  },
  methods: {
    onSelect(event: any) {
      this.copyValue = event
      this.$emit('selected', event)
    }
  }
})
</script>
<style lang="scss" >
    .style-chooser .vs__search::placeholder{
        color: rgba(74, 85, 104, 0.5);
    }

  .style-chooser .vs__dropdown-toggle{
    height: 2.5rem;
    border-color:rgba(74, 85, 104, 0.3);
  }

</style>
