<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'FormTextHeader',
  props: {
    title: String
  }
})
</script>

<template>
  <div class="flex items-center justify-center border-b-2 border-b-gray-300  mb-6">
    <span class="text-center  mb-3 text-2xl uppercase tracking-wide  "> {{ title }} </span>
  </div>
</template>
