import { LucinRoute } from '@/main/configs/routes/routes-paths'
import { IHttpClient } from '@/data/protocols/httpClient'
import { Token } from '@/presentation/helpers/token-local-storage'
import CustomerService from '@/data/customers/customer'
import { Customer } from '@/domain/models/customers/customers'

export const customersHttpFactory = (
  http: IHttpClient<Customer>
): CustomerService => {
  return new CustomerService(LucinRoute.customers, Token.getToken(), http)
}
