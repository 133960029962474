<template>
  <div class="flex flex-col mx-2 xl:flex-row h-60 ">
    <div
      class="bg-white min-h-60  w-full xl:w-1/6  mt-8  xl:mt-0   rounded-lg border overflow-auto xl:mr-4"
    >
      <div
        class="bg-gray-100    rounded-tr-lg rounded-tl-lg  flex justify-center w-full p-4"
      >
        <img
          src="../../assets/svgs/illustrations/date_picker.svg"
          alt=""
          srcset=""
          class="h-40 w-full"
          loading="lazy"
        />
      </div>
      <div class="bg-white flex flex-col justify-center w-full p-4 ">
        <span
          class=" text-center  font-bold uppercase font-mono text-gray-700 mb-2 h-10"
        >
          Cliente x Qtd. Desenhos x Tipo
        </span>
        <span class="text-xs mb-2 font-thin text-gray-700 h-10 py-2">
          Relatório de quantidades de desenhos por cliente e tipo de estampa.
        </span>
        <label class="block uppercase text-gray-700 text-xs font-bold mt-4">
          Cliente
        </label>
        <v-select
          class="style-chooser"
          placeholder="cliente"
          :options="listCustomers"
          :filterable="false"
          :value="drawinQtyType.customerName"
          label="name"
          id="client"
          name="client"
          @search="listAllCustomers"
          @input="setCustomerName(drawinQtyType, $event)"
        >
          <template slot="no-options">
            Informe o NOME do cliente, se o cadastro existir, buscaremos pra
            você :)
          </template>
          <template slot="option" slot-scope="option">
            <div class="">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <FormulateInput
          v-model="drawinQtyType.startDate"
          type="date"
          name="Data de inicio"
          :value="drawinQtyType.startDate"
          label="De"
          validation="after:2020-09-20"
          label-class="mt-4 block uppercase text-gray-700 text-xs font-bold truncate lg:break-words"
          input-class="w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none"
          error-class="text-red-500 text-xs italic border-red-500"
          input-has-errors-class="appearance-none border border-red-500"
          placeholder=""
          :validation-messages="{
            after: 'Data inicial deve ser depois de 20/09/2020'
          }"
          min="2020-09-20"
          :max="new Date().toISOString().split('T')[0]"
        />
        <FormulateInput
          v-model="drawinQtyType.endDate"
          :value="drawinQtyType.endDate"
          type="date"
          name="Data final"
          label="Até"
          label-class="mt-4 block uppercase text-gray-700 text-xs font-bold truncate lg:break-words"
          input-class="w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none"
          error-class="text-red-500 text-xs italic border-red-500"
          input-has-errors-class="appearance-none border border-red-500"
          :validation-messages="{
            before: 'Data final deve ser depois da data inicial'
          }"
          :min="drawinQtyType.startDate"
          input-container-class="normal-text-color"
        />

        <button
          type="button"
          :disabled="!drawinQtyType.startDate || !drawinQtyType.endDate"
          class="flex justify-center "
          :class="{
            'bg-gray-400  text-black cursor-pointer hover:bg-gray-200 text-sm font-mono uppercase font-semibold  mt-6 p-1 rounded-lg ':
              drawinQtyType.startDate && drawinQtyType.endDate,
            'bg-gray-200  cursor-not-allowed text-gray-400 text-sm font-mono uppercase font-semibold  mt-6 p-1 rounded-lg  ':
              !drawinQtyType.startDate || !drawinQtyType.endDate
          }"
          @click="generateDrawinQtyType"
        >
          Gerar relatório
        </button>
      </div>
    </div>

    <div
      class="bg-white   w-full xl:w-1/6  mt-8  xl:mt-0 rounded-lg  border overflow-auto xl:mr-4 "
    >
      <div
        class="bg-gray-100    rounded-tr-lg rounded-tl-lg  flex justify-center w-full p-4"
      >
        <img
          src="../../assets/svgs/illustrations/sent.svg"
          alt=""
          srcset=""
          class="h-40 w-full"
          loading="lazy"
        />
      </div>

      <div class="bg-white flex flex-col justify-center w-full p-4 ">
        <span
          class=" text-center  font-bold uppercase font-mono text-gray-700 mb-2 h-10"
        >
          Developer em andamento
        </span>
        <span class="text-xs  mb-2  font-thin text-gray-700 h-10 py-2">
          Relatório de desenhos com developer enviados ou fazendo.
        </span>
        <label class="block uppercase text-gray-700 text-xs font-bold mt-4">
          Cliente
        </label>
        <v-select
          class="style-chooser"
          placeholder="cliente"
          :options="listCustomers"
          :filterable="false"
          :value="developerInProgress.customerName"
          label="name"
          id="client"
          name="client"
          @search="listAllCustomers"
          @input="setCustomerName(developerInProgress, $event)"
        >
          <template slot="no-options">
            Informe o NOME do cliente, se o cadastro existir, buscaremos pra
            você :)
          </template>
          <template slot="option" slot-scope="option">
            <div class="">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <FormulateInput
          v-model="developerInProgress.startDate"
          type="date"
          name="Data de inicio"
          :value="developerInProgress.startDate"
          label="De"
          validation="after:2020-09-20"
          label-class="mt-4 block uppercase text-gray-700 text-xs font-bold truncate lg:break-words"
          input-class="w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none"
          error-class="text-red-500 text-xs italic border-red-500"
          input-has-errors-class="appearance-none border border-red-500"
          placeholder=""
          :validation-messages="{
            after: 'Data inicial deve ser depois de 20/09/2020'
          }"
          min="2020-09-20"
          :max="new Date().toISOString().split('T')[0]"
        />
        <FormulateInput
          v-model="developerInProgress.endDate"
          :value="developerInProgress.endDate"
          type="date"
          name="Data final"
          label="Até"
          label-class="mt-4 block uppercase text-gray-700 text-xs font-bold truncate lg:break-words"
          input-class="w-full px-3 py-2 border border-gray-400 border-box rounded leading-none focus:border-blue-500 outline-none"
          error-class="text-red-500 text-xs italic border-red-500"
          input-has-errors-class="appearance-none border border-red-500"
          :validation-messages="{
            before: 'Data final deve ser depois da data inicial'
          }"
          :min="developerInProgress.startDate"
          input-container-class="normal-text-color"
        />
        <button
          type="button"
          :disabled="
            !developerInProgress.startDate || !developerInProgress.endDate
          "
          :class="{
            'bg-gray-400  text-black cursor-pointer hover:bg-gray-200 text-sm font-mono uppercase font-semibold  mt-6 p-1 rounded-lg ':
              developerInProgress.startDate && developerInProgress.endDate,
            'bg-gray-200  cursor-not-allowed text-gray-400 text-sm font-mono uppercase font-semibold  mt-6 p-1 rounded-lg  ':
              !developerInProgress.startDate || !developerInProgress.endDate
          }"
          @click="generateDeveloperInProgress"
        >
          Gerar relatório
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Customer } from '@/domain/models/customers/customers'
import { Toastr } from '@/presentation/helpers/toastr-configs'
import nProgress from 'nprogress'
import Vue from 'vue'

export default Vue.extend({
  name: 'ReportPage',
  props: {
    reportHttp: {
      required: true
    },
    customersHttp: {
      required: true
    }
  },
  data() {
    return {
      drawinQtyType: {
        customerName: '',
        startDate: null,
        endDate: null,
        loading: false
      },
      developerInProgress: {
        customerName: '',
        startDate: null,
        endDate: null,
        loading: false
      },
      collection: {
        customerName: '',
        startDate: null,
        endDate: null,
        loading: false
      },
      drawins: {
        customerName: '',
        startDate: null,
        endDate: null,
        loading: false
      },
      timeoutDrawinQtyStep: null,
      timeoutDeveloperInProgress: null,
      timeoutCollection: null,
      timeoutDrawin: null,
      listCustomers: []
    }
  },
  methods: {
    async generateDrawinQtyType() {
      clearTimeout(this.timeoutDrawinQtyStep)
      this.timeoutDrawinQtyStep = setTimeout(async () => {
        nProgress.start()
        await this.reportHttp.qtyDrawinsByType({
          dateInit: this.drawinQtyType.startDate,
          dateEnd: this.drawinQtyType.endDate,
          customerName: this.drawinQtyType.customerName
        })
        Toastr.successTopRight(
          this,
          'O relatório está sendo gerado e será enviado no seu e-mail em breve!'
        )
        nProgress.done()
      }, 500)
    },
    async generateDeveloperInProgress() {
      clearTimeout(this.timeoutDeveloperInProgress)
      this.timeoutDeveloperInProgress = setTimeout(async () => {
        nProgress.start()
        await this.reportHttp.drawinWithDeveloperInProgress({
          dateInit: this.developerInProgress.startDate,
          dateEnd: this.developerInProgress.endDate,
          customerName: this.developerInProgress.customerName
        })
        Toastr.successTopRight(
          this,
          'O relatório está sendo gerado e será enviado no seu e-mail em breve!'
        )
        nProgress.done()
      }, 500)
    },
    async generateCollectionReport() {
      clearTimeout(this.timeoutCollection)
      this.timeoutCollection = setTimeout(async () => {
        nProgress.start()
        await this.reportHttp.drawinsFromCollection({
          dateInit: this.collection.startDate,
          dateEnd: this.collection.endDate,
          customerName: this.collection.customerName
        })
        Toastr.successTopRight(
          this,
          'O relatório está sendo gerado e será enviado no seu e-mail em breve!'
        )
        nProgress.done()
      }, 500)
    },
    async generateDrawinReport() {
      clearTimeout(this.timeoutDrawin)
      this.timeoutCollection = setTimeout(async () => {
        nProgress.start()
        await this.reportHttp.drawins({
          dateInit: this.drawins.startDate,
          dateEnd: this.drawins.endDate,
          customerName: this.drawins.customerName
        })
        Toastr.successTopRight(
          this,
          'O relatório está sendo gerado e será enviado no seu e-mail em breve!'
        )
        nProgress.done()
      }, 500)
    },
    async listAllCustomers(
      searchText: string,
      loading: (value: boolean) => void
    ) {
      loading(true)
      if (!searchText) {
        clearTimeout(this.timeout)
        loading(false)
        return
      }
      clearTimeout(this.customerDebounce)
      this.customerDebounce = setTimeout(async () => {
        const { data } = await this.customersHttp.listAll({ searchText })
        this.listCustomers = data
        loading(false)
      }, 200)
    },
    setCustomerName(obj: { customerName: string }, event: Customer) {
      obj.customerName = event?.name || ''
    }
  }
})
</script>
<style lang="scss">
.style-chooser .vs__search::placeholder {
  color: rgba(74, 85, 104, 0.5);
}

.style-chooser .vs__dropdown-toggle {
  height: 2.5rem;
  border-color: #cbd5e0;
}
</style>
