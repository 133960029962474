<template>
  <section>
      <div class="flex flex-row md:flex-col md:items-start ">
        <label class="text-xs text-gray-400 font-medium mr-1">{{ title  }} </label>
        <span class="uppercase md:w-24 md:overflow-hidden md:truncate" :class="{ 'font-semibold': highlight } " :title="value"> {{ value }} </span>
      </div>
  </section>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'BasesCard',
  props: {
    title: {
      type: [String, Number],
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    highlight: {
      type: Boolean,
      default: false
    }
  }
})
</script>
