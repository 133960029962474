export abstract class DatesHelper {
 public static daysBetweenTodayAnd(date: Date) {
     const end = date.getTime()
     const today = new Date().getTime()
     const days = Math.ceil((end - today) / (1000 * 60 * 60 * 24))
     return days > 0 ? days : 0
 }
 public static daysBetweenDateAndToday(date: Date){
     const today = new Date().getTime()
     const datePast = date.getTime()
     const days = Math.ceil((today - datePast) / (1000*60*60*24))
     return days <= 0 ? 0 : days
 }
}
