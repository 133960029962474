<script lang="ts">
import Vue from 'vue'
import { Token } from '@/presentation/helpers/token-local-storage'
import { mapGetters } from 'vuex'
export default Vue.extend({
  name: 'DrawinStepsBasePage',
  methods: {
    goBack() {
      if (Token.validRole('freelancer')) {
        return this.$router.push('/portal')
      }
      return this.$router.push('/portal/estilo/desenhos')
    },
    goTo(path: string) {
      if (path === 'editar') {
        return this.$router.push(
          this.$route.path
            .replace('desenhos/', 'desenhos/editar/')
            .replace(/\/arte|\/configurar/, '')
        )
      }
      return this.$router.push(path).catch(() => {})
    }
  },
  computed: {
    canShow() {
      return Token.validRole('administrator', 'manager')
    },
    ...mapGetters({
      activeDrawing: 'activeDrawin'
    })
  }
})
</script>
<template>
  <section class="px-4 flex flex-col item">
    <div class="flex flex-col pt-2 w-full  mb-4 ">
      <div class="flex overflow-x-auto md:overflow-hidden">
        <button
          class="px-2 mr-auto bg-gray-300 rounded-full py-1  border cursor-pointer focus:outline-none hover:bg-gray-400 "
          @click="goBack"
        >
          <i class="fas fa-arrow-left   text-sm"></i>
        </button>
        <button
          class="px-2 bg-gray-300 rounded-md py-1 border cursor-pointer  focus:outline-none hover:bg-gray-400 ml-2 "
          @click="goTo('arte')"
        >
          <i class="fas fa-paint-brush   text-sm"></i> Arte
        </button>
        <button
          class="px-2 bg-gray-300 rounded-md py-1  hover:border-gray-400  cursor-pointer focus:outline-none ml-2 hover:bg-gray-400"
          v-if="canShow"
          @click="goTo('configurar')"
        >
          <i class="fas fa-cog  text-sm"></i> Configurar
        </button>
        <button
          class="px-2 bg-gray-300 rounded-md py-1 border-gray-200 hover:border-gray-400 cursor-pointer ml-2  hover:bg-gray-400 focus:outline-none"
          v-if="canShow"
          @click="goTo('editar')"
        >
          <i class="fas fa-edit  text-sm"></i> Editar
        </button>
        <!-- <button 
        :disabled="!activeDrawing.developerApproved"
        :class="{
          'opacity-50 cursor-not-allowed': !activeDrawing.developerApproved
         }" class="px-2 bg-gray-300 rounded-md py-1 border-gray-200 hover:border-gray-400 cursor-pointer ml-2  hover:bg-gray-400 focus:outline-none" > <i class="fas fa-layer-group  text-sm"></i>    Develop </button> -->
      </div>
    </div>
    <div>
      <keep-alive>
        <router-view> </router-view>
      </keep-alive>
    </div>
  </section>
</template>
