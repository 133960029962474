<template>
  <div
    class="flex flex-row  w-full relative pb-2 items-center  justify-center  mb-6"
  >
    <router-link v-if="to" :to="to">
      <i
        class="fas fa-arrow-left bg-gray-300 text-xl p-1 rounded-full border hover:border-gray-400 "
      ></i>
    </router-link>
    <div v-else class="cursor-pointer absolute left-0 mr-2" title="Voltar">
      <i
        @click="routing"
        class="fas fa-arrow-left bg-gray-300 text-xl p-1 rounded-full border hover:border-gray-400 "
      ></i>
    </div>
    <span class="font-mont  uppercase font-black text-xl "> {{ title }} </span>
  </div>
</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'BackHeader',
  props: {
    title: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: false,
      default: ''
    }
  },
  methods: {
    routing() {
      if (!this.hasHistory()) this.$router.go(-1)
      return this.$router.push('/portal/estilo/desenhos')
    },
    hasHistory(): boolean {
      return window.history.length < 2
    }
  }
})
</script>
