<template>
  <section class="flex flex-col justify-center items-center">
    <div class="bg-white border  w-full lg:w-1/3 flex flex-col pt-6 pb-4 mb-4">
       <div class="flex flex-col items-center">
          <div class="inline-flex shadow-lg border border-gray-200 rounded-full overflow-hidden h-40 w-40">
            <input  type="file" ref="fileInput" hidden @change="changeImage($event.target.files)" accept="image/*">
            <img 
              :src="imageUrl || require('../../assets/images/no_avatar.png')"
              alt=""
              class="h-full w-full"
            >
          </div>
          <button class="border rounded-full mt-4 py-2 px-4 text-xs font-semibold text-gray-700 hover:bg-gray-200 focus:outline-none"  @click="$refs.fileInput.click()"> Alterar foto </button>
          <button  v-if="imageUrl" class="rounded-full py-2 px-4 text-xs text-red-600 hover:underline focus:outline-none"  @click="changeImage(null)"> Remover foto </button>
          <h2 class="mt-2 font-bold text-xl capitalize"> {{ token().name }} </h2>
          <h5 class="mt-2  font-medium "> {{ token().email }}</h5>
				  <h6 class="mt-2 text-sm capitalize">  {{ token().roles.join(', ') }} </h6>
       </div>
    </div>

 
    <div class="bg-white border  w-full lg:w-1/3 flex flex-col  pb-4 mb-4">
      <div class=" w-full  flex flex-col   py-2 border-t-1 ">
            <span class="font-mont  self-center font-bold uppercase mt-2">Dados</span>
            <div class="w-9/12 self-center">
              <FormulateForm @submit="changeData">
                <SampleInput label="Nome" name="userName" :value="token().name" validation="^required" placeholder="informe o nome"  />
                <SampleInput label="CPF/CNPJ" name="cpf" :value="token().cpf" validation="matches:/^(\d{11})?$/"  placeholder="informe o cpf (sem pontuações)"   />
                <SampleInput label="Banco" name="bankName"  :value="token()?.bankAccount?.bankName"  placeholder="informe o banco ex: nubank, santander"  />
                <SampleInput label="Agencia" name="agency"  :value="token()?.bankAccount?.agency"   placeholder="informe a agencia"  />
                <SampleInput label="Conta" name="accountNumber" :value="token()?.bankAccount?.accountNumber"   placeholder="informe a conta"   />
                <SampleInput label="Chave Pix" name="pix" :value="token()?.bankAccount?.pix"   placeholder="informe a chave pix"   />

                <SimpleButton class="mt-4" text="SALVAR " />
              </FormulateForm>
            </div>
          </div>
    </div>
    


    <div class="bg-white border  w-full lg:w-1/3 flex flex-col  pb-4 mb-4">
      <div class=" w-full  flex flex-col mt-4 mb-2  border-t-1 ">
              <span class="font-mont  self-center font-bold uppercase mt-2">Alterar senha</span>
              <div class="w-9/12 self-center">
              <FormulateForm @submit="changePassword">
                <SampleInput label="Senha atual" name="current_password" validation="^required" placeholder="informe a senha atual"  type="password"/>
                <SampleInput label="Nova Senha" name="new_password" validation="^required" placeholder="informe a nova senha"  type="password"/>
                <SampleInput label="Confirme a senha" name="new_password_confirm" validation="^required|confirm" placeholder="confirme sua senha"  type="password" />
                <SimpleButton class="mt-4" text="Alterar senha " />
              </FormulateForm>
              </div>
          </div>
    </div>
    


    
  </section>
</template>


<script lang="ts">
import { Token } from '@/presentation/helpers/token-local-storage'
import SampleInput from '@/presentation/components/forms/sample-input.vue'
import SimpleButton from '@/presentation/components/forms/send-form-button.vue'
import { IMe } from '@/domain/users/me'
import Vue from 'vue'
import { Toastr } from '@/presentation/helpers/toastr-configs'
import { mapActions, mapGetters } from 'vuex'
import NProgress from 'nprogress'
export default Vue.extend({
  name: 'EditUserProfilePage',
  components: {
    SampleInput,
    SimpleButton
  },
  data(){
    return {
      imageUrl: Token.userInfo().avatarUrl 
    }
  },
  props: {
    me: {
      required: true,
      type: Object as () => IMe
    }
  },
  methods: {
    token: Token.userInfo,
    async changeImage(files: FileList) {
      NProgress.start()

      if(Token.userInfo().avatarUrl || files?.length){
        const formData = new FormData()
        const fileAppend = files?.length ? files[0] : null
        formData.append('files', fileAppend)
        const response = await this.me.updateAvatar(formData)
        this.imageUrl = files ? URL.createObjectURL(files[0]) : null
        Token.updateToken('avatarUrl', response?.data[0]?.avatarUrl || null)
        this.updateAvatarUrl( {avatarUrl: response.data[0].avatarUrl})
      }
     
      NProgress.done()
      Toastr.successBottomRight(this, 'Imagem alterada com sucesso!')
    },
    async changePassword({current_password, new_password,  new_password_confirm}: {current_password: string, new_password: string, new_password_confirm: string}): Promise<void> {
      if(!event || !current_password || !new_password_confirm || !new_password){
        return
      }
      try {
        await this.me.changePassword(current_password, new_password_confirm , new_password)
        Toastr.successBottomRight(this, 'Senha atualizada com sucesso! Por favor faça o login novamente!')
        Token.removeToken()
        this.$router.push('/')
      } catch (error) {
        Toastr.errorBottomRight(this, error.errorMessage)
      }
    },
    async changeData({bankName, agency, pix, accountNumber, cpf, userName}: { userName: string, bankName: string, agency: string,  pix: string, accountNumber: string, cpf: string}): Promise<void> {
      try {
        await this.me.updateData(userName, cpf, {
          pix,
          bankName,
          accountNumber,
          agency
        })
        Token.updateToken('name', userName)
        Token.updateToken('cpf', cpf)
        Token.updateToken('bankAccount', {
          pix,
          accountNumber,
          bankName,
          agency
        })
        Toastr.successBottomRight(this, 'Dados atualizados com sucesso!')
      } catch (error) {
        Toastr.errorBottomRight(this, error.errorMessage)
      }
    },
    ...mapActions({ updateAvatarUrl: 'updateAvatarUrl'})
  },
  computed: {
    ...mapGetters(['myAvatarUrl'])
  }
})
</script>