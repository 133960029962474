<template>
  <section class="h-full" >

      <div class="flex">
          <div class="fixed top-0 z-40  w-1/8  flex lg:relative  border-r lg:justify-center  lg:flex-row  bg-white h-screen  overflow-auto  "  
         v-if="isOpen">
            <SideBarNavigation class="font-euclid leading-5 text-sm  xs:hidden "  v-bind:isOpen="isOpen" />
          </div>
          <div class="flex flex-col w-full jusitfy-content-center items-center overflow-auto">
            <div class="flex justify-between w-full font-euclid  border-r p-2 text-center text-2xl  h-12 border-b  bg-white ">
              <span @click="isOpen = !isOpen" ><i class="fas fa-bars text-cyan-500 text-lg py-2 cursor-pointer"></i></span>
                <router-link  class="mx-auto text-2xl" to="/portal"> LUCIN </router-link>
            </div>
            <div class="w-full p-4 overflow-auto calcHeight ">
              <slot class=""></slot>  
            </div>
       
          </div>
         
          <div
            @keydown.esc="isOpen = false"
            v-show="isOpen"
            class="cursor-pointer lg:hidden z-10 fixed inset-0 transition-transform"
            >
            <div
               @click="isOpen = false"
               class="absolute inset-0 bg-black opacity-50"
               tabindex="0"
               ></div>
         </div>
        
        </div>

  </section>
</template>

<script lang="ts">
import Vue from 'vue'
import TopBarNavigation from './top-bar.vue'
import SideBarNavigation from './side-bar.vue'

export default Vue.extend({
  name: 'NavigationWrapper',
  components: {
    TopBarNavigation,
    SideBarNavigation
  },
  data () {
    return {
      isOpen: true
    }
  },
  methods: {
    showSideNav (event: boolean) {
      this.isOpen = event
    }
  }
})
</script>


<style>
  .calcHeight {
    height: calc(100vh - 3rem);
  }
</style>