import { render, staticRenderFns } from "./sample-checkbox.vue?vue&type=template&id=37829c1a&scoped=true"
import script from "./sample-checkbox.vue?vue&type=script&lang=ts"
export * from "./sample-checkbox.vue?vue&type=script&lang=ts"
import style0 from "./sample-checkbox.vue?vue&type=style&index=0&id=37829c1a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37829c1a",
  null
  
)

export default component.exports