<template>
 <div class="flex flex-col justify-between relative">
    <!-- <div class="z-20 fixed -mt-3  w-full top-0">
      <InfiniteProgressBar />
    </div> -->
   <section  class="flex fixed top-0 items-center justify-between z-10 bg-white px-4 py-3 w-full h-16 text-gray-700 shadow-md  border-gray-400 ">
      <div class="flex">
         <button class="mr-2 focus:outline-none hover:bg-gray-200 p-2 rounded-full" @click="drawer">
            <svg class="h-6 w-6" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path d="M4 6H20M4 12H20M4 18H11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
         </button>
      </div>
      <div class="self-center text-2xl text-gray-700">
         <router-link to="/portal"> LUCIN </router-link>
      </div>
      <div class="flex items-center">     
         <span class="px-1 w-8 relative cursor-pointer hover:text-gray-700">
         <i class="fas fa-bell p-2 bg-gray-200  text-gray-600 rounded-full"></i>
         <!-- <span class="absolute right-0 top-0 -mt-2 -mr-1 text-xs bg-gray-500 text-white font-medium px-2 shadow-lg rounded-full">
            
         </span> -->
         </span>
      </div>
   </section>
 </div>

</template>

<script lang="ts">
import Vue from 'vue'
export default Vue.extend({
  name: 'TopBarNavigation',
  props: {
    isOpen: Boolean
  },
  methods: {
    drawer () {
      this.$emit('isOpen', !this.isOpen)
    }
  }
})
</script>
