import { IDevelop } from '@/domain/develop/develop'
import { IHttpResponse } from '@/domain/models/api-responses'
import {DeveloperStatus, DeveloperStatusType, DrawingDeveloperModel} from '@/domain/models/develop/develop-model'

export const developStoreFactory = (developHttp: IDevelop) => ({
  state(): object {
    return {
      develop: {
        data: [],
        statusCode: 0,
        pagesLimit: 0
      },
      loading: false,
      searchText: '',
      pageNumber: 1,
      pageSize: 6,

    }
  },
  getters: {
    developList(state: any) {
      return state.develop.data
    },
    developPageLimit (state: any) {
      return state.develop.pagesLimit
    },
    activeDevelopPage(state: any) {
      return state.pageNumber
    },
    developSearchText (state: any) {
      return state.searchText
    },
  },
  mutations: {
    LOAD_DEVELOP(state: any, payload: IHttpResponse<DrawingDeveloperModel>) {
      state.develop = payload
      state.loading = false
    },
    SET_DEVELOPER_STATUS(state: any, payload: DeveloperStatus & {id: string}){
      const findIndex = state.develop.data.findIndex((d: any) => d.id === payload.id)
      if(findIndex < 0){
        return
      }
      state.develop.data[findIndex].status.push({ status: payload.status, date: payload.date })
      state.develop.data[findIndex].activeStatus = payload.status
    },
    REMOVE_DEVELOPER_STATUS(state: any, payload: { status: DeveloperStatusType, id: string }) {
      const findIndex = state.develop.data.findIndex((d: any) => d.id === payload.id)
      if (findIndex < 0) {
        return
      }
      state.develop.data[findIndex].status = state.develop.data[findIndex].status.filter((s: DeveloperStatus) => s.status !== payload.status)
      const statusLength = state.develop.data[findIndex].status.length - 1
      if (statusLength < 0) {
        state.develop.data[findIndex].activeStatus = null
        return
      }
      state.develop.data[findIndex].activeStatus =  state.develop.data[findIndex].status[statusLength].status
    },
    SET_DEVELOP_SEARCH_TEXT (state: any, payload: string) {
      state.searchText = payload
    },
  },
  actions: {
    async loadDevelop(context: any, payload: { pageNumber: number, searchText?: string, pageSize?: number, sort: any }) {
      context.commit('SET_LOADING')
      const { pageNumber, pageSize, sort } = payload
      const response = await developHttp.list({ pageNumber, pageSize: pageSize || 6, searchText: context.state.searchText, ...sort })
      context.commit('LOAD_DEVELOP', response)
      return context.state
    },
    async setDeveloperStatus(context: any, payload: DeveloperStatus & {id: string}){
      const { id, status, date} = payload
      await developHttp.setStatus(id, { status, date})
      context.commit("SET_DEVELOPER_STATUS", payload)
    },
    async removeDeveloperStatus(context: any, payload: { status: DeveloperStatusType, id: string }) {
      const { id, status } = payload
      await developHttp.removeStatus(id, status)
      context.commit("REMOVE_DEVELOPER_STATUS", payload)
    },
    async updateDeveloper(context: any, payload: Partial<DrawingDeveloperModel> & {id: string}){
      const {id, ...data} = payload
      await developHttp.update(id, data)
    }
  }
})
